import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { OptimizelyDecision, UserAttributes } from '@optimizely/optimizely-sdk';
import { switchMap } from 'rxjs';
import { FlagKey } from './flag-key.enum';
import { UserAttributesService } from './user-attributes.service';

export type Flag = {
	[key in FlagKey]?: OptimizelyDecision | null;
};

export interface Flag2State {
	enabled: boolean;
	initialised: boolean;
	flags: Flag | undefined;
	error: string | undefined;
	userAttributes: UserAttributes | undefined;
}

export const defaultState: Flag2State = {
	enabled: false,
	initialised: false,
	flags: undefined,
	error: undefined,
	userAttributes: undefined,
};

@Injectable({
	providedIn: 'root',
})
export class FlagStore extends ComponentStore<Flag2State> {
	public readonly selectUserAttributes$ = this.select((state) => state.userAttributes);

	public readonly updateInitialised = this.updater((state) => ({ ...state, initialised: true, error: undefined }));
	public readonly updateEnabled = this.updater((state, enabled: boolean) => ({ ...state, enabled }));
	public readonly updateUserAttributes = this.updater((state, userAttributes: UserAttributes) => ({
		...state,
		userAttributes,
	}));
	public readonly addFlag = this.updater(
		(state, { flagKey, decision }: { flagKey: FlagKey; decision: OptimizelyDecision | null }) => ({
			...state,
			flags: { ...state.flags, [flagKey]: decision },
		})
	);
	public readonly updateError = this.updater((state, error: string) => ({ ...state, error }));

	public readonly loadUserAttributes = this.effect((trigger) =>
		trigger.pipe(
			switchMap(() =>
				this.userAttributesService.getAttributes().pipe(
					tapResponse(
						(userAttributes) => {
							this.updateUserAttributes(userAttributes);
						},
						() => {
							// fail silently
						}
					)
				)
			)
		)
	);

	constructor(private userAttributesService: UserAttributesService) {
		super(defaultState);
	}
}
