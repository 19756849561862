import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
	name: 'sanitizeHtml', pure: false,
	standalone: true
})
export class SanitizeHtmlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) { }

	transform(content: any): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}
}
