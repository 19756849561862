/* eslint-disable @typescript-eslint/naming-convention */
export const productDetailsConstants = {
	tealium_event: 'product_view',
	interaction_source: 'page',
} as const;

export const productViewConstants = {
	tealium_event: 'view_item',
} as const;

export const productGridConstants = {
	tealium_event: 'product_list_view',
	interaction_source: 'page',
} as const;

export const productClearConstants = {
	tealium_event: 'cart_clear',
	interaction_source: 'page',
	cart_step: '',
	cart_step_label: '',
	cart_payment_type: '',
} as const;

export const productSelectConstants = {
	tealium_event: 'select_item',
} as const;

export const defaultSalesPrice = '0.00' as const;

export const stockAvailabilityConstants = {
	inStock: 'In Stock',
	outOfStock: 'Out of Stock',
} as const;

export class TealiumProductList {
	product_category_1: string[] = [];
	product_category_2: string[] = [];
	product_category_3: string[] = [];
	product_sku: string[] = [];
	product_image_url: string[] = [];
	product_name: string[] = [];
	product_price: string[] = [];
	product_sale_price: string[] = [];
	product_dietary: string[] = [];
	product_stock: string[] = [];
	product_brand: string[] = [];
	product_quantity: string[] = [];
	product_promo_category: string[] = [];
	product_promo_value: string[] = [];
	search_value: string[] = [];
	search_results_returned: string[] = [];
}

export interface TealiumProduct {
	item_id: string;
	item_name: string;
	coupon?: string;
	discount?: number;
	index?: string;
	item_brand?: string;
	item_category?: string;
	item_category2?: string;
	item_category3?: string;
	item_list_id?: string;
	item_list_name?: string;
	item_variant?: string;
	price?: number;
	quantity?: number;
	each_kg?: string;
	promotion_id?: string;
	promotion_name?: string;
}
