import { Directive, Output, EventEmitter, Input, HostBinding } from '@angular/core';

@Directive({
	selector: '[cdxAccordionItem],[cdxAccordion-item]',
	exportAs: 'cdxAccordionItem',
	standalone: true
})
export class AccordionItemDirective {
	@HostBinding('attr.aria-expanded')
	@Input()
	expanded = false;

	@Output() toggleExpandCollapse: EventEmitter<boolean> = new EventEmitter();

	public toggle(isExpanded: boolean): void {
		this.expanded = isExpanded;
		this.toggleExpandCollapse.next(this.expanded);
	}
}
