/* eslint-disable @typescript-eslint/naming-convention */
import {isPlatformBrowser} from '@angular/common';
import {Inject, Injectable, Injector, NgZone, Optional, PLATFORM_ID, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subject, throwError} from 'rxjs';
import {catchError, take} from 'rxjs/operators';
import {customerLoginCompleted, customerLogout} from '../constants';
import {ANALYTICS_PROVIDER, AnalyticsClass, LEGACY_FRAMEWORK} from '../injection-tokens';
import {BridgeEvent, ChangeOrderStatus} from '../ui-models';
import {GigyaResponse} from '../ui-models/Gigya';
import {ApiService} from './api.service';
import {AppSettingsService} from './app-settings.service';
import {DatalayerService} from './datalayer.service';
import {InboundEmailParamsService} from './inbound-email-params.service';
import {LocalStorageService} from './local-storage.service';
import {LoggingService} from './logging.service';
import {NativeBridgeService} from './native-bridge.service';
import {ShopperService} from './shopper.service';
import {StatefulService} from './stateful.service';
import {CustomWindow, WINDOW} from './window.service';
import {MedalliaKeys, MedalliaSurveyDataPushService} from './medallia-survey-data-push.service';

export const GIGYA_CHECK_INTERVAL = 100;
export const OLIVE_CHAT_SESSION_STORAGE_KEY = 'shop-countdown.id_token';

export class GigyaState implements GigyaResponse {
	errorCode?: any;
	status?: string;
	errorMessage?: string;
	id_token?: any;
	profile?: any;
	source?: any;
	provider?: string;
	newUser?: boolean;
	loginMode?: string;
	data?: {
		Countdown?: {
			scvCustomerGUID?: string;
			shopperId?: number;
			lastUpdateUTC?: string;
		};
	};
	preAuth?: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class GigyaService extends StatefulService<GigyaState> {
	renderer: Renderer2;
	ScvIdKey = 'scvId';
	OrderCountKey = 'orderCount';
	HasActiveDeliverySubscriptionKey = 'hasActiveDeliverySubscription';
	cookieName = 'cw-ssoc';
	userInfo = {};
	socializeLogin$ = new Subject<GigyaResponse>();

	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		@Inject(WINDOW) private window: CustomWindow,
		private apiService: ApiService,
		private loggingService: LoggingService,
		private appSettingsService: AppSettingsService,
		private nativeBridgeService: NativeBridgeService,
		private injector: Injector,
		private shopperService: ShopperService,
		private medalliaSurveyDataPushService: MedalliaSurveyDataPushService,
		private inboundEmailParamsService: InboundEmailParamsService,
		private ngZone: NgZone,
		private localStorageService: LocalStorageService,
		@Optional() @Inject(ANALYTICS_PROVIDER) private tealiumService: AnalyticsClass | undefined,
		@Optional() @Inject(LEGACY_FRAMEWORK) private legacyFramework: boolean
	) {
		super({});
	}

	get sessionEndpoint(): string {
		return this.appSettingsService.getEndpoint('session');
	}

	get router(): Router {
		return this.injector.get(Router);
	}

	afterScreenLoad = (containerId: any) => () => {
		const containers = document.querySelectorAll(`#${containerId} .inputContainer`);

		containers.forEach((container) => {
			const passwordInput = container.firstChild as HTMLInputElement;
			const showPasswordButton = container.lastChild;
			this.renderer.listen(showPasswordButton, 'click', () => {
				passwordInput.type = passwordInput.type === 'password' ? 'text' : 'password';
			});
		});
	};

	handleLogout(callbackFun?: Function): void {
		this.removeJWTCookie();
		this.loggingService.trackEvent('UI:Authentication:Any:UserInitiatedLogout', {});
		this.window.gigya?.accounts.logout({
			callback: () => {
				this.loggingService.trackEvent('UI:Authentication:Any:UserLoggedoutFromGigya', {});
				this.setState(<GigyaState>{
					profile: null,
					id_token: null,
					status: '',
					preAuth: false,
					data: undefined,
				});
				if (callbackFun) {
					callbackFun();
				}
			},
		});
		this.clearFromLocalStorage();
	}

	pollyfillCustomEvent(): void {
		if (typeof this.window.CustomEvent === 'function') {
			return;
		}

		const customEvent = (event: string, params: { bubbles: any; cancelable: any; detail: any }): any => {
			params = params || { bubbles: false, cancelable: false, detail: undefined };
			const evt = this.window.document.createEvent('CustomEvent');
			evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
			return evt;
		};

		customEvent.prototype = this.window.Event.prototype;
		this.window.CustomEvent = customEvent;
	}

	initGigya(): void {
		if (!isPlatformBrowser(this.platformId)) {
			return;
		}
		this.loggingService.trackEvent('UI:Authentication:Any:initGigyaCalled', {});

		this.pollyfillCustomEvent();

		const gigyCheckInterval = setInterval(() => {
			if (typeof this.window.gigya !== 'undefined') {
				this.loggingService.trackEvent('UI:Authentication:Any:GigyaEventsRegistered', {});
				this.addEventHandlers();
				clearInterval(gigyCheckInterval);
			}
		}, GIGYA_CHECK_INTERVAL);
	}

	addEventHandlers = (): void => {
		this.window.gigya?.accounts.getAccountInfo({
			callback: this.getAccountInfoCallback.bind(this),
			include: 'data, loginIDs, profile',
		});
		this.window.gigya?.accounts.addEventHandlers({
			onLogin: this.onLogin.bind(this),
			onLogout: this.onLogout.bind(this),
		});
		this.window.gigya?.socialize.addEventHandlers({
			onLogin: (response) => {
				this.socializeLogin$.next(response);
			},
		});
	};

	getAccountInfoCallback(res: GigyaResponse): any {
		this.loggingService.trackEvent('UI:Authentication:Any:getAccountInfoCallback', { ...res });
		this.nativeBridgeService.broadcastNativeEvent(BridgeEvent.gigyaAccountInfoReceived, {
			errorCode: res?.errorCode,
			status: res?.status,
			errorMessage: res?.errorMessage,
			scvid: res?.data?.Countdown?.scvCustomerGUID,
		});

		this.setState(<GigyaState>{
			...res,
			preAuth: false,
		});

		// if we're on an inbound link from an email we need to ensure their session has the correct store and/or onecard
		if (this.inboundEmailParamsService.isFromEmail()) {
			// We don't have a session so skip straight to setting the store and/or onecard
			if (!res.id_token) {
				return this.logoutAndSetOnecardOrStore();
			}

			return this.inboundEmailParamsService
				.isShoppersPrimaryOnecard()
				.pipe(take(1))
				.subscribe((isPrimary) => {
					// The onecard in the token is the current user's primary card, no need to log out
					// just set the store
					if (isPrimary) {
						return this.inboundEmailParamsService.setStore();
					}

					// if we're on an inbound link from an email and the onecard is not the current user's primary onecard
					// we sign them out so we can set it on their session
					this.logoutAndSetOnecardOrStore();
				});
		}

		if (res.errorCode) {
			this.removeJWTCookie();
			this.updateShopper();
			this.handleOliveWidgetOnLogout();
			this.dispatchUserEvent({ email: null });
			if (this.shopperService.isLoggedInUser) {
				this.doContextLogout(() => this.router.navigateByUrl('/'));
			}
			return;
		}

		this.setTokenInSessionStorage(res.id_token);

		const hasJWTCookie = this.window.document.cookie.indexOf(this.cookieName) !== -1;

		if (!hasJWTCookie) {
			this.onLogin(res);
		}

		this.userInfo = {
			email: res.profile && res.profile.email,
		};

		this.trackShopperAndOrderChange();

		this.setToLocalStorage();

		this.dispatchUserEvent(this.userInfo);
	}

	onLogin(loginResponse: GigyaResponse): void {
		this.loggingService.trackEvent('UI:Authentication:Any:GigyaLoginEvent', { ...loginResponse });

		if (loginResponse.errorCode !== 0 && typeof loginResponse.errorCode !== 'undefined') {
			return;
		}

		this.refreshToken()
			.pipe(take(1))
			.subscribe((token) => {
				this.setState(<GigyaState>{
					...loginResponse,
					id_token: token,
					preAuth: false,
				});

				this.makeSessionCall()
					?.pipe(take(1))
					.subscribe((res: any) => {
						const isSocialLogin = this.isSocialLogin(loginResponse);

						this.tealiumService?.link({
							...customerLoginCompleted,
							...this.shopperService.getAnalyticsData(),
						});

						this.handleSession(res, isSocialLogin);
					});
			});
	}

	onLogout(): void {
		this.removeJWTCookie();
		this.updateShopper();

		this.setState(<GigyaState>{
			profile: null,
			id_token: null,
			status: '',
			preAuth: false,
			data: undefined,
		});

		this.handleOliveWidgetOnLogout();
		this.loggingService.trackEvent('UI:Authentication:Any:GigyaLogoutEvent', {
			shopperId: this.shopperService.state.shopperScvId,
		});
		this.clearFromLocalStorage();

		const loggedOutShopperDetails = this.shopperService.getAnalyticsData();
		loggedOutShopperDetails.customer_logged_in_status = 'false';
		this.tealiumService?.link({ ...customerLogout, ...loggedOutShopperDetails });
	}

	doContextLogout(callback: Function): void {
		this.apiService
			.post(this.appSettingsService.getEndpoint('logout'), { gigyaInitiated: true })
			.pipe(
				take(1),
				catchError((e) => throwError(() => new Error(e)))
			)
			.subscribe(() => callback.apply(this));
	}

	makeSessionCall(): any {
		if (!this.sessionEndpoint) {
			return;
		}

		return this.apiService.post(this.sessionEndpoint, { redirectUrl: 'on' });
	}

	handleSession(response: { context: any }, isSocialLogin: boolean): void {
		if (!response.context?.active && !isSocialLogin) {
			return;
		}

		// some systems like global nav app (that sits within an angularjs framework) handle redirects outside the spa.
		if (this.legacyFramework) {
			return;
		}

		// if we get an active session from gigya (always asynchronous) we reload the current URL and let the guards and resolvers do their thing again
		this.ngZone.run(() => {
			this.router.navigateByUrl(this.router.url, { replaceUrl: true });
		});

		this.trackShopperAndOrderChange();

		this.setToLocalStorage();
	}

	isSocialLogin = (res: GigyaResponse): boolean =>
		res.provider === 'facebook' || res.provider === 'googleplus' || !res.newUser;

	getJWTCookie(): any {
		const match = this.window.document.cookie.match(new RegExp('(^| )' + this.cookieName + '=([^;]+)'));
		if (match) {
			return match[2];
		}
	}

	getToken(): any {
		return this.state?.id_token;
	}

	getScvCustomerGUID(): any {
		return this.state?.data?.Countdown?.scvCustomerGUID;
	}

	refreshToken(): Observable<string> {
		const refreshToken = new Observable<string>((subscriber) => {
			this.window.gigya?.accounts.getJWT({
				fields: 'lastLoginTimestamp, data',
				callback: (res: GigyaResponse) => {
					// update JWT token in cookie, session, and state
					if (res.id_token) {
						this.setTokenInSessionStorage(res.id_token);
						this.setJWTCookie(res.id_token);
						this.setState({ id_token: res.id_token });
					} else {
						this.nativeBridgeService.broadcastNativeEvent(BridgeEvent.gigyaAccountInfoReceived, {
							errorCode: res?.errorCode,
							status: res?.status,
							errorMessage: res?.errorMessage,
						});
						this.loggingService.trackEvent('UI:Authentication:Any:GigyaRefreshToken', { ...res });
					}
					subscriber.next(res.id_token);
					subscriber.complete();
				},
			});
		});

		return refreshToken;
	}

	setJWTCookie(idToken: string): void {
		const myDate = new Date();
		const cookieValue = idToken;
		myDate.setMonth(myDate.getMonth() + 12);
		const domain = this.window.location.hostname;
		this.window.document.cookie = `${this.cookieName}=${cookieValue};expires=${myDate};path=/;domain=${domain};secure;SameSite=None`;
	}

	removeJWTCookie(): void {
		const domain = this.window.location.hostname;
		this.window.document.cookie = `${this.cookieName}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${domain}`;
	}

	dispatchUserEvent(userInfo: { email?: null }): void {
		const event = new CustomEvent('sessionInit', { detail: userInfo });
		this.window.document.dispatchEvent(event);
	}

	setPreAuthJWT(jwt: string): void {
		this.setState(<GigyaState>{
			id_token: jwt,
			preAuth: true,
		});
	}

	// Olive chat widget read the id token from session storage.
	setTokenInSessionStorage(token: string): void {
		try {
			this.window.sessionStorage?.setItem(OLIVE_CHAT_SESSION_STORAGE_KEY, token);
		} catch (e) {
			this.loggingService.trackException({
				error: new Error('Session storage is not available or Quota Exceeded'),
			});
		}
	}

	handleOliveWidgetOnLogout(): void {
		try {
			this.window.sessionStorage?.removeItem(OLIVE_CHAT_SESSION_STORAGE_KEY);
			if (this.window.chatWidget?.updateUserToken) {
				this.window.chatWidget?.updateUserToken(null);
			}
		} catch (e) {
			this.loggingService.trackException(e);
		}
	}

	updateShopper(): void {
		this.shopperService.storeFulfilmentInfo();
	}

	loginWithCookie(cookieName: string, cookieValue: string): void {
		const myDate = new Date();
		myDate.setMonth(new Date().getMonth() + 1);
		cookieName = `gac_${this.window.gigya?.apiKey}`;
		this.window.document.cookie = `${cookieName}=${cookieValue};expires=${myDate};path=/;`;
		this.window.gigya?.socialize.refreshUI({
			callback: () => {
				this.window.gigya?.accounts.getAccountInfo({
					callback: this.getAccountInfoCallback.bind(this),
					include: 'data, loginIDs, profile',
				});
			},
		});
		this.setToLocalStorage();
	}

	clearFromLocalStorage(): void {
		this.localStorageService.removeItem(this.ScvIdKey);
		this.localStorageService.removeItem(this.OrderCountKey);
		this.localStorageService.removeItem(this.HasActiveDeliverySubscriptionKey);
	}

	setToLocalStorage(): void {
		const shopper = this.shopperService.state;
		this.localStorageService.setItem(this.ScvIdKey, shopper.shopperScvId || '');
		this.localStorageService.setItem(this.OrderCountKey, shopper.orderCount || '');
		this.localStorageService.setItem(
			this.HasActiveDeliverySubscriptionKey,
			shopper.hasActiveDeliverySubscription?.toString() || 'false'
		);
	}

	// send message to Datalayer Service
	// to track shopper and track order change.
	private trackShopperAndOrderChange(): void {
		const datalayerService = this.injector.get(DatalayerService);
		const shopper = this.shopperService.state;

		datalayerService.trackShopper({
			shopperId: shopper.shopperIdHash || '',
			scvId: shopper.shopperScvId || '',
			sessionGroups: shopper.sessionGroups || [],
			orderCount: shopper.orderCount || '',
			hasActiveDeliverySubscription: shopper.hasActiveDeliverySubscription?.toString() || 'false',
		});

		datalayerService.trackChangeOrderStatus({
			revisionNumber: '1',
			amendedTransactionId: shopper.changingOrderId,
			changeMyOrderStatus: shopper.isChangingOrder ? ChangeOrderStatus.enabled : ChangeOrderStatus.disabled,
		});
		this.medalliaSurveyDataPushService.push(MedalliaKeys.scvId, shopper.shopperScvId || '', false);
	}

	private logoutAndSetOnecardOrStore(): void {
		this.handleLogout(() => {
			this.handleOliveWidgetOnLogout();
			this.doContextLogout(() => {
				this.inboundEmailParamsService.setParameters();
			});
		});
	}
}
