import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'img[default]',
	standalone: true
})
export class ImagePreloadDirective {
	@Input() default: string;

	@HostBinding('attr.src')
	@Input()
	src: string;

	@HostBinding('attr.srcset')
	@Input()
	srcset: string;

	@HostListener('error')
	onError(): void {
		this.src = this.default;
		this.srcset = this.default;
	}

	@HostListener('load')
	onLoad(): void {
		// TO-DO place holder to add class that removes loading symbol
	}
}
