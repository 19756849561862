import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FulfilmentStoreService } from '@woolworthsnz/fulfilment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ExpressFulfilmentInterceptor implements HttpInterceptor {
	constructor(private fulfilmentService: FulfilmentStoreService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse && event?.body?.expressItemQuantityExceededWhenItemIsAdded) {
					this.fulfilmentService.expressItemQuantityExceededWhenItemIsAdded$.next();
				}
			})
		);
	}
}
