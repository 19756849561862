import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomWindow, WINDOW } from '../services/window.service';

@Injectable({ providedIn: 'root' })
export class RequestHeaderInterceptor implements HttpInterceptor {
	constructor(@Inject(WINDOW) private window: CustomWindow) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		req = this.addUiVersionHeader(req, this.window.BOOTSTRAP_DATA?.uiVersion ?? '');

		return next.handle(req);
	}

	addUiVersionHeader(req: HttpRequest<any>, uiVersion: string): HttpRequest<any> {
		if (this.isValidURL(req.url)) {
			let headers = req.headers;
			if (!headers.get('x-ui-ver')) {
				headers = headers.set('x-ui-ver', uiVersion);
			}
			return req.clone({ headers });
		}
		return req;
	}

	isValidURL(url: string): boolean {
		const urlString = url?.trim() ?? '';

		// HttpRequest object in interceptor will have a relative url if the request is to the same domain.
		// Trader API & UI are in the same domain, so we will use this to filter out calls to other API's.
		const isRelativeUrl = !urlString.startsWith('http://') || !urlString.startsWith('https://');

		const isApiRequest = urlString.startsWith('/api');

		return isRelativeUrl && isApiRequest;
	}
}
