import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { ShopperService, ShopperState } from './shopper.service';
import { CustomWindow, WINDOW } from './window.service';

export enum MedalliaKeys {
	isExpressAbandoned = 'isExpressAbandoned',
	transactionId = 'transactionId',
	scvId = 'scvId',
}

interface MedalliaData {
	[key: string]: string | number | boolean;
}

@Injectable({
	providedIn: 'root',
})
export class MedalliaSurveyDataPushService {
	localStorageKey = 'medalliaData';
	medalliaPrefix = 'medallia_';

	constructor(
		@Inject(PLATFORM_ID) private platform: any,
		@Inject(WINDOW) private window: CustomWindow,
		private localStorageService: LocalStorageService,
		private shopperService: ShopperService
	) {}

	push(key: MedalliaKeys, value: string | number | boolean, updateImmediately = true): void {
		if (isPlatformBrowser(this.platform) && this.window) {
			this.window[`${this.medalliaPrefix}${key}`] = value;

			if (updateImmediately) {
				this.refreshMedallia();
			} else {
				const shopperId = this.shopperService.state.shopperIdHash;
				const localStorageKey = `${this.localStorageKey}_${shopperId}`;

				const medalliaData: MedalliaData = this.localStorageService.getItem(localStorageKey) || {};
				// we have to push to local storage and rehydrate when user reloads the app.
				this.localStorageService.setItem(
					localStorageKey,
					JSON.stringify({
						...medalliaData,
						[`${this.medalliaPrefix}${key}`]: value,
					})
				);
			}
		}
	}

	pushShopperSpecificInformation(): void {
		this.shopperService.state$.pipe(filter(this.isLoggedInShopper), take(1)).subscribe((state: ShopperState) => {
			this.push(MedalliaKeys.scvId, state.shopperScvId || '', false);
		});
	}

	trackExpressAbandonedEvent(isExpressAbandoned: boolean): void {
		this.push(MedalliaKeys.isExpressAbandoned, isExpressAbandoned, false);
	}

	isLoggedInShopper = (s: ShopperState): boolean => Boolean(s.isLoggedIn && s.isShopper);

	rehydrateFromLocalStorage(): void {
		if (!isPlatformBrowser(this.platform) || !this.window) {
			return;
		}
		this.shopperService.state$.pipe(filter(this.isLoggedInShopper), take(1)).subscribe((state: ShopperState) => {
			const shopperId = state.shopperIdHash;
			const medalliaData: MedalliaData =
				this.localStorageService.getItem(`${this.localStorageKey}_${shopperId}`) || {};
			Object.keys(medalliaData).forEach((key: string) => (this.window[key] = medalliaData[key]));

			// we have to notify medallia after we add variables to window object
			this.refreshMedallia();
		});
	}

	refreshMedallia(): void {
		this.window.KAMPYLE_ONSITE_SDK?.updatePageView();
	}
}
