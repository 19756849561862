import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LoggingService } from './logging.service';
import { CustomWindow, WINDOW } from './window.service';

/**
 * This service will primarily be used by the Track Impression directive and allow multiple elements to be
 * tracked by a single Intersection Observer.
 * It can also be injected by any other component who would like to add / remove any local elements and register their
 * own callback functions for custom code.
 * The options for the shared intersection observer will be the same for all elements.  There are two threshold values
 * which will trigger the callback.
 *  - 0.2 (20%) > This is used by the track promo impression directive to indicate that the element is "Out of view" / hidden.
 *  - 0.5 (50%) > This is used by the track promo impression directive to indicate that the element is visible enough to
 *                track promo impression analytics data for the element.
 */
@Injectable({
	providedIn: 'root',
})
export class ImpressionIntersectionService {
	public readonly defaultHiddenThreshold = 0.2;
	public readonly defaultVisibleThreshold = 0.5;

	/**
	 * As each observable child attaches itself to the parent observer, we need to
	 * map Elements to Callbacks so that when an Element's intersection changes,
	 * we'll know which callback to invoke
	 * @private
	 */
	private mapping: Map<Element, Function> = new Map();

	private sharedObserver: IntersectionObserver;

	constructor(
		@Inject(WINDOW) protected window: CustomWindow,
		@Inject(PLATFORM_ID) private platformId: Object,
		private loggingService: LoggingService
	) {
		if (isPlatformServer(platformId)) {
			return;
		}
		this.initSharedObserver();
	}

	/**
	 * Not all browsers support the Intersection Observer API.  This will return if it's available on the current browser.
	 * @returns True if the browser supports the Intersection Observer API, False if it doesn't
	 */
	canIntersect(): boolean {
		// TODO: CT1EK-2463 - Implement the IntersectionObserver polyfill to cater for browsers not supporting the API natively
		return this.window && 'IntersectionObserver' in this.window;
	}

	/**
	 * Add element to list of elements which will be tracked by the intersection observer.
	 * @param element The element to observer
	 * @param callback The callback function to call when any of the threshold values are intersected
	 */
	public add(element: HTMLElement, callback: Function): void {
		if (!this.canIntersect()) {
			this.loggingService.warn('ImpressionIntersectionService.add() - The intersection observer API is not available');
			return;
		}

		if (!this.mapping.has(element)) {
			this.mapping.set(element, callback);
			this.sharedObserver.observe(element);
		}
	}

	/**
	 * Remove an element from the list of elements tracked by the intersection observer
	 * @param element The element to unobserve
	 */
	public remove(element: HTMLElement): void {
		if (!this.canIntersect()) {
			this.loggingService.warn(
				'ImpressionIntersectionService.remove() - The intersection observer API is not available'
			);
			return;
		}

		if (this.mapping.has(element)) {
			this.sharedObserver.unobserve(element);
			this.mapping.delete(element);
		}
	}

	/**
	 * Create a shared instance of the intersection observer with the following threshold values.
	 * - 0.2 (20%)
	 * - 0.5 (50%)
	 * The callback function for each element intersecting those % visibility thresholds will be called
	 * @private
	 */
	private initSharedObserver(): void {
		if (!this.canIntersect()) {
			this.loggingService.warn(
				'ImpressionIntersectionService.initSharedObserver() - The intersection observer API is not available'
			);
			return;
		}

		this.sharedObserver = new IntersectionObserver(
			(entries: IntersectionObserverEntry[]) => {
				for (const entry of entries) {
					const callback = this.mapping.get(entry.target);

					if (callback) {
						callback([entry]);
					}
				}
			},
			{
				threshold: [this.defaultHiddenThreshold, this.defaultVisibleThreshold],
			}
		);
	}
}
