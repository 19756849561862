import {
	AfterViewInit,
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	Output,
	PLATFORM_ID,
} from '@angular/core';
import { noIntersectionObserver } from '../helpers/browser.helper';
import { AppSettingsService, CustomWindow, WINDOW } from '../services';

/**
 * Can be attached to any element with a grid css property in the host style
 * and will emit the number of columns being calculated and rendered by the component
 *
 * Usage: `(cdxGridColumnCount)="gridColumnsChanged($event)"` where $event is the number of columns
 */
@Directive({
	selector: '[cdxGridColumnCount]',
	standalone: true
})
export class GridColumnCountDirective implements AfterViewInit {
	@Output('cdxGridColumnCount') columnCountChange = new EventEmitter<number | undefined>();

	@Input() target: string;

	private previousColumnCount: number | undefined;

	constructor(
		private elementRef: ElementRef,
		@Inject(PLATFORM_ID) private platformId: object,
		@Inject(WINDOW) private window: CustomWindow,
		private appSettingsService: AppSettingsService
	) { }

	@HostListener('window:resize')
	onResize(): void {
		this.getColumnCount();
	}

	ngAfterViewInit(): void {
		this.getColumnCount();
	}

	private getColumnCount(): void {
		if (noIntersectionObserver(this.platformId, this.window)) {
			// Fallback to computedStyle check, which has performance impacts, when IntersectionObserver is not supported

			let grid: HTMLElement;
			if (this.target) {
				grid = this.elementRef.nativeElement.querySelector(this.target);
			} else {
				grid = this.elementRef.nativeElement;
			}
			const gridComputedStyle = this.window.getComputedStyle(grid);
			const columnCount = gridComputedStyle?.getPropertyValue('grid-template-columns')?.split(' ').length;

			if (columnCount !== this.previousColumnCount) {
				this.columnCountChange.emit(columnCount);
				this.previousColumnCount = columnCount;
			}
			return;
		}

		// Uses IntersectionObserver to avoid causing the browser to reflow
		const observer = new IntersectionObserver((entries) => {
			for (const entry of entries) {
				const bounds = entry.boundingClientRect;

				let columnCount = 1;

				if (bounds.width < this.appSettingsService.getBreakpointSize('mobile')) {
					columnCount = 1;
				} else if (bounds.width < this.appSettingsService.getBreakpointSize('tablet')) {
					columnCount = 2;
				} else if (bounds.width < this.appSettingsService.getBreakpointSize('desktop')) {
					columnCount = 3;
				} else if (bounds.width < this.appSettingsService.getBreakpointSize('large')) {
					columnCount = 4;
				} else {
					columnCount = 5;
				}

				if (columnCount !== this.previousColumnCount) {
					this.columnCountChange.emit(columnCount);
					this.previousColumnCount = columnCount;
				}
			}
			// Disconnect the observer to stop from running in the background
			observer.disconnect();
		});
		observer.observe(document.body);
	}
}
