import { Directive, Input, HostBinding } from '@angular/core';

/**
 * @description Apply this to a component which contains
 * elements which have the GridDirective
 */
@Directive({
	selector: '[cdxGridContainer]',
	standalone: true
})
export class GridContainerDirective {
	@Input() cdxGridContainer = 12;

	@Input() class = '';
	@HostBinding('class')
	get hostClasses(): string {
		return [this.class, this.gridClass].join(' ');
	}

	get gridClass(): string {
		return this.cdxGridContainer === 12 ? 'u-grid' : `u-grid-${this.cdxGridContainer}-col`;
	}
}
