import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomWindow, WINDOW } from '@woolworthsnz/styleguide';
import { ContextResponse } from '@woolworthsnz/trader-api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class BasketInterceptor implements HttpInterceptor {
	constructor(@Inject(WINDOW) private window: CustomWindow) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					const hasBasket = event?.body?.context?.basketTotals;
					const windowHasSetTrolleyFunction =
						this.window.COUNTDOWN_NS && typeof this.window.COUNTDOWN_NS.SetTrolley === 'function';

					if (windowHasSetTrolleyFunction && hasBasket) {
						const { subtotal, savings, deliveryFees, totalItems } = event.body.context.basketTotals;

						const { method } = (<ContextResponse>event.body.context).fulfilment || {};

						this.window.COUNTDOWN_NS.SetTrolley('0', subtotal, savings, deliveryFees, totalItems, method);
					}
				}
			})
		);
	}
}
