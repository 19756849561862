import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppSettingsService } from '../services';

@Directive({
	selector: '[cdxEmbedded]',
	standalone: true
})
export class EmbeddedVisibilityDirective implements OnInit {
	@Input('cdxEmbedded') isVisible: 'show' | 'hide' = 'hide';

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private appSettingsService: AppSettingsService
	) { }

	ngOnInit(): void {
		const shouldBeVisible =
			(this.appSettingsService.getSetting('isEmbeddedInApp') && this.isVisible === 'show') ||
			(!this.appSettingsService.getSetting('isEmbeddedInApp') && this.isVisible === 'hide');

		if (shouldBeVisible) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
