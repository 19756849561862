import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { isLinkExternal } from '../helpers';
import { CustomWindow, FeatureService, FeatureState, WINDOW } from '../services';

@Injectable({
	providedIn: 'root',
})
export class FeatureRedirectGuard {
	constructor(
		private featureService: FeatureService,
		private router: Router,
		@Inject(PLATFORM_ID) private platformId: Object,
		@Inject(WINDOW) private window: CustomWindow
	) {}

	canActivate(
		next: ActivatedRouteSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const routeFeature = next.data.feature || null;
		const redirectUrl: string = isPlatformServer(this.platformId) ? '/' : next.data.featureRedirectUrl || '/';
		const externalTarget: string = next.data.externalFeatureRedirectTarget;
		const redirectWhenFeatureEnabled = next.data.redirectWhenFeatureEnabled ?? false;

		return this.featureService.state$.pipe(
			filter((s) => !!s.updatedFromServer),
			map((s) => {
				// if the feature is on and the feature switch indicates the new feature is internal OR the feature is off but the switch indicates the new feature is external
				if (
					((s as FeatureState).enabledFeatures.includes(routeFeature) && !redirectWhenFeatureEnabled) ||
					(!(s as FeatureState).enabledFeatures.includes(routeFeature) && redirectWhenFeatureEnabled)
				) {
					return true;
				} else {
					if (isLinkExternal(this.platformId, redirectUrl, this.window.location)) {
						if (externalTarget) {
							this.window.open(redirectUrl, externalTarget);
						} else {
							this.window.location = redirectUrl;
						}
						return false;
					}
					return this.router.parseUrl(redirectUrl);
				}
			}),
			// ensure the observable completes otherwise SSR render seems to never get past guard
			take(1)
		);
	}
}
