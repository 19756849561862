<div>
	<svg
		width="100%"
		viewBox="0 0 25 25"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<defs>
			<polygon id="path-1" points="0 0 15.6847983 0 15.6847983 13.875 0 13.875"></polygon>
		</defs>
		<g id="Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g id="Group-3" transform="translate(4.454898, 5.500000)">
				<mask id="mask-2" fill="white">
					<use xlink:href="#path-1"></use>
				</mask>
				<g id="Clip-2"></g>
				<path
					d="M13.3012939,6.30280372 C12.6141738,7.78502857 11.5638875,9.06696906 10.243338,10.0315916 L4.48697271,9.06998539 C5.86965865,7.68187006 7.35791616,6.38303811 8.91977213,5.20304959 L13.3012939,6.30280372 Z M5.56199293,3.75641745 L6.01926865,6.01504578 C4.28367203,7.43332442 2.73689771,9.0573168 1.40790246,10.8568596 C1.90016761,7.75667506 3.29371234,5.37317064 5.56199293,3.75641745 L5.56199293,3.75641745 Z M7.07317455,5.13970665 L6.6557144,3.08136266 C8.49627932,2.14328385 10.5148077,1.54846346 12.5695321,1.33189093 C10.6408904,2.45215611 8.79791237,3.72866721 7.07317455,5.13970665 L7.07317455,5.13970665 Z M8.67363955,10.9925945 C6.76550883,11.9535974 4.40553179,12.5055859 1.63835012,12.6370979 C2.15896877,11.7352151 2.77852307,10.8930556 3.48555096,10.1263044 L8.67363955,10.9925945 Z M10.1697395,4.27281324 C11.5186426,3.29491887 12.9489865,2.4183732 14.4336244,1.65946443 C14.3545966,2.85513787 14.1259587,4.03331659 13.7507272,5.17167975 L10.1697395,4.27281324 Z M15.5870692,0.293669945 C15.4778781,0.114499909 15.2884525,0.0047054757 15.0682604,-0.000120653224 C5.33697467,0.0988149897 0.126565231,4.68182767 -0.000120653223,13.258462 C-0.000120653223,13.593878 0.271952365,13.8701739 0.617020583,13.875 C10.347703,13.7760644 15.5575092,9.19305168 15.6847983,0.609781389 C15.6823853,0.497573892 15.6492056,0.387779459 15.5870692,0.293669945 L15.5870692,0.293669945 Z"
					id="Fill-1"
					mask="url(#mask-2)"
				></path>
			</g>
		</g>
	</svg>
	<h2 i18n="@@sustainable-badge">Sustainable</h2>
</div>
