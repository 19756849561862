<ul data-cy="navigationBar" #navList>
	<li *ngFor="let item of navItems" #navElements>
		<a
			[routerLink]="[item.url]"
			routerLinkActive="active"
			[routerLinkActiveOptions]="{exact: item.exact}"
		>
			{{ item.text }}
		</a>
	</li>
</ul>