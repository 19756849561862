import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FulfilmentStoreService } from '../services';

@Injectable({
	providedIn: 'root',
})
export class TimeslotReservedRedirectGuard {
	constructor(private fulfilmentStoreService: FulfilmentStoreService, private router: Router) {}

	canActivate(): Observable<boolean | UrlTree> {
		return this.fulfilmentStoreService.state$.pipe(
			filter((s) => !!s.updatedFromServer),
			map((s) => {
				if (s.selectedDate && s.startTime) {
					return true;
				} else {
					return this.router.createUrlTree([`/checkout/bookatimeslot`]);
				}
			})
		);
	}
}
