import { Injectable } from '@angular/core';
import { LocalStorageService, StatefulService, ShopperService } from '@woolworthsnz/styleguide';
import {
	ExpressFulfilmentAvailabilityStatusResponse,
	ExpressFulfilmentInformation,
	FeesAndPasses,
	FulfilmentWindowsSummaryResponse,
	FulfilmentWindowSummarySlot,
	LockerInformation,
	NonTradingDaySummaryResponse,
	OrderViewModel,
	ShopperFulfilmentDetails,
	SlotsOffering,
} from '@woolworthsnz/trader-api';
import { Subject } from 'rxjs';
import { DaySlots, PickupType } from '../ui-models';

export class FulfilmentState implements ShopperFulfilmentDetails, FulfilmentWindowsSummaryResponse {
	updatedFromServer? = false;
	address? = 'Countdown Glenfield';
	areaId?: number;
	clientSelectedDate?: Date | string;
	currentReservationAlertsFired: {
		closingSoon: boolean;
		closingNow: boolean;
		closed: boolean;
		expired?: boolean;
	} = {
		closed: false,
		closingNow: false,
		closingSoon: false,
		expired: false,
	};
	cutOffTime?: string;
	daySlots?: DaySlots;
	deliveryPass?: FeesAndPasses;
	deliveryFeesNote?: string;
	deliverySaverUpSellNote?: string;
	endTime?: string;
	fulfilmentStoreId?: number;
	isSlotToday?: boolean;
	isAddressInDeliveryZone?: boolean;
	method?: OrderViewModel.DeliveryMethodEnum = OrderViewModel.DeliveryMethodEnum.Courier;
	nonTradingDays?: Array<NonTradingDaySummaryResponse>;
	perishableCode: ShopperFulfilmentDetails.PerishableCodeEnum = ShopperFulfilmentDetails.PerishableCodeEnum.P;
	serverTimeZoneOffset: string;
	slots?: Array<FulfilmentWindowSummarySlot>;
	selectedDate?: Date | string;
	selectedDateWithTZInfo?: Date | string;
	suburb?: string | { id: number; name?: string };
	suburbId?: number;
	pickupAddressId?: number;
	startTime?: string;
	locker?: LockerInformation;
	error?: any;
	completed?: boolean;
	pickupType?: PickupType;
	isExpressAbandoned?: boolean;
	expressSlotStatus: ExpressFulfilmentAvailabilityStatusResponse.AvailabilityStatusEnum;
	expressFulfilment?: ExpressFulfilmentInformation;
	sameDayDeliveryFeeMinimum?: number;
	sameDayDeliveryFeeMaximum?: number;
	standardDeliveryFeeMinimum?: number;
	standardDeliveryFeeMaximum?: number;
	pickupStoreType?: SlotsOffering.ValueEnum;
}

@Injectable({
	providedIn: 'root',
})
export class FulfilmentStoreService extends StatefulService<FulfilmentState> {
	expressItemQuantityExceededWhenItemIsAdded$ = new Subject<void>();
	constructor(
		private localStorageService: LocalStorageService,
		private shopperService: ShopperService
	) {
		super(new FulfilmentState());
	}

	setState(state: Partial<FulfilmentState>): void {
		// write method and suburb ID to local storage (this will allow us to preserve ranging in the event the user gets logged out)
		if (this.shopperService.isLoggedInUser) {
			if (
				(state.method !== this.state.method && state.method) ||
				(state.suburbId !== this.state.suburbId && state.suburbId) ||
				(state.pickupAddressId !== this.state.pickupAddressId && state.pickupAddressId)
			) {
				if (state.method) {
					this.localStorageService.setItem('fulfilmentMethod', state.method);
				}
				if (state.suburbId) {
					this.localStorageService.setItem('fulfilmentSuburbId', state.suburbId.toString());
				}
				if (state.pickupAddressId) {
					this.localStorageService.setItem('fulfilmentPickupAddressId', state.pickupAddressId.toString());
				}
			}
		}

		super.setState(state);
	}

	getIsExpressFulfilmentSlot(): boolean | undefined {
		return this.state?.expressFulfilment?.isExpressSlot;
	}

	getFulfilmentMethod(): OrderViewModel.DeliveryMethodEnum | undefined {
		return this.state?.method;
	}
}
