<div
	*ngIf="showAlert"
	[attr.data-fullwidth]="fullwidth"
	[attr.data-iconsize]="iconsize"
	[attr.data-inlinelink]="inlineLink"
	[attr.data-type]="type"
	[attr.data-withmargin]="withMargin"
	[attr.describedBy]="alertDescriptionId"
	[attr.labelledBy]="alertLabelId"
	[attr.role]="role"
	[class.alert--candismiss]="candismiss"
	class="alert"
	id="cdx-alert"
>
	<cdx-svg-icon
		*ngIf="icon && showIcon"
		[fill]="type"
		[name]="icon"
		[size]="iconsize"
		class="alert-icon"
	></cdx-svg-icon>

	<div class="alert-content">
		<span
			*ngIf="showTitle && title"
			[innerHTML]="title"
			class="alert-label"
			data-cy="alertLabel"
			id="{{ alertLabelId }}"
		>
		</span>

		<div *ngIf="description" class="alert-description" data-cy="alertDescription" id="{{ alertDescriptionId }}">
			<span *ngFor="let item of description">
				<ng-container *ngIf="item?.message; else descriptionTemplate">
					<ng-container *ngIf="item?.field">{{ item.field }}: </ng-container>{{ item?.message }}
				</ng-container>
				<ng-template #descriptionTemplate>
					<span [innerHTML]="item"></span>
				</ng-template>
			</span>
		</div>

		<ng-content select="[alertLink]"></ng-content>
	</div>

	<div *ngIf="showCta" class="alert-cta">
		<ng-content select="[cta]"></ng-content>
	</div>
	<button (click)="closeAlert()" *ngIf="candismiss" aria-label="Dismiss alert" class="alert-closeBtn" type="button"></button>
</div>
