import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

/**
 * Same as the currency pipe (uses it internally) but truncates the '.00' from $20.00, but leave the '.50' and '.75' on $2.50 and $2.75 respectively.
 */
@Pipe({
	name: 'truncatedCurrency',
	standalone: true
})
export class TruncatedCurrencyPipe implements PipeTransform {
	constructor(private currencyPipe: CurrencyPipe) { }

	transform(
		value: any,
		currencyCode?: string,
		display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol'
	): string | null {
		const parsedValue = parseFloat(value);

		if (isNaN(parsedValue)) {
			return null;
		}

		const hasDecimal = parsedValue % 1 !== 0;
		if (hasDecimal) {
			// If it has a decimal place (e.g. 2.5, 36.75, etc.) we want to use the default currency pipe, which will map to $2.50, $36.75
			// unfortunately this also transform 20 into $20.00
			return this.currencyPipe.transform(parsedValue, currencyCode, display);
		} else {
			// If it does not have a decimal (e.g. 20) we can safely set the minFractionDigits to 0 to output $20
			// unfortunately this also transform 2.5 into $2.5, so we have these two branches
			return this.currencyPipe.transform(parsedValue, currencyCode, display, '.0');
		}
	}
}
