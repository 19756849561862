import { A11yModule } from '@angular/cdk/a11y';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {
	ButtonComponent,
	ButtonGroupComponent,
	FooterComponent,
	IconCircleComponent,
	ImageComponent,
	LoadingComponent,
	LogoComponent,
	NavComponent,
	NotificationComponent,
	OptionListComponent,
	OptionListItemComponent,
	OptionListMultiComponent,
	PointerComponent,
	PoweredByOnecardComponent,
	SectionComponent,
	SuccessIconComponent,
	SustainableBadgeComponent,
	SvgDefinitionsComponent,
	SvgIconComponent,
	WappleLoadingComponent,
} from './4_atoms/components';
import {
	AccordionComponent,
	AccordionItemComponent,
	AlertComponent,
	AutocompleteModalComponent,
	BackNavComponent,
	BreadcrumbComponent,
	CardComponent,
	CollapsibleContentComponent,
	CollapsibleNavComponent,
	CtaComponent,
	DigitalPayCreditCardComponent,
	DigitalPayFrameComponent,
	EditOrderNotificationComponent,
	EmbeddedNavComponent,
	FeatureListComponent,
	FeatureListItemComponent,
	FullBleedImageHeaderComponent,
	GenericModalComponent,
	HintComponent,
	LoadMoreComponent,
	ModalComponent,
	ModalViewComponent,
	PageHeadingComponent,
	PaginationComponent,
	PaymentInstrumentComponent,
	PersuasionBarComponent,
	PopupComponent,
	PopupTargetDirective,
	RoutedModalBaseComponent,
	SlidePanelComponent,
	SlottedModalComponent,
	TickerComponent,
	WarningComponent,
} from './5_molecules/components';
import {
	Carousel2Component,
	Carousel2ItemComponent,
	CarouselComponent,
	CutoutModalComponent,
	CutoutModalTargetDirective,
	OliveRoundelComponent,
	OliveTokenHiddenIframComponent,
	TabComponent,
	TabsComponent,
} from './6_organisms/components';
import { NotFoundErrorComponent, ServerErrorComponent } from './7_templates/components';
import { InjectorContainerModule } from './decorators';
import {
	AccordionDirective,
	AccordionItemDirective,
	CarouselDirective,
	DeferLoadDirective,
	EmbeddedVisibilityDirective,
	FocusOnChangeDirective,
	GridColumnCountDirective,
	GridContainerDirective,
	GridDirective,
	ImagePreloadDirective,
	ImageZoomDirective,
	LegacyNoAjaxDirective,
	MaybeExternalLinkDirective,
	NumericDirective,
	SanitizeHtmlPipe,
	ScrollBlockDirective,
	ScrollIntoViewDirective,
	TrackEventDirective,
	TrackImpressionDirective,
} from './directives';
import {
	BreakOnCommaPipe,
	CapitalizeSpecificWordsPipe,
	FormattedDatePipe,
	JoinPipe,
	KebabCasePipe,
	NullToZeroPipe,
	OrdinalPipe,
	PreserveNewlinePipe,
	SentenceCasePipe,
	SrPunctuationPipe,
	TruncatedCurrencyPipe,
} from './pipes';
import {
	ApiService,
	APP_SETTINGS_PROVIDER,
	AppInsightsProvider,
	BreakPointService,
	DatalayerService,
	DocumentEventService,
	DynatraceService,
	ExperimentService,
	GlobalErrorHandlerService,
	LocalStorageService,
	LoggingService,
	MarkdownService,
	ModalOverlayRef,
	ModalOverlayService,
	ModalRoutingService,
	PageVisibilityService,
	PORTAL_PROVIDER,
	PortalService,
	PromotionService,
	ServerAppInsightsProvider,
	ShopperState,
	WINDOW_PROVIDERS,
} from './services';

const exportFiles = {
	atoms: [
		ButtonComponent,
		ButtonGroupComponent,
		FooterComponent,
		IconCircleComponent,
		ImageComponent,
		LoadingComponent,
		LogoComponent,
		NavComponent,
		NotificationComponent,
		OptionListComponent,
		OptionListItemComponent,
		OptionListMultiComponent,
		PointerComponent,
		PoweredByOnecardComponent,
		SvgDefinitionsComponent,
		SvgIconComponent,
		SuccessIconComponent,
		SustainableBadgeComponent,
		WappleLoadingComponent,
	],
	molecules: [
		AccordionComponent,
		AccordionItemComponent,
		AlertComponent,
		AutocompleteModalComponent,
		BackNavComponent,
		BreadcrumbComponent,
		CardComponent,
		CollapsibleContentComponent,
		CollapsibleNavComponent,
		CtaComponent,
		DigitalPayCreditCardComponent,
		DigitalPayFrameComponent,
		EditOrderNotificationComponent,
		FullBleedImageHeaderComponent,
		HintComponent,
		GenericModalComponent,
		LoadMoreComponent,
		ModalComponent,
		ModalViewComponent,
		PaymentInstrumentComponent,
		PageHeadingComponent,
		PaginationComponent,
		PopupComponent,
		PopupTargetDirective,
		RoutedModalBaseComponent,
		SlidePanelComponent,
		SlottedModalComponent,
		TickerComponent,
	],
	organisms: [
		CarouselComponent,
		Carousel2Component,
		Carousel2ItemComponent,
		CutoutModalComponent,
		CutoutModalTargetDirective,
		TabComponent,
		TabsComponent,
		OliveRoundelComponent,
	],
	templates: [NotFoundErrorComponent, ServerErrorComponent],
	directives: [
		CarouselDirective,
		DeferLoadDirective,
		EmbeddedVisibilityDirective,
		FocusOnChangeDirective,
		GridColumnCountDirective,
		ImageZoomDirective,
		ImagePreloadDirective,
		MaybeExternalLinkDirective,
		NumericDirective,
		SanitizeHtmlPipe,
		TrackEventDirective,
		TrackImpressionDirective,
		ScrollBlockDirective,
		ScrollIntoViewDirective,
	],
	pipes: [
		BreakOnCommaPipe,
		CapitalizeSpecificWordsPipe,
		FormattedDatePipe,
		JoinPipe,
		KebabCasePipe,
		NullToZeroPipe,
		OrdinalPipe,
		SentenceCasePipe,
		SrPunctuationPipe,
		TruncatedCurrencyPipe,
		PreserveNewlinePipe,
	],
};

const declarations = {
	atoms: [SectionComponent],
	molecules: [
		EmbeddedNavComponent,
		FeatureListComponent,
		FeatureListItemComponent,
		PersuasionBarComponent,
		WarningComponent,
	],
	organisms: [OliveTokenHiddenIframComponent],
	directives: [
		AccordionDirective,
		AccordionItemDirective,
		GridDirective,
		GridContainerDirective,
		LegacyNoAjaxDirective,
	],
};

const nonSingletonProviders = [ApiService, WINDOW_PROVIDERS];

const providers = [
	APP_SETTINGS_PROVIDER,
	PORTAL_PROVIDER,
	AppInsightsProvider,
	ServerAppInsightsProvider,
	BreakPointService,
	DatalayerService,
	DocumentEventService,
	DynatraceService,
	ExperimentService,
	GlobalErrorHandlerService,
	LocalStorageService,
	LoggingService,
	ModalOverlayService,
	ModalOverlayRef,
	ModalRoutingService,
	PageVisibilityService,
	PortalService,
	PromotionService,
	ShopperState,
	TruncatedCurrencyPipe,
	MarkdownService,
	FormattedDatePipe,
	CookieService,
	{ provide: ErrorHandler, useExisting: GlobalErrorHandlerService },
];

@NgModule({
	exports: [
		...exportFiles.atoms,
		...exportFiles.directives,
		...exportFiles.molecules,
		...exportFiles.organisms,
		...exportFiles.pipes,
		...exportFiles.templates,
	],
	imports: [
		A11yModule,
		FormsModule,
		LayoutModule,
		OverlayModule,
		PortalModule,
		ReactiveFormsModule,
		RouterModule.forChild([]),
		CommonModule,
		GoogleMapsModule,
		InjectorContainerModule,
		...exportFiles.atoms,
		...exportFiles.directives,
		...exportFiles.molecules,
		...exportFiles.organisms,
		...exportFiles.pipes,
		...exportFiles.templates,
		...declarations.atoms,
		...declarations.directives,
		...declarations.molecules,
		...declarations.organisms,
	],
	providers: nonSingletonProviders,
})
export class StyleguideModule {
	static forRoot(): ModuleWithProviders<StyleguideModule> {
		return {
			ngModule: StyleguideModule,
			providers,
		};
	}
}
