import equal from 'fast-deep-equal';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export const mapFulfilmentType = (
	input: any
): {
	address: any;
	areaId: any;
	fulfilmentStoreId: any;
	method: any;
	selectedDate: any;
	startTime: any;
} => ({
	address: input.address,
	areaId: input.areaId,
	fulfilmentStoreId: input.fulfilmentStoreId,
	method: input.method,
	selectedDate: input.selectedDate,
	startTime: input.startTime,
});

export const distinctUntilFulfilmentChanged =
	() =>
	<T>(source: Observable<T>): Observable<T> =>
		source.pipe(distinctUntilChanged((a, b) => equal(mapFulfilmentType(a), mapFulfilmentType(b))));
