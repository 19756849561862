import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'kebabcase',
	standalone: true
})
export class KebabCasePipe implements PipeTransform {
	transform(value?: string | null): string | undefined | null {
		if (!value?.length) {
			return value;
		}

		return value.split(' ').join('-');
	}
}
