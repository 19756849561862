import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
	parse(url: string): UrlTree {
		let splitUrl = url.split('#');
		const urlWithQueryString = splitUrl[0];
		const hash = splitUrl[1];
		splitUrl = urlWithQueryString.split('?');
		const lowercaseAddress = splitUrl[0].toLowerCase();
		const search = splitUrl[1];
		return super.parse(`${lowercaseAddress}${search ? '?' + search : ''}${hash ? '#' + hash : ''}`);
	}
}
