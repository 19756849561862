export enum NewLayoutOfProductCategoriesAndFiltersVariants {
	Off = 'off',
	Control = 'control',
	V3 = 'v3',
}

export interface NewLayoutOfProductCategoriesAndFiltersVariables {
	searchTargets: string;
}

export enum NewLayoutOfProductCategoriesAndFiltersExp2Variants {
	Off = 'off',
	Control = 'control',
	V1 = 'v1',
	V2 = 'v2',
}
