import { Injectable } from '@angular/core';
import { filter, Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettingsService, AuthFacade, ShopperService } from '@woolworthsnz/styleguide';
import { EverydayRewardsFacade } from '../+state';

@Injectable({
	providedIn: 'root',
})
export class EDRStartupService {
	constructor(
		private appSettingsService: AppSettingsService,
		private shopperService: ShopperService,
		private edrFacade: EverydayRewardsFacade,
		private authFacade: AuthFacade
	) {}

	/**
	 * Load the global store state on application init
	 */
	public init(): void {
		const isLoggedIn$: Observable<boolean> = this.appSettingsService.useSSU()
			? this.authFacade.isLoggedIn$
			: this.shopperService.state$.pipe(map((s) => Boolean(s.isLoggedIn)));

		isLoggedIn$.pipe(filter(Boolean), take(1)).subscribe(() => {
			this.edrFacade.fetchEdrBalance();
			this.edrFacade.fetchBoostOffersCount();
			this.edrFacade.fetchRewardMessages();
		});
	}
}
