import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AppSettingsService } from './app-settings.service';
import { Observable } from 'rxjs';
import { AuthResponse } from '@woolworthsnz/trader-api';

@Injectable({
	providedIn: 'root',
})
export class UserAuthService {
	public fetchUserAuthState(isRetry?: boolean): Observable<AuthResponse> {
		const queryParam = isRetry !== undefined ? `?isRetry=${isRetry}` : '';
		return this.apiService.get(`${this.appSettingsService.getEndpoint('getUserEndpoint')}${queryParam}`);
	}

	constructor(private appSettingsService: AppSettingsService, private apiService: ApiService) {}
}
