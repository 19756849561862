import { Inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import {
	AppSettingsService,
	CustomWindow,
	FeatureService,
	HistoryService,
	LoggingService,
	ShopperService,
	ShopperState,
	WINDOW,
} from '@woolworthsnz/styleguide';
import { ContextResponse } from '@woolworthsnz/trader-api';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ShopperGuard {
	constructor(
		private shopperService: ShopperService,
		private router: Router,
		private appSettingsService: AppSettingsService,
		private historyService: HistoryService,
		private loggingService: LoggingService,
		private featureService: FeatureService,
		@Inject(WINDOW) private window: CustomWindow
	) {}

	canActivate(): Observable<boolean | UrlTree> {
		return this.shopperService.state$.pipe(
			skipWhile((s) => s.isLoggedIn === undefined),
			map((s: ShopperState) => {
				// A logged in user can be a shopper or non shopper example brand site user, as we use SSO any user from brunch,
				// pharmacy, or brandsite can log in to shop website

				const isNotAShopper = !s.isShopper;

				if (isNotAShopper && s.isLoggedIn) {
					return this.router.createUrlTree(['/shop/register/shopperdetails']);
				}

				if (isNotAShopper && !s.isLoggedIn) {
					if (this.appSettingsService.useSSU()) {
						const redirectUrl = `${this.window.origin}${this.historyService.getPreviousUrlInGuard()}`;
						const url = `${this.window.origin}${this.appSettingsService.getEndpoint(
							'oidcSignInEndpoint'
						)}?redirectUrl=${encodeURIComponent(this.appSettingsService.getAuthRedirectUrl(redirectUrl))}`;

						if (
							this.featureService.isFeatureEnabled(ContextResponse.EnabledFeaturesEnum.RedirectValidation)
						) {
							this.loggingService.trackEvent('RedirectValidation', {
								shopper: this.shopperService.state?.shopperScvId || 'unknown',
								detail: 'track redirect in Shopper guard',
								href: url,
							});
						}

						this.window.location.href = url;

						return false;
					}
					return this.router.createUrlTree(['/shop/securelogin']);
				}
				return true;
			})
		);
	}
}
