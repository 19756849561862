import { Pipe, PipeTransform } from '@angular/core';

/**
 * Replaces \\n in text with a <br/>
 */

@Pipe({
	name: 'preserveNewline',
	standalone: true
})
export class PreserveNewlinePipe implements PipeTransform {
	transform(value?: string | null): string | undefined | null {
		if (!value?.length) {
			return value;
		}

		return value?.replace(/\\n/g, '<br aria-hidden="true"/>');
	}
}
