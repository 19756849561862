import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, InjectionToken, PLATFORM_ID, makeStateKey, StateKey, TransferState } from '@angular/core';


export const REQ_RECEIVE_TIME = new InjectionToken('req_receive_time');

@Injectable({
	providedIn: 'root',
})
export class ServerStateService {
	private initiallyServerRenderedKey: StateKey<boolean> = makeStateKey('initiallyServerRendered');

	constructor(private transferState: TransferState, @Inject(PLATFORM_ID) private platformId: Object) {}

	setInitialValues(): void {
		this.transferState.set(this.initiallyServerRenderedKey, isPlatformServer(this.platformId));
	}

	wasInitiallyServerRendered(): boolean {
		return this.transferState.get(this.initiallyServerRenderedKey, false);
	}
}
