import { ConnectedPosition } from '@angular/cdk/overlay';
import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import {
	CustomWindow,
	DocumentEventService,
	ModalOverlayRef,
	ModalOverlayService,
	ModalOverlayState,
	PopupService,
	ScrollStrategy,
	WINDOW,
} from '../../../services';
import { PopupTargetDirective } from './popup-target.directive';
import { PaddingSize } from '../../../ui-models';
import { SvgIconComponent } from '../../../4_atoms/components/svg-icon/svg-icon.component';
import { A11yModule } from '@angular/cdk/a11y';
import { CardComponent } from '../card/card.component';
import { PointerComponent } from '../../../4_atoms/components/pointer/pointer.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
	selector: 'cdx-popup',
	template: `
		<ng-content></ng-content>
		<ng-template cdkPortal #overlayTemplate="cdkPortal">
			<div class="popupContainer" #modalContainer>
				<cdx-pointer *ngIf="showPointer" [position]="pointerPosition" [rounded]="true"></cdx-pointer>
				<cdx-card
					[padding]="padding"
					roundedCorners="medium"
					cdkTrapFocus
					#cardComponent
					[ngClass]="{ canClose: hasCloseBtn }"
				>
					<button
						cdkFocusInitial
						aria-label="Dismiss popup"
						type="button"
						class="closeBtn"
						*ngIf="hasCloseBtn"
						(click)="onClose()"
					>
						<cdx-svg-icon name="cross" [size]="closeButtonSize" [fill]="'dark'"></cdx-svg-icon>
					</button>
					<ng-content select="[cardContent]"></ng-content>
				</cdx-card>
			</div>
		</ng-template>
	`,
	styleUrls: [`./popup.component.scss`],
	providers: [ModalOverlayService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [PortalModule, NgIf, PointerComponent, CardComponent, A11yModule, NgClass, SvgIconComponent],
})
export class PopupComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('overlayTemplate') overlayTemplate: CdkPortal;

	@ViewChildren('modalContainer', { read: ElementRef }) modalContainerQuery: QueryList<ElementRef>;
	@ViewChildren('cardComponent', { read: ElementRef }) cardComponentQuery: QueryList<ElementRef>;

	@ContentChild(PopupTargetDirective) target: PopupTargetDirective;

	@Output() modalOpen = new EventEmitter<boolean>();

	@Input() popupActiveClass: string;
	@Input() popupPointerOffsetX = 0;
	@Input() popupPosition: 'start' | 'end' = 'start';
	@Input() closeOnRouteChange = true;
	@Input() scrollStrategy: ScrollStrategy = 'reposition';
	@Input() showPointer = true;
	@Input() customClass = '';
	@Input() popupTracking: { name?: string; value?: string };
	@Input() popupId: string;
	@Input() hasBackdrop = false;
	@Input() shouldListenToClickAway = true;
	@Input() hasCloseBtn = false;
	@Input() padding: PaddingSize = PaddingSize.Default;
	@Input() closeButtonSize: 'tiny' | 'very-small' | 'small' = 'tiny';

	modalContainerRef: ElementRef;

	get pointerPosition(): { right: string; left?: undefined } | { left: string; right?: undefined } {
		const pointerOffset = 12;
		const pointerWidth = 8;

		// has to include the width of the pointer
		if (this.popupPosition === 'end') {
			return { right: `${pointerOffset + pointerWidth + this.popupPointerOffsetX}px` };
		}

		return { left: `${pointerOffset + this.popupPointerOffsetX}px` };
	}

	private overlay?: ModalOverlayRef;
	private clickAwaySubscription: Subscription;
	private destroyed$: Subject<boolean> = new Subject();

	constructor(
		private modalOverlayService: ModalOverlayService,
		private documentEventService: DocumentEventService,
		private element: ElementRef,
		private router: Router,
		private cdr: ChangeDetectorRef,
		@Inject(WINDOW) private window: CustomWindow,
		private popupService: PopupService
	) {}

	@HostListener('document:keydown', ['$event'])
	onKeydown(event: KeyboardEvent): void {
		// 'Esc' is IE/Edge specific, it's 'Escape' in every other browser
		if (event.key === 'Esc' || event.key === 'Escape') {
			this.closeOverlay();
			event.preventDefault();
		}
	}

	ngOnInit(): void {
		this.popupService.showPopup$.pipe(takeUntil(this.destroyed$)).subscribe((id) => {
			if (id === this.popupId) {
				this.openModal();
			}
		});

		this.popupService.closePopup$.pipe(takeUntil(this.destroyed$)).subscribe((id) => {
			if (id === this.popupId) {
				this.closeOverlay();
			}
		});

		this.router.events
			.pipe(
				takeUntil(this.destroyed$),
				filter((ev): ev is NavigationStart => ev instanceof NavigationStart)
			)
			.subscribe(() => {
				if (this.closeOnRouteChange) {
					this.closeOverlay();
				}
			});
	}

	ngAfterViewInit(): void {
		this.target?.click.pipe(takeUntil(this.destroyed$)).subscribe(() => {
			if (!this.overlay) {
				this.openModal();
				return;
			}

			this.closeOverlay();
		});

		// Can't use ViewChild directly on the element as it is added and removed from DOM by cdk
		this.modalContainerQuery.changes
			.pipe(takeUntil(this.destroyed$))
			.subscribe((elements: QueryList<ElementRef>) => {
				this.modalContainerRef = elements.first;
			});

		// Using cdkTrapFocusAutoCapture returns focus to the popup trigger after the modal closes
		// This is normally ok with modals with a backdrop, but since you can click on other elements
		// while these popups are open we don't want to steal focus back. Instead we are attempting to gain
		// focus when the modal opens here
		this.cardComponentQuery.changes
			.pipe(takeUntil(this.destroyed$))
			.subscribe((elements: QueryList<ElementRef>) => {
				const cardEl = elements.first;
				if (cardEl?.nativeElement) {
					// Basic list of elements that could be focusable. Doesn't check all cases (e.g. visibility) but should work well enough
					const firstFocusableEl = cardEl.nativeElement.querySelector(
						'input, select, textarea, button, a, [tabindex]'
					);
					if (firstFocusableEl) {
						firstFocusableEl.focus();
					}
				}
			});
	}

	ngOnDestroy(): void {
		this.closeOverlay();
		this.destroyed$.next(true);
		this.destroyed$.complete();
		if (this.clickAwaySubscription) {
			this.clickAwaySubscription.unsubscribe();
		}
	}

	onClose(): void {
		this.popupService.close(this.popupId);
	}

	openModal(): void {
		const panelClass = this.customClass;
		const state: Partial<ModalOverlayState> = {
			...(!!panelClass && { panelClass }),
			element: this.target.elementRef.nativeElement,
			hasBackdrop: this.hasBackdrop,
			isConnectedElement: true,
			scrollStrategy: this.scrollStrategy,
			connectedPositions: this.getConnectedPosition(this.popupPosition),
		};

		this.modalOverlayService.setState(state);

		const overlay = this.modalOverlayService.open({
			templateRef: this.overlayTemplate,
			trackingData: this.popupTracking,
		});
		this.modalOpen.emit(true);

		if (this.target && this.popupActiveClass) {
			this.target.class = this.popupActiveClass;
		}

		if (overlay) {
			// starts listening to document clicks then once clicked it unsubscribes
			// until next time popup modal is open this avoids listening to clicks
			// while modal is closed or triggering duplicate events

			if (this.shouldListenToClickAway) {
				this.subscribeToClickAwayListener();
			}
			this.overlay = overlay;
		}

		this.cdr.markForCheck();
	}

	closeOverlay(): void {
		if (!this.overlay) {
			return;
		}

		this.overlay.close();
		this.modalOpen.emit(false);

		// If focus is on the body try to refocus the target element as it means the popup was closed
		// but no other element was focused. This is to maintain tab order after closing
		// setTimeout to handle do this on next tick when closing the modal with escape
		setTimeout(() => {
			if (this.window.document.activeElement === this.window.document.body) {
				this.target?.elementRef?.nativeElement?.focus();
			}
		});

		// remove active class
		if (this.target?.class) {
			this.target.class = '';
			this.cdr.markForCheck();
		}

		this.overlay = undefined;
	}

	private subscribeToClickAwayListener(): void {
		if (this.clickAwaySubscription) {
			this.clickAwaySubscription.unsubscribe();
		}

		this.clickAwaySubscription = this.documentEventService.documentClick$
			.pipe(
				takeUntil(this.destroyed$),
				filter((event) => {
					if (!event.target) {
						return false;
					}

					// In ShadowDOM the event.target is the element containing the shadowRoot, so we check composedPath instead
					// @ts-ignore
					if (event.target.shadowRoot) {
						return (
							!this.element.nativeElement.contains(event.composedPath()[0]) &&
							!this.modalContainerRef?.nativeElement.contains(event.composedPath()[0])
						);
					}

					return (
						!this.element.nativeElement.contains(event.target) &&
						!this.modalContainerRef?.nativeElement.contains(event.target)
					);
				}),
				take(1)
			)
			.subscribe(() => this.closeOverlay());
	}

	private getConnectedPosition = (position: 'start' | 'end'): ConnectedPosition[] => [
		{
			originX: position,
			originY: 'bottom',
			overlayX: position,
			overlayY: 'top',
		},
	];
}
