<div class="warning" id="warning" [attr.data-type]="'warning'" [attr.data-visible]="isVisible && message">
	<i class="warning-icon">
		<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
			<use href="#alert" />
		</svg>
	</i>
	<div class="warning-content">
		<span class="warning-label" id="warning-label">
			{{ message }}
		</span>
	</div>

	<button aria-label="Dismiss alert" *ngIf="hasCloseButton" class="warning-closeBtn" id="warning-closeBtn"></button>
</div>
