import { ContentChildren, Directive, Input, QueryList, AfterContentInit, OnDestroy } from '@angular/core';
import { AccordionItemDirective } from './accordion-item.directive';
import { Subject, takeUntil } from 'rxjs';

@Directive({
	selector: '[cdxAccordion]',
	standalone: true,
})
export class AccordionDirective implements AfterContentInit, OnDestroy {
	@Input() openMultiple = false;

	@ContentChildren(AccordionItemDirective)
	items: QueryList<AccordionItemDirective>;
	private expandedItem: AccordionItemDirective;
	private destroyed$: Subject<boolean> = new Subject();

	ngAfterContentInit(): void {
		this.items.forEach((accordionItem) => {
			accordionItem.toggleExpandCollapse
				.pipe(takeUntil(this.destroyed$))
				.subscribe(() => this.onItemExpanded(accordionItem));
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onItemExpanded(item: AccordionItemDirective): void {
		if (!this.openMultiple && item !== this.expandedItem) {
			if (this.expandedItem) {
				this.expandedItem.expanded = false;
			}
			this.expandedItem = item;
		}
	}
}
