import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'cdx-image, [cdxImage]',
	template: ` <ng-content></ng-content> `,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class ImageComponent {
	@Input() imageFolder: string;
}
