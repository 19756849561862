<global-nav
	*ngIf="appService.mainHeaderVisible$ | async"
	[externalLinksInCurrentTab]="true"
	[trolleyLink]="trolleyLink"
	[mainNav]="navs.main"
	[mobileNav]="navs.mobileNav"
	[timeslotRoute]="timeslotRoute"
	[simplified]="appService.useFunnelHeader$ | async"
	(logout)="authenticationService.doLogout()"
	[class.nav-experiment-v1]="isNavExperiment7VariationActive$ | async"
></global-nav>

<div
	[attr.aria-busy]="appService.loading$ | async"
	class="main"
	[ngClass]="{ 'main--flex': appService.mainFlex$ | async }"
	[@.disabled]="appService.useFunnelHeader$ | async"
>
	<router-outlet name="shopping-list"></router-outlet>
	<div [ngClass]="{ 'main-dc': shouldShowNewLayoutOfProductCategoriesAndFilters$ | async }">
		<cdx-breadcrumb></cdx-breadcrumb>
		<div
			class="banner-container"
			id="header-banner"
			[class.hideBannerContainer]="(appService.bannersVisible$ | async) === false"
		></div>
		<div
			#dynamicContentSecondaryPanel
			id="dynamic-content-secondary-panel"
			class="dynamic-content-canvas"
			[class.hideSecondaryPanel]="(appService.dynamicContentSecondaryPanelVisible$ | async) === false"
		></div>
	</div>
	<router-outlet></router-outlet>
</div>

<ng-container *ngIf="appService.isHomePage(); else noLazyload">
	@defer( when appSettingsService.isDynamicConentShown()) {
		<global-nav-footer *cdxEmbedded="'hide'" [simplified]="appService.useFunnelHeader$ | async"></global-nav-footer>
		}
</ng-container>
<ng-template #noLazyload>
	<global-nav-footer *cdxEmbedded="'hide'" [simplified]="appService.useFunnelHeader$ | async"></global-nav-footer>
</ng-template>

<cdx-svg-definitions></cdx-svg-definitions>
<h3 class="app-loading-text" *ngIf="(appService.loading$ | async) && (appService.loadingText$ | async) !== undefined">{{appService.loadingText$ | async}}</h3>
<cdx-wapple-loading *ngIf="appService.loading$ | async"></cdx-wapple-loading>
<cdx-olive-roundel *cdxEmbedded="'hide'"></cdx-olive-roundel>
<cdx-modal-view></cdx-modal-view>
<wnz-iam-hidden-iframe *ngIf="(checkSSU$ | async) !== true"></wnz-iam-hidden-iframe>
