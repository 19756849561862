import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { REVIEW_ORDER_PATH } from '@woolworthsnz/shop';
import { AppSettings, AppSettingsService } from '@woolworthsnz/styleguide';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class HaveYouForgottenPreferenceGuard {
	constructor(private appSettingsService: AppSettingsService, private router: Router) {}

	canActivate(): Observable<boolean | UrlTree> {
		return this.appSettingsService.state$.pipe(
			filter((s: AppSettings) => s.updatedFromServer),
			map((s) => {
				if (s.advancedSettings?.showHaveYouForgotten === false) {
					this.router.navigateByUrl(REVIEW_ORDER_PATH);
					return false;
				}
				return true;
			})
		);
	}
}
