import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FeatureService } from '@woolworthsnz/styleguide';
import { ContextResponse, EdrBalanceResponseV2, RewardMessagesResponse } from '@woolworthsnz/trader-api';
import { EDRService } from '../services';
import * as EDRActions from './everyday-rewards.actions';

@Injectable()
export class EverydayRewardsEffects {
	public fetchEdrBalance$ = createEffect(() =>
		this.actions$.pipe(
			ofType(EDRActions.fetchEdrBalance),
			switchMap(() =>
				this.edrService.fetchEdrBalance().pipe(
					map((edrBalanceResponse: EdrBalanceResponseV2) =>
						EDRActions.fetchEdrBalanceSuccess({ edrBalanceResponse })
					),
					catchError((error: any) => of(EDRActions.fetchEdrBalanceFailed(error)))
				)
			)
		)
	);

	public fetchBoostOffersCount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(EDRActions.fetchBoostOffersCount),
			switchMap(() =>
				this.edrService.fetchBoostOffersCount().pipe(
					map((response) =>
						EDRActions.fetchBoostOffersCountSuccess({ boostOffersCount: response.totalOffers })
					),
					catchError((error: any) => of(EDRActions.fetchBoostOffersCountFailed(error)))
				)
			)
		)
	);

	public fetchRewardMessages$ = createEffect(() =>
		this.actions$.pipe(
			ofType(EDRActions.fetchRewardMessages),
			switchMap(() => this.featureService.isEnabled(ContextResponse.EnabledFeaturesEnum.EnableEdrRewardMessages)),
			switchMap((isRewardMessagesEnabled: boolean) =>
				!isRewardMessagesEnabled
					? of(EDRActions.fetchRewardMessagesSuccess({ rewardMessages: [] }))
					: this.edrService.fetchRewardMessages().pipe(
							map((response: RewardMessagesResponse) =>
								EDRActions.fetchRewardMessagesSuccess({ rewardMessages: response.rewardMessages })
							),
							catchError((error: any) => of(EDRActions.fetchRewardMessagesFailed(error)))
						)
			)
		)
	);

	public deleteRewardMessage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(EDRActions.deleteRewardMessage),
			switchMap(({ messageId }) =>
				this.featureService
					.isEnabled(ContextResponse.EnabledFeaturesEnum.EnableEdrRewardMessages)
					.pipe(map((isRewardMessagesEnabled: boolean) => ({ messageId, isRewardMessagesEnabled })))
			),
			switchMap(({ messageId, isRewardMessagesEnabled }) =>
				!isRewardMessagesEnabled
					? of(EDRActions.deleteRewardMessageSuccess())
					: this.edrService.deleteRewardMessage(messageId).pipe(
							map(() => EDRActions.deleteRewardMessageSuccess()),
							catchError((error: any) => of(EDRActions.deleteRewardMessageFailed(error)))
						)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private edrService: EDRService,
		private featureService: FeatureService
	) {}
}
