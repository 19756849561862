import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIf, NgTemplateOutlet } from '@angular/common';

/**
 * @description
 * @export
 */
@Component({
	selector: 'cdx-logo',
	template: `
		<ng-container *ngIf="link; then linkImage; else logoImage"></ng-container>
		<ng-template #linkImage>
			<a href="{{ link }}" title="{{ altText }}">
				<ng-container *ngTemplateOutlet="logoImage"></ng-container>
			</a>
		</ng-template>
		<ng-template #logoImage>
			<img [src]="logoUrl" alt="{{ altText }}" />
		</ng-template>
	`,
	styleUrls: ['./logo.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, NgTemplateOutlet]
})
export class LogoComponent {
	@Input() type: 'landscape' | 'portrait' | 'wapple' = 'landscape';
	@Input() background: 'dark' | 'light' = 'light';
	@Input() link: string;
	@Input() altText: string;
	@Input() logoSrcPrefix: string;

	get backgroundString(): string {
		return this.background === 'dark' && this.type !== 'wapple' ? '_dark' : '';
	}

	get logoUrl(): string {
		return `${this.logoSrcPrefix || '/'}logos/cd_logo_${this.type}${this.backgroundString}.svg`;
	}
}
