export const THEME_CONSTANTS = {
	breakpoints: {
		mobile: ' 640px',
		'tablet-portrait': ' 768px',
		tablet: '970px',
		desktop: '1200px',
		large: '1440px',
		xlarge: '1800px',
	},
	colors: {
		white: 'white',
		black: 'black',
		'bottle-green': '#0d3b22',
		parsley: '#125430',
		'fun-green': '#007837',
		'green-haze': ' #00ab4e',
		sushi: '#80c342',
		frost: '#edf4db',
		shark: '#1d2327',
		'outer-space': '#283137',
		'limed-spruce': '#39464e',
		'shuttle-gray': '#535e65',
		'regent-gray': '#949b9f',
		'silver-sand': '#b4b9bc',
		porcelain: '#e7e9ea',
		alabaster: '#f1f2f3',
		'school-bus-yellow': '#ffdd00',
		tango: ' #f47920',
		bridesmaid: '#feefe4',
		shiraz: '#c20e1a',
		'we-peep': '#f8e2e4',
		rose: '#e6007e',
		'havelock-blue': '#4a90e2',
		selago: '#e9f2fc',
	},
	transitions: {
		longest: '3000ms',
		longer: '1800ms',
		long: '1000ms',
		medium: '600ms',
		default: '300ms',
		short: '150ms',
	},
	easings: {
		linear: 'linear',
		default: 'ease',
		'ease-in': 'ease-in',
		'ease-in-out': 'ease-in-out',
		'ease-out': 'ease-out',
		'ease-out-back': 'cubic-bezier(0.34, 1.56, 0.64, 1)',
		'out-quart': 'cubic-bezier(0.165, 0.84, 0.44, 1)',
		'in-quart': 'cubic-bezier(0.895, 0.03, 0.685, 0.22)',
	},
};
