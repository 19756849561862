import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'cdx-button-group',
	template: `
		<ng-content></ng-content>
	`,
	styleUrls: ['./button-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class ButtonGroupComponent {

}
