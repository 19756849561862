import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, ActivationEnd } from '@angular/router';
import { CustomWindow } from '../services/window.service';

const pageParam = 'page';
const searchParam = 'search';

function checkRouteChildrenForData(route: ActivationEnd | ActivatedRoute, dataProperty: string): boolean {
	let routeSnapshot = route.snapshot;
	let propertyPresent = routeSnapshot.data[dataProperty];
	// Check all child route snapshots as we use a lot of nested routing modules
	// E.g. for the route '/shop/register' the route '' has a child '/shop' has a child '/shop/register'
	while (routeSnapshot.firstChild) {
		routeSnapshot = routeSnapshot.firstChild;
		if (routeSnapshot.data && routeSnapshot.data[dataProperty] !== undefined) {
			propertyPresent = routeSnapshot.data[dataProperty];
		}
	}

	return propertyPresent;
}

export function checkFullActivationEndForData(routerEvent: ActivationEnd, dataProperty: string): boolean {
	return checkRouteChildrenForData(routerEvent, dataProperty);
}

export function checkFullActivatedRouteForData(activatedRoute: ActivatedRoute, dataPropery: string): boolean {
	return checkRouteChildrenForData(activatedRoute, dataPropery);
}

export function cleanUrlForOrigin(window: CustomWindow, url: string): string {
	if (!url.startsWith(window.location?.origin)) {
		return `${window.location?.origin}${url}`;
	}
	return url;
}

export function isSearchPage(url: string): boolean {
	const match = url.match(/specials|search|browse/);

	return match ? true : false;
}

export function isNewPageOrSearchQuery(
	previousUrl: string,
	currentUrl: string,
	isScroll = false,
	baseUrl?: string
): boolean {
	if (!baseUrl && typeof location !== 'undefined') {
		baseUrl = location.origin;
	}
	const current = new URL(currentUrl, baseUrl);
	const previous = new URL(previousUrl, baseUrl);

	const currentPage = current.searchParams.has(pageParam) ? current.searchParams.get(pageParam) : '1';
	const previousPage = previous.searchParams.has(pageParam) ? previous.searchParams.get(pageParam) : '1';
	const searchParamChanged = current.searchParams.get(searchParam) !== previous.searchParams.get(searchParam);

	return current.pathname !== previous.pathname || (isScroll && currentPage !== previousPage) || searchParamChanged;
}

export function isTheFirstPage(url: string, baseUrl: string = location.origin): boolean {
	const current = new URL(url, baseUrl);

	const currentPage = current.searchParams.has(pageParam) ? current.searchParams.get(pageParam) : '1';

	return currentPage === '1';
}

export function isLinkAbsolute(href: string): boolean {
	return new RegExp('^(//|[a-z]+:)', 'i').test(href);
}

export function isLinkExternal(platformId: string | Object, href: string, location: Location): boolean {
	if (!isPlatformBrowser(platformId)) {
		return false;
	}
	try {
		// valid url, easy to check hostname
		const url = new URL(href);
		return (
			`${url.protocol}://${url.hostname}:${url.port}` !==
			`${location.protocol}://${location.hostname}:${location.port}`
		);
	} catch (e) {
		try {
			// If this works it's relative
			new URL(href, location.toString());
			return false;
		} catch (e2) {
			// Might as well check
			return !href.includes(location.hostname);
		}
	}
}
