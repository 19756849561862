import { Component, Input, HostBinding, ChangeDetectionStrategy } from '@angular/core';
import { Route, RouterLinkActive, RouterLink } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';

/**
 * @description
 * @export
 */
@Component({
	selector: 'cdx-nav',
	template: `
		<ng-container *ngIf="routes">
			<ul>
				<ng-container *ngFor="let route of routes">
					<li *ngIf="route.path">
						<a
							[routerLink]="[route.path]"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLinkActive="link--active"
							>{{ route.data?.title }}</a
						>
					</li>
				</ng-container>
			</ul>
		</ng-container>
	`,
	styleUrls: ['./nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, NgFor, RouterLinkActive, RouterLink]
})
export class NavComponent {
	@HostBinding('attr.role') role = 'navigation';

	@Input() routes: Route[];
}
