import { isPlatformServer } from '@angular/common';
import {
	AfterContentInit,
	Directive,
	ElementRef,
	HostListener,
	Inject,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	Renderer2,
	SimpleChanges,
} from '@angular/core';
import { ProductImagesResponse } from '@woolworthsnz/trader-api';
import { BreakPointService, CustomWindow, WINDOW } from '../services';
import { Subject, takeUntil } from 'rxjs';

@Directive({
	selector: '[cdxImageZoom], [ImageZoom]',
	standalone: true,
})
export class ImageZoomDirective implements OnInit, AfterContentInit, OnChanges, OnDestroy {
	@Input() images: ProductImagesResponse = {};

	canZoom = false;
	cx: number;
	cy: number;
	lens: HTMLElement;
	result: HTMLElement;
	document: Document;
	private destroyed$: Subject<boolean> = new Subject();

	constructor(
		private zone: NgZone,
		private el: ElementRef,
		private renderer: Renderer2,
		private breakpointService: BreakPointService,
		@Inject(WINDOW) private window: CustomWindow,
		@Inject(PLATFORM_ID) private platformId: Object
	) {
		this.document = window.document;
	}

	@HostListener('touchmove', ['$event'])
	@HostListener('mousemove', ['$event'])
	mouseOver($event: MouseEvent): void {
		if (!this.images) {
			return;
		}

		this.zone.runOutsideAngular(() => {
			requestAnimationFrame(() => {
				$event.preventDefault();

				this.moveLens($event);
			});
		});
	}

	@HostListener('mouseenter', ['$event'])
	mouseEnter(): void {
		if (!this.images || !this.canZoom) {
			return;
		}

		this.renderer.setStyle(this.result, 'display', `block`);
		this.renderer.setStyle(this.lens, 'display', `block`);
	}

	@HostListener('mouseleave', ['$event'])
	mouseOut(): void {
		if (!this.images || !this.canZoom) {
			return;
		}

		this.renderer.setStyle(this.result, 'display', `none`);
		this.renderer.setStyle(this.lens, 'display', `none`);
	}

	ngOnInit(): void {
		// No point doing a zoom if the image doesn't exist
		if (!this.images || isPlatformServer(this.platformId)) {
			return;
		}

		const img = this.el.nativeElement;

		this.lens = this.document?.createElement('div');
		this.lens.setAttribute('class', 'product-zoomLens');

		this.result = this.document?.createElement('div');
		this.result.setAttribute('class', 'product-zoom');

		this.renderer.appendChild(this.el.nativeElement, this.lens);
		this.renderer.appendChild(this.el.nativeElement, this.result);

		this.cx = this.result.offsetWidth / this.lens.offsetWidth;
		this.cy = this.result.offsetHeight / this.lens.offsetHeight;

		this.renderer.setStyle(this.result, 'background-image', `url('${this.images.big}')`);
		this.renderer.setStyle(
			this.result,
			'background-size',
			`${img.offsetWidth * this.cx}px ${img.offsetHeight * this.cy}px`
		);

		// We need the dimensions of these, so we grab those before hiding them
		this.renderer.setStyle(this.lens, 'display', `none`);
		this.renderer.setStyle(this.result, 'display', `none`);
	}

	ngAfterContentInit(): void {
		// Try to avoid the zoom showing when the page loads
		this.breakpointService.isSmallDevice$.pipe(takeUntil(this.destroyed$)).subscribe((isSmall) => {
			this.canZoom = !isSmall;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.images) {
			if (!this.images || !this.canZoom) {
				return;
			}
			this.renderer.setStyle(this.result, 'background-image', `url('${this.images?.big}')`);
		}
	}

	getCursorPosition(
		pageX: number,
		pageY: number
	): {
		x: number;
		y: number;
	} {
		const coords = this.el.nativeElement.getBoundingClientRect();

		let x = pageX - coords.left;
		let y = pageY - coords.top;

		x = x - this.window.pageXOffset;
		y = y - this.window.pageYOffset;

		return { x, y };
	}

	moveLens($event: MouseEvent): void {
		const pos = this.getCursorPosition($event.pageX, $event.pageY);
		const img = this.el.nativeElement;

		let x = pos.x - this.lens.offsetWidth / 2;
		let y = pos.y - this.lens.offsetHeight / 2;

		if (x > img.offsetWidth - this.lens.offsetWidth) {
			x = img.offsetWidth - this.lens.offsetWidth;
		}

		if (x < 0) {
			x = 0;
		}

		if (y > img.offsetHeight - this.lens.offsetHeight) {
			y = img.offsetHeight - this.lens.offsetHeight;
		}

		if (y < 0) {
			y = 0;
		}

		this.renderer.setStyle(this.lens, 'left', `${x}px`);
		this.renderer.setStyle(this.lens, 'top', `${y}px`);
		this.renderer.setStyle(this.result, 'background-position', `-${x * this.cx}px -${y * this.cy}px`);
	}
}
