import {
	BlockScrollStrategy,
	CloseScrollStrategy,
	ConnectedPosition,
	FlexibleConnectedPositionStrategy,
	GlobalPositionStrategy,
	NoopScrollStrategy,
	Overlay,
	OverlayConfig,
	OverlayRef,
	PositionStrategy,
	RepositionScrollStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { isPlatformServer } from '@angular/common';
import { ComponentRef, ElementRef, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { ContextResponse } from '@woolworthsnz/trader-api';
import { SVGIcon } from '../4_atoms/components/svg-definitions/SVGIcon';
import {
	GENERIC_MODAL_DATA,
	GenericModal,
	GenericModalComponent,
} from '../5_molecules/components/generic-modal/generic-modal.component';
import { NotificationType } from '../ui-models/datalayer';
import { DatalayerService } from './datalayer.service';
import { ModalOverlayRef } from './modal-overlayref';
import { ShopperService, ShopperState } from './shopper.service';
import { StatefulService } from './stateful.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { FeatureService } from './feature.service';

export enum ModalEvent {
	addShopperNote,
	addToTrolley,
	addToTrolleyError,
	deleteFromList,
	genericError,
	notAuthorised,
	productCategorisationSchemeOnboard,
	filterOnboard,
	reviewTrolley,
	selectFulfilmentSlot,
	setTraderSuburb,
	updatedFulfilmentTimeslot,
	updateFulfilmentLocation,
	saveRecipe,
	genericModal,
	startExpressFulfilmentShop,
}

export type ScrollStrategy = 'reposition' | 'noop' | 'block' | 'close';

export class ModalOverlayState {
	backdropClass?: 'overlay--transparent' | 'overlay--opaque' = 'overlay--opaque';
	element?: ElementRef<HTMLElement>;
	hasBackdrop = true;
	isConnectedElement = false;
	maxWidth?: number;
	panelClass = 'overlay-panel';
	scrollStrategy?: ScrollStrategy;
	templateRef: ComponentPortal<GenericModalComponent>;
	width: number | string = 'auto';
	eventType: ModalEvent = ModalEvent.genericError;
	modal: ModalOverlayRef;
	connectedPositions?: ConnectedPosition[];
	data?: unknown;
}

@Injectable({
	providedIn: 'root',
})
export class ModalOverlayService extends StatefulService<ModalOverlayState> {
	public componentRef: any;
	public shopper: ShopperState;

	public modalClosed$: Observable<void>;

	private modalClosedSubject = new BehaviorSubject<void>(undefined);

	private enableAtpSignInContent = false;

	constructor(
		private overlay: Overlay,
		private injector: Injector,
		private router: Router,
		private shopperService: ShopperService,
		private datalayerService: DatalayerService,
		private featureService: FeatureService,
		@Inject(PLATFORM_ID) private platformId: Object
	) {
		super(new ModalOverlayState());
		this.shopperService.state$.pipe(takeUntilDestroyed()).subscribe((s) => (this.shopper = s));
		this.modalClosed$ = this.modalClosedSubject.asObservable();
	}

	get isConnectedElement(): boolean {
		return this.state.isConnectedElement;
	}

	get element(): ElementRef<HTMLElement> | undefined {
		return this.state.element;
	}

	get eventType(): ModalEvent {
		return this.state.eventType;
	}

	get modal(): ModalOverlayRef {
		return this.state.modal;
	}

	get templateRef(): ComponentPortal<GenericModalComponent> {
		return this.state.templateRef;
	}

	get notAuthorisedButtonText(): string {
		return this.shopper.isLoggedIn ? 'Add details' : 'Sign in';
	}

	open(
		options: {
			eventType?: ModalEvent;
			templateRef?: any;
			errorText?: string;
			errorTitle?: string;
			icon?: SVGIcon;
			skipTracking?: boolean;
			trackingData?: { name?: string; value?: string };
		} = {}
	): ModalOverlayRef | undefined {
		if (this.modal) {
			this.modal.close();
		}

		// no modals when SSR
		if (isPlatformServer(this.platformId)) {
			return;
		}

		const overlayRef = this.createOverlay();
		const modalOverlayRef = new ModalOverlayRef(overlayRef);
		modalOverlayRef.backdropClick$ = overlayRef.backdropClick();

		let modalTrackingName = options.trackingData?.name || '';

		this.enableAtpSignInContent = this.featureService.isFeatureEnabled(
			ContextResponse.EnabledFeaturesEnum.EnableAtpSignInContent
		);

		if (this.isFailure(options.eventType)) {
			this.setState({
				eventType: options.eventType,
				templateRef: new ComponentPortal(
					GenericModalComponent,
					null,
					this.createInjector({
						description:
							options.errorText || `Please try refreshing your browser or coming back in a few minutes.`,
						title: options.errorTitle || "We're having technical issues at present",
						icon: options.icon,
						closeAction: () => this.close(),
					})
				),
			});
		} else if (
			!this.doesShopperNeedToBeLoggedInToPerformAction(options.eventType) ||
			(this.shopper.isShopper && options.eventType !== ModalEvent.notAuthorised)
		) {
			// There's an expectation here that if you're calling a modal
			// you will always pass a template ref, so we don't check
			// that it exists
			this.setState({
				eventType: options.eventType,
				templateRef: options.templateRef,
			});
		} else {
			// If new ATP sign in content feature is enabled and customer is not logged in
			// and the event type is addToTrolley or selectFulfilmentSlot
			// Load new ATP sign in content for modal dialog
			if (this.showAtpSignInContent(options.eventType)) {
				this.setState({
					eventType: ModalEvent.notAuthorised,
					templateRef: new ComponentPortal(
						GenericModalComponent,
						null,
						this.createInjector({
							title: 'Sign in to keep shopping',
							buttonText: 'Sign in or register',
							description: 'Sign in and select a time slot to see accurate prices and stock information.',
							ctaAction: this.redirectToAuthentication,
							icon: 'info',
							iconFill: 'gray',
							isButtonPrimary: true,
							isNewTextStyle: true,
							closeAction: () => this.close(),
							hasLinkButton: true,
							linkButtonText: 'Just browsing',
						})
					),
				});
			} else {
				// Not authorised to do whatever it is we're trying to do
				this.setState({
					eventType: ModalEvent.notAuthorised,
					templateRef: new ComponentPortal(
						GenericModalComponent,
						null,
						this.createInjector({
							title: this.getNotAuthorisedText(options.eventType),
							buttonText: this.notAuthorisedButtonText,
							ctaAction: this.redirectToAuthentication,
							iconFill: 'warning',
							closeAction: () => this.close(),
						})
					),
				});
			}
			modalTrackingName = 'Login';
		}

		if (!options.skipTracking) {
			this.datalayerService.trackNotificationEvent(
				NotificationType.Modal,
				modalTrackingName,
				options.trackingData?.value || ''
			);
		}

		this.componentRef = this.attachModalContainer(overlayRef);
		this.componentRef?.instance?.modalCTAEmitter?.pipe(take(1)).subscribe(() => {
			this.close();
		});

		overlayRef
			.backdropClick()
			.pipe(take(1))
			.subscribe(() => {
				// give other things a chance to react to the backdrop click before disposing of it
				setTimeout(() => this.close(), 0);
			});
		this.setState({ modal: modalOverlayRef });
		return modalOverlayRef;
	}

	openGenericModal(modalData: GenericModal): void {
		if (!modalData.closeAction) {
			modalData.closeAction = (): void => this.close();
		}

		this.open({
			eventType: ModalEvent.genericModal,
			templateRef: new ComponentPortal(
				GenericModalComponent,
				null,
				Injector.create({
					providers: [
						{
							provide: GENERIC_MODAL_DATA,
							useValue: modalData,
						},
					],
				})
			),
			skipTracking: modalData.skipTracking,
		});
	}

	attachModalContainer = (overlayRef: OverlayRef): ComponentRef<GenericModalComponent> =>
		overlayRef.attach(this.templateRef);

	close = (): void => {
		if (this.modal) {
			this.modal.close();
			this.modal.dispose();
			this.setState(new ModalOverlayState());
			this.modalClosedSubject.next();
		}
	};

	createOverlay = (): OverlayRef =>
		this.state && this.overlay.create(this.getOverlayConfig(this.state, this.getPositionStrategy()));

	createInjector(data: GenericModal): PortalInjector {
		if (!data.closeAction) {
			data.closeAction = (): void => this.close();
		}
		const injectorTokens = new WeakMap<any, any>([[GENERIC_MODAL_DATA, data]]);

		return new PortalInjector(this.injector, injectorTokens);
	}

	doesShopperNeedToBeLoggedInToPerformAction(eventType?: ModalEvent): boolean {
		// ModalEvent.addShopperNote = 0, so we have to explicitly check against undefined here and not just
		// if (!eventType) return false;
		if (eventType === undefined) {
			return false;
		}

		return [
			ModalEvent.addToTrolley,
			ModalEvent.addShopperNote,
			ModalEvent.deleteFromList,
			ModalEvent.selectFulfilmentSlot,
			ModalEvent.notAuthorised,
			ModalEvent.saveRecipe,
			ModalEvent.startExpressFulfilmentShop,
		].includes(eventType);
	}

	getNotAuthorisedText(eventType?: ModalEvent): string {
		const isLoggedInText = this.shopper.isLoggedIn ? 'We need a few more details to' : 'Sign in to';

		switch (eventType) {
			case ModalEvent.addToTrolley:
				return `${isLoggedInText} add items to your trolley.`;
			case ModalEvent.addShopperNote:
				return `${isLoggedInText} add or update shopper notes for this product.`;
			case ModalEvent.selectFulfilmentSlot:
				return `${isLoggedInText} select a pickup or delivery time.`;
			case ModalEvent.saveRecipe:
				return `${isLoggedInText} save a recipe.`;
			case ModalEvent.startExpressFulfilmentShop:
				return `${isLoggedInText} start shopping`;
			case ModalEvent.deleteFromList:
			default:
				return `${isLoggedInText} continue.`;
		}
	}

	getFailureText(eventType: ModalEvent): string {
		switch (eventType) {
			case ModalEvent.addToTrolleyError:
				return `Add to trolley error`;
			case ModalEvent.genericError:
			default:
				return `continue.`;
		}
	}

	getPositionStrategy(): FlexibleConnectedPositionStrategy | GlobalPositionStrategy {
		if (this.isConnectedElement && this.element) {
			return this.overlay
				.position()
				.flexibleConnectedTo(this.element)
				.setOrigin(this.element)
				.withFlexibleDimensions(false)
				.withPush(false)
				.withPositions(
					this.state.connectedPositions || [
						{
							originX: 'start',
							originY: 'bottom',
							overlayX: 'start',
							overlayY: 'top',
						},
					]
				)
				.withDefaultOffsetX(1);
		}

		return this.overlay.position().global().centerHorizontally().centerVertically();
	}

	getScrollStrategy(
		strategy?: ScrollStrategy
	): CloseScrollStrategy | RepositionScrollStrategy | NoopScrollStrategy | BlockScrollStrategy {
		switch (strategy) {
			case 'close': {
				return this.overlay.scrollStrategies.close();
			}
			case 'reposition': {
				return this.overlay.scrollStrategies.reposition();
			}
			case 'noop': {
				return this.overlay.scrollStrategies.noop();
			}
			case 'block':
			default: {
				return this.overlay.scrollStrategies.block();
			}
		}
	}

	getOverlayConfig(config: ModalOverlayState, positionStrategy: PositionStrategy): OverlayConfig {
		const { hasBackdrop, backdropClass, panelClass, width, maxWidth } = config;

		return new OverlayConfig({
			hasBackdrop,
			backdropClass,
			panelClass,
			positionStrategy,
			scrollStrategy: this.getScrollStrategy(config.scrollStrategy),
			width,
			maxWidth,
		});
	}

	isFailure(eventType: ModalEvent | undefined): boolean {
		if (!eventType) {
			return false;
		}

		return [ModalEvent.genericError, ModalEvent.addToTrolleyError].includes(eventType);
	}

	redirectToAuthentication = (): void => {
		const redirectUrl = this.shopper.isLoggedIn ? '/shop/register/shopperdetails' : '/shop/securelogin';

		this.router.navigateByUrl(redirectUrl);

		this.close();
	};

	showAtpSignInContent(eventType?: ModalEvent): boolean {
		return (
			this.enableAtpSignInContent &&
			!this.shopper.isLoggedIn &&
			(eventType === ModalEvent.addToTrolley || eventType === ModalEvent.selectFulfilmentSlot)
		);
	}
}
