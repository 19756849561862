<div class="loadMorePaginator" *ngIf="progress < 100">
	<label id="progressLabel" class="showingText"
		>Showing {{ currentlyShowing | number }} of {{ totalItems | number }}</label
	>
	<div
		role="progressbar"
		[attr.aria-valuenow]="progress"
		aria-valuemin="0"
		aria-valuemax="100"
		aria-labelledby="progressLabel"
		class="progressBar"
	>
		<div class="progressBar-fill" [style]="'width: ' + progress + '%'"></div>
	</div>
	<button
		cdxButton
		size="large"
		fillStyle="outline"
		[buttonState]="loading ? 'busy' : 'default'"
		(click)="handleLoadMoreClick()"
	>
		<ng-content></ng-content>
	</button>
</div>
