import { isPlatformServer, ViewportScroller } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, Scroll, NavigationEnd } from '@angular/router';
import { delay, filter, map, takeWhile } from 'rxjs/operators';
import { isNewPageOrSearchQuery, isSearchPage } from '../helpers/router.helper';
import { AppSettingsService } from './app-settings.service';
import { HistoryService } from './history.service';

@Injectable({
	providedIn: 'root',
})
/**
 * This is provided early in the app startup factory and attempts to solve some issues detailed in this issue: https://github.com/angular/angular/issues/24547.
 * It also specifically handles scrolling when navigating between modals until a fix is provided to this issue: https://github.com/angular/angular/issues/38382.
 * Router Scroller is not overrideable at the DI level because it is not exposed outside NG core - so this has to work in parallel with the service - source is here: https://github.com/angular/angular/blob/master/packages/router/src/router_scroller.ts
 */
export class RouterScrollService {
	constructor(
		private appSettingsService: AppSettingsService,
		private viewportScroller: ViewportScroller,
		private router: Router,
		private historyService: HistoryService,
		@Inject(PLATFORM_ID) private platformId: any
	) {
		if (isPlatformServer(this.platformId)) {
			return;
		}
		const modalOutletName = this.appSettingsService.getSetting('modalOutletName');

		// Borrowed from
		//   https://github.com/angular/angular/blob/02e8901d9eec916d1f59409435e53b7a34e7a33d/packages/router/src/router_scroller.ts
		this.viewportScroller.setHistoryScrollRestoration('manual');

		this.router.events
			.pipe(
				takeWhile((e) => !!e),
				filter((e) => e instanceof Scroll),
				map((e) => e as Scroll),
				delay(0)
			)
			.subscribe((e: Scroll) => {
				const previousUrl = this.historyService.getPreviousUrl();
				const navigationEndEvent = e.routerEvent as NavigationEnd;
				const currentUrl = navigationEndEvent.urlAfterRedirects;

				// Keep position if both page and path haven't changed
				if (isSearchPage(currentUrl) && !isNewPageOrSearchQuery(previousUrl, currentUrl, true)) {
					return;
				}
				// never scroll when going to or from a modal route
				// TODO should be able to use the actual routing events to figure out if an aux route is involved rather than regex url matching
				const modalRoute = new RegExp(`\\(.*${modalOutletName}:`);
				if (currentUrl.match(modalRoute) || previousUrl.match(modalRoute)) {
					return;
				}

				if (e.position) {
					// backward navigation
					this.viewportScroller.scrollToPosition(e.position);
				} else if (e.anchor) {
					// anchor navigation
					this.viewportScroller.scrollToAnchor(e.anchor);
				} else {
					// forward navigation
					this.viewportScroller.scrollToPosition([0, 0]);
				}
			});
	}
}
