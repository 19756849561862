import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ServerBrowserTransitionService {
	private _transitionToBrowser$: Subject<boolean> = new BehaviorSubject<boolean>(false);

	get transitionToBrowser$(): Observable<boolean> {
		return this._transitionToBrowser$.asObservable();
	}

	setTransitionedToBrowser(): void {
		this._transitionToBrowser$.next(true);
	}
}
