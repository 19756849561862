export const customerLoginCompleted = {
	tealium_event: 'customer_login',
	tealium_event_label: 'login completed',
	tealium_event_step: 2,
	interaction_source: 'page',
} as const;

export const customerLogout = {
	tealium_event: 'customer_logout',
	interaction_source: 'page',
} as const;
