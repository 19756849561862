import { Inject, Injectable, Optional } from '@angular/core';
import {
	ApiService,
	AppSettingsService,
	LoggingService,
	REQ_RECEIVE_TIME,
	StatefulService,
} from '@woolworthsnz/styleguide';
import {
	BannerResponse,
	DeliveryFee,
	NavigationItem,
	NavigationMenuItem,
	ShellResponse,
} from '@woolworthsnz/trader-api';
import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';

export class ShellState implements ShellResponse {
	[key: string]: any;
	specials?: NavigationItem[];
	dynamicHeaderLink?: NavigationItem;
	mainNavs?: NavigationMenuItem[];
	browseBanners?: BannerResponse[];
	specialsBanners?: BannerResponse[];
	recipesBanners?: BannerResponse[];
	deliveryFees?: Array<DeliveryFee>;
	footerLinks?: { [key: string]: NavigationItem };
	showBrowseNav? = false;
	showMobileNav? = false;
	showDesktopSubNav? = false;
	contentEditor? = false;
	initialised? = false;
}
export interface Categories {
	mainNavs: Array<NavigationMenuItem>;
	specials: Array<NavigationItem>;
}

@Injectable({
	providedIn: 'root',
})
export class ShellService extends StatefulService<ShellState> {
	constructor(
		private appSettingsService: AppSettingsService,
		private apiService: ApiService,
		private loggingService: LoggingService,
		@Optional() @Inject(REQ_RECEIVE_TIME) private originalTime: number = 0
	) {
		super(new ShellState());
	}

	get shellEndpoint(): string {
		return this.appSettingsService.getEndpoint('shell');
	}

	get hasInitialised(): boolean {
		return !!this.state.initialised;
	}

	getCheckoutLink(): string {
		const settingsFromApi = this.appSettingsService.getSetting('advancedSettings');

		if (!settingsFromApi) {
			return '/shop/checkout';
		}

		return settingsFromApi.showHaveYouForgotten ? '/shop/checkout' : '/checkout';
	}

	getApplicationShell = (): Observable<any> => {
		this.loggingService.log(`Shell Service calling shell api at ${Date.now() - this.originalTime}ms`);
		this.setState({ initialised: true });
		return this.apiService.get(this.shellEndpoint, {}).pipe(
			tap((r: ShellResponse) => {
				const {
					specials,
					dynamicHeaderLink,
					mainNavs,
					browseBanners,
					specialsBanners,
					recipesBanners,
					footerLinks,
					contentEditor,
					deliveryFees,
				} = r;
				this.setState({
					specials,
					dynamicHeaderLink,
					mainNavs,
					browseBanners,
					specialsBanners,
					recipesBanners,
					footerLinks,
					contentEditor,
					deliveryFees,
				});
			}),
			tap((r: ShellResponse) => {
				const {
					fulfilmentMessages,
					fulfilmentTimeouts,
					disabledMessageTitleNames,
					productSearchSettings,
					deliverySubscriptionMessages,
					deliverySubscriptionSettings,
					expressFulfilmentSettings,
					expressFulfilmentMessages,
				} = r;

				this.appSettingsService.setState({
					// TODO: Reorganise this, could get confusing
					messages: fulfilmentMessages,
					timeouts: fulfilmentTimeouts,
					disabledMessageTitleNames,
					productSearchSettings,
					deliverySubscriptionMessages,
					deliverySubscriptionSettings,
					expressFulfilmentSettings,
					expressFulfilmentMessages,
				});

				this.loggingService.log(`Shell service received shell response at ${Date.now() - this.originalTime}ms`);
			})
		);
	};

	toggleMobileNav(action?: 'show' | 'hide'): void {
		this.setState({
			showMobileNav: action === 'show',
		});
	}

	toggleBrowseNav(action?: 'show' | 'hide'): void {
		this.setState({
			showBrowseNav: action === 'show',
		});
	}

	setMainNavsAndSpecialCategories = ({ mainNavs, specials }: Categories): void => {
		this.setState({
			mainNavs: mainNavs || this.state.mainNavs,
			specials: specials || this.state.specials,
		});
	};
}
