import { Injectable } from '@angular/core';
import { ShopperService } from './shopper.service';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ExperimentService {
	experimentState: BehaviorSubject<any> = new BehaviorSubject({
		optedIn: null,
		disabled: null,
	});

	experimentState$ = this.experimentState.asObservable();

	constructor(private shopperService: ShopperService) { }

	getBetaTestingStatus(): void {
		this.shopperService
			.getExperiments()
			.pipe(take(1))
			.subscribe((response: any) =>
				this.experimentState.next({
					optedIn: response.isInBetaTestingGroup,
					disabled: !response.isAbleToParticipateInExperiments,
				})
			);
	}

	optInToExperiment(betaTestStatus: boolean): void {
		this.shopperService
			.optInToExperiment(betaTestStatus)
			.pipe(
				take(1),
				catchError((error) => throwError(error))
			)
			.subscribe((response: any) => this.experimentState.next({ optedIn: response.isInBetaTestingGroup }));
	}

	optOutOfExperiments(): void {
		this.shopperService
			.optOutOfExperiments()
			.pipe(
				take(1),
				catchError((error) => throwError(error))
			)
			.subscribe((_: any) =>
				this.experimentState.next({
					disabled: true,
				})
			);
	}
}
