import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { FeatureListItem, FeatureListItemComponent } from '../feature-list-item/feature-list-item.component';
import { NgFor } from '@angular/common';

@Component({
	selector: 'cdx-feature-list',
	template: `
		<ng-container *ngFor="let item of items; let index">
			<cdx-feature-list-item [item]="item" [index]="index"></cdx-feature-list-item>
		</ng-container>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./feature-list.component.scss'],
	standalone: true,
	imports: [NgFor, FeatureListItemComponent]
})
export class FeatureListComponent implements OnInit {
	@Input() items: FeatureListItem[];

	ngOnInit(): void {
		if (!this.items) {
			// eslint-disable-next-line no-console
			console.warn('[FeatureListComponent]: No items passed. Will not render.');
		}
	}
}
