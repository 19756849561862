import { FocusableOption, FocusOrigin } from '@angular/cdk/a11y';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnDestroy,
	Optional,
	Output,
	ViewChild,
} from '@angular/core';
import { DatalayerService, TrackingEvent } from '../../../services/datalayer.service';
import { ButtonState, NotificationType } from '../../../ui-models';
import { SuccessIconComponent } from '../success-icon/success-icon.component';
import { LoadingComponent } from '../loading/loading.component';
import { NgIf, NgClass } from '@angular/common';

const BUSY_LOADER_HIDE_TIME = 300;

/* eslint-disable @typescript-eslint/naming-convention */
// TODO: Migrate to using these combos
export enum ButtonFillColour {
	Primary,
	PrimaryInverse,
	Secondary,
	SecondaryInverse,
	Disabled,
	Warn,
}

export enum ButtonFillStyle {
	Fill,
	Flat,
	Outline,
}
/* eslint-enable @typescript-eslint/naming-convention */

@Component({
	selector: 'cdx-button, [cdxButton]',
	template: `
		<i class="button-icon--start" #slotStart> <ng-content select="[start]"></ng-content> </i>

		<ng-container *ngIf="!isCompleted">
			<ng-content></ng-content>
		</ng-container>

		<div *ngIf="slotCounterActive" class="counter">
			<ng-content select="[counter]"></ng-content>
		</div>

		<ng-container *ngIf="isCompleted">
			<ng-content select="[completed]"></ng-content>
		</ng-container>

		<i class="button-icon--end" #slotEnd>
			<cdx-loading [fill]="loadingFillStyle" *ngIf="isBusy"></cdx-loading>

			<cdx-success-icon *ngIf="isCompleted" (animationCompleteEmitter)="animationCompleted()"></cdx-success-icon>

			<ng-content select="[end]"></ng-content>
		</i>
	`,
	styleUrls: ['./button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, LoadingComponent, SuccessIconComponent, NgClass],
})
export class ButtonComponent implements OnDestroy, FocusableOption {
	@Input() class: string;
	@Input() trackingName?: string;

	@Input()
	@HostBinding('attr.checked')
	checked: boolean | null = null;

	@Input()
	@HostBinding('attr.aria-disabled')
	disabled = false;

	@HostBinding('attr.align')
	@Input()
	align: 'left' | 'center' | 'right' = 'center';

	@HostBinding('attr.overrideFill')
	@Input()
	overrideIconButtonFill: boolean | null = null;

	@HostBinding('attr.fillStyle')
	@Input()
	fillStyle:
		| 'primary'
		| 'primaryInverse'
		| 'primaryFlatInverse'
		| 'outline'
		| 'link'
		| 'flat'
		| 'warn'
		| 'warnOutline'
		| 'secondary'
		| 'secondaryFlat'
		| 'secondaryOutline'
		| 'tertiary'
		| 'tertiaryFlat' = 'primary';

	@HostBinding('attr.chip')
	@Input()
	chip: boolean;

	@HostBinding('attr.rounded')
	@Input()
	rounded: boolean;

	@HostBinding('attr.size')
	@Input()
	size: 'default' | 'small' | 'large' = 'default';

	// @HostBinding('attr.marginSize')
	// @Input()
	// marginSize: 'default' | 'small' = 'default';

	@HostBinding('attr.inline')
	@Input()
	inline = false;

	@HostBinding('attr.data-orientation')
	@Input()
	orientation: 'vertical' | 'horizontal' = 'horizontal';

	@HostBinding('attr.fullWidth')
	@Input()
	fullWidth = false;

	@Input()
	@HostBinding('class.btn--footer')
	footerButton = false;

	@HostBinding('attr.data-slotendsize')
	@Input()
	endSlotSize: 'extra-small' | 'small' | 'default' = 'default';

	@HostBinding('attr.data-slotstartsize')
	@Input()
	startSlotSize: 'extra-small' | 'small' | 'default' = 'default';

	@HostBinding('attr.focus-visible-style')
	@Input()
	focusVisibleStyle: 'dark-dashed' | null = null;

	@Input()
	slotCounterActive = false;

	// TODO: We should automate the adding of an icon into the button if we want i
	// We would then be able to dictate the colour of it and hover states
	@ViewChild('slotStart', { read: ElementRef, static: true })
	slotStart: ElementRef;
	@ViewChild('slotEnd', { read: ElementRef, static: true })
	slotEnd: ElementRef;

	@Output() animationCompletedEmitter: EventEmitter<any> = new EventEmitter();

	startTimer = 0;
	state = ButtonState.default;
	trackingEvent = TrackingEvent;
	notificationType = NotificationType;
	_endSlotActive: boolean;
	_startSlotActive: boolean;

	constructor(
		public el: ElementRef,
		private cdr: ChangeDetectorRef,
		@Optional() private dataLayerService: DatalayerService
	) {}

	@HostBinding('style.pointer-events')
	get events(): 'none' | 'auto' {
		return this.state !== ButtonState.default ? 'none' : 'auto';
	}

	@HostBinding('attr.aria-busy')
	get isBusy(): boolean {
		return this.state === ButtonState.busy;
	}

	@HostBinding('attr.data-completed')
	get isCompleted(): boolean {
		return this.state === ButtonState.completed;
	}

	get endSlotActive(): boolean {
		return this._endSlotActive;
	}

	get startSlotActive(): boolean {
		return this._startSlotActive;
	}

	get loadingFillStyle(): 'green' | 'dark' | 'light' {
		switch (this.fillStyle) {
			case 'outline':
			case 'flat':
			case 'link':
				return 'green';
			case 'secondaryFlat':
			case 'secondaryOutline':
			case 'tertiaryFlat':
				return 'dark';
			default:
				return 'light';
		}
	}

	// ADD SVG SIZE
	@Input()
	@HostBinding('attr.data-slotend')
	set endSlotActive(a: boolean) {
		this._endSlotActive = a;
	}

	@Input()
	@HostBinding('attr.data-slotstart')
	set startSlotActive(a: boolean) {
		this._startSlotActive = a;
	}

	@Input()
	set buttonState(buttonState: ButtonState) {
		switch (buttonState) {
			case ButtonState.busy: {
				this.setBusyState();
				break;
			}

			case ButtonState.completed: {
				this.setCompleteState();

				if (this.trackingName && this.dataLayerService) {
					this.dataLayerService.trackEvent(
						this.trackingEvent.NotificationEvent,
						this.notificationType.Button,
						this.trackingName,
						'success'
					);
				}
				break;
			}

			case ButtonState.errored:
				this.setDefaultState();

				if (this.trackingName && this.dataLayerService) {
					this.dataLayerService.trackEvent(
						this.trackingEvent.NotificationEvent,
						this.notificationType.Button,
						this.trackingName,
						'failure'
					);
				}

				break;
			case ButtonState.default:
			default: {
				this.setDefaultState();
				break;
			}
		}
	}

	ngOnDestroy(): void {
		return;
	}

	animationCompleted(): void {
		this.animationCompletedEmitter.emit();
	}

	// Part of the FocusableOption interface
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	focus(origin?: FocusOrigin): void {
		this.el.nativeElement.focus();
	}

	setCompleteState(): void {
		this._endSlotActive = true;
		const timeDifferenceFromStart = Date.now() - this.startTimer;
		if (timeDifferenceFromStart >= BUSY_LOADER_HIDE_TIME) {
			this.setState(ButtonState.completed);
		} else {
			const remainingTime = BUSY_LOADER_HIDE_TIME - timeDifferenceFromStart;

			setTimeout(() => {
				this.setState(ButtonState.completed);
			}, remainingTime);
		}
	}

	setBusyState(): void {
		this._endSlotActive = true;
		this.startTimer = Date.now();
		this.setState(ButtonState.busy);
	}

	setDefaultState(): void {
		this._endSlotActive = false;
		this.setState(ButtonState.default);
	}

	setState(state: ButtonState): void {
		this.state = state;
		this.cdr.markForCheck();
	}
}
