import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconFill, SVGIcon } from '../../../../';
import { OptionListBaseComponent } from '../option-list-base/option-list-base.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { OptionListItemComponent } from '../option-list-item/option-list-item.component';
import { NgFor, NgIf } from '@angular/common';

export interface MultiSuggestion {
	id: any;
	title?: string;
	suggestions: any[];
	displayKey: string;
	icon?: SVGIcon;
	iconFill?: IconFill;
}

@Component({
	selector: 'cdx-option-list-multi',
	template: `
		<section *ngFor="let suggestionSection of listItems">
			<h5 *ngIf="suggestionSection.title" class="multiSuggestion-title">{{ suggestionSection.title }}</h5>
			<ul role="list">
				<cdx-option-list-item
					*ngFor="let item of suggestionSection.suggestions; let index = index"
					[itemLabel]="item[suggestionSection.displayKey]"
					class="list-group-item"
					(click)="onMultiSuggestionClick(suggestionSection.id, item)"
				>
					<cdx-svg-icon
						*ngIf="suggestionSection.icon"
						[name]="suggestionSection.icon"
						[fill]="suggestionSection.iconFill || 'currentColor'"
						size="extra-small"
					></cdx-svg-icon>
					{{ item[suggestionSection.displayKey] }}
				</cdx-option-list-item>
			</ul>
		</section>
	`,
	styleUrls: ['./option-list-multi.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgFor, NgIf, OptionListItemComponent, SvgIconComponent]
})
export class OptionListMultiComponent extends OptionListBaseComponent {
	@Input() set listItems(value: MultiSuggestion[]) {
		this.sections = value;
		let count = 0;
		this.itemMap = new Map<number, any>();
		value?.forEach((section) => {
			section.suggestions.forEach((suggestion) => {
				this.itemMap.set(count, {
					section: section.id,
					option: suggestion,
				});
				count++;
			});
		});
	}

	get listItems(): MultiSuggestion[] {
		return this.sections;
	}

	private sections: MultiSuggestion[];
	private itemMap = new Map<number, any>();

	getItem(index: number): any {
		return this.itemMap.get(index);
	}

	onMultiSuggestionClick(id: any, option: any): void {
		this.optionSelect.emit({ section: id, option });
	}
}
