import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IconFill, SVGIcon } from '../../../4_atoms/components/';
import { SvgIconComponent } from '../../../4_atoms/components/svg-icon/svg-icon.component';
import { NgIf } from '@angular/common';

@Component({
	selector: 'cdx-hint',
	template: `
		<p>
			<cdx-svg-icon *ngIf="icon" [name]="icon" [fill]="iconFill" size="small"></cdx-svg-icon>
			<ng-content></ng-content>
		</p>
	`,
	styleUrls: ['./hint.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, SvgIconComponent]
})
export class HintComponent {
	@HostBinding('attr.constrain-width')
	@Input()
	constrainWidth = false;
	@Input() icon: SVGIcon;
	@Input() iconFill: IconFill;
	@HostBinding('attr.hint-icon-right-aligned')
	@Input()
	hintIconRightAligned: false;
	@HostBinding('attr.icon-with-pointer-cursor')
	@Input()
	iconWithPointerCursor: false;
}
