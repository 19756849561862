import { isPlatformBrowser, NgIf, NgFor, NgClass } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	PLATFORM_ID,
	Self,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { parseStringToIntOrDefault } from '../../../helpers';
import { DatalayerService, TrackingEvent } from '../../../services';
import { NotificationType } from '../../../ui-models';
import { Carousel2Service } from './carousel2.service';
import { SvgIconComponent } from '../../../4_atoms/components/svg-icon/svg-icon.component';

export enum ContainerDisplayType {
	single = 'single',
	dynamic = 'itemssize',
	fixed = 'fixedsize',
}

export enum ContainerDisplayAlign {
	left = 'left',
	center = 'center',
	right = 'right',
}

/**
 * Wrap your carousel items in the cdx-carousel2-item component
 */
@Component({
	selector: 'cdx-carousel2',
	templateUrl: './carousel2.component.html',
	styleUrls: ['./carousel2.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [Carousel2Service],
	standalone: true,
	imports: [NgIf, SvgIconComponent, NgFor, NgClass],
})
export class Carousel2Component implements OnChanges, AfterViewInit, OnDestroy {
	@Input() itemCount: number; // Number of carousel items
	@Input() name = 'Carousel 2';
	@Input() enableArrowNavigation = true;

	@Input() set autoRotateInterval(value: number | string) {
		if (typeof value === 'string') {
			this._autoRotateInterval = parseStringToIntOrDefault(value, 0);
		} else {
			this._autoRotateInterval = value;
		}
	}
	private destroyed$: Subject<boolean> = new Subject();

	@HostBinding('attr.data-display-type')
	@Input()
	displayType: ContainerDisplayType = ContainerDisplayType.dynamic;

	@Input() columns = 4; // Original NumberInDisplay setting
	@Input() align: ContainerDisplayAlign; // This only applies to displayType=fixedsize

	@HostBinding('attr.data-align') displayAlign: ContainerDisplayAlign | null = null; // The current value of Align in using

	@ViewChild('list', { read: ElementRef }) list: ElementRef;

	@Output() itemsVisible = new EventEmitter<{ from: number; to: number }>();
	@Output() autoRotationCancelled = new EventEmitter<void>();

	// Animation scrolling
	intervalId?: number;
	// Pagination
	currentPage = 0;
	pages: any[] = new Array(4);

	// The current value of NumberInDisplay in using
	private displayColumns = 4;
	private _autoRotateInterval = 0;

	constructor(
		private cdr: ChangeDetectorRef,
		@Inject(PLATFORM_ID) private platformId: object,
		private datalayerService: DatalayerService,
		@Self() private carousel2Service: Carousel2Service
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.displayType === ContainerDisplayType.dynamic && 'columns' in changes) {
			this.updateCarouselItemWidth(changes.columns.currentValue);
		}
		this.updateScrollingData();
		this.emitVisible();
		this.cdr.markForCheck();
	}

	ngAfterViewInit(): void {
		// Debounce native scrolling and update the selected nav dot once they settle
		fromEvent(this.list.nativeElement, 'touchend')
			.pipe(takeUntil(this.destroyed$), debounceTime(500))
			.subscribe(() => {
				this.updateNavigationLocation();
				this.cdr.markForCheck();
			});
	}

	private updateCarouselItemWidth(columns: number): void {
		this.carousel2Service.$columns.next(columns);
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
		this.cancelAutoRotation();
	}

	paginationClick(i: number): void {
		this.cancelAutoRotation();
		this.scrollToPage(i);

		// Track click event
		this.datalayerService.trackEvent(
			TrackingEvent.NotificationEvent,
			NotificationType.Button,
			`Carousel Nav Button - ${this.name}`,
			i + 1
		);
	}

	private updateScrollingData(): void {
		if (!this.itemCount) {
			return;
		}
		const length = this.itemCount;
		const columns = this.columns || 1;

		switch (this.displayType) {
			case ContainerDisplayType.single:
				this.displayColumns = 1;
				this.displayAlign = null;
				this.pages = new Array(this.itemCount);
				break;
			case ContainerDisplayType.dynamic:
				this.displayColumns = length >= columns ? columns : length;
				this.displayAlign = null;
				this.pages = length > columns ? new Array(Math.ceil(length / columns)) : [];
				break;
			case ContainerDisplayType.fixed:
				this.displayColumns = columns;
				this.displayAlign = length >= columns ? null : this.align;
				this.pages = length > columns ? new Array(Math.ceil(length / columns)) : [];
				break;
		}

		this.setupAutorotation();
	}

	private scrollToPage(i: number): void {
		const scrollWidth = this.list.nativeElement.scrollWidth;
		const scrollPerElement = scrollWidth / this.itemCount;
		const scrollPerPage = scrollPerElement * this.displayColumns;

		this.currentPage = i;

		this.list.nativeElement.scrollTo({
			left: Math.floor(scrollPerPage * i) + 16,
			behavior: 'smooth',
		});

		this.cdr.markForCheck();
	}

	private updateNavigationLocation(): void {
		if (!this.list?.nativeElement) {
			return;
		}

		const scrollWidth = this.list.nativeElement.scrollWidth;
		const scrollLeft = this.list.nativeElement.scrollLeft;
		const scrollPerElement = scrollWidth / this.itemCount;
		const scrollPerPage = scrollPerElement * this.displayColumns;

		// There is a scroll-padding applied, so we need to account for it when scrolled all the way to the left
		this.currentPage = scrollLeft < scrollPerElement / 2 ? 0 : Math.ceil(scrollLeft / scrollPerPage);
	}

	private setupAutorotation(): void {
		if (
			isPlatformBrowser(this.platformId) &&
			this.itemCount > 1 &&
			this._autoRotateInterval > 1 &&
			this.pages.length > 1 &&
			!this.intervalId
		) {
			// @ts-ignore typings think this is a NodeJS.Timeout
			this.intervalId = setInterval(() => {
				let nextPage = this.currentPage + 1;
				nextPage = nextPage % this.pages.length;
				this.scrollToPage(nextPage);
				this.emitVisible();
			}, this._autoRotateInterval * 1000);
		}
	}

	private cancelAutoRotation(): void {
		if (this.intervalId) {
			this.autoRotationCancelled.emit();
			clearInterval(this.intervalId);

			this.autoRotateInterval = 0;
			this.intervalId = undefined;
			this.carousel2Service.isAutoRotating = false;
		}
	}

	private emitVisible(): void {
		const visibleItems = {
			from: this.currentPage * this.displayColumns,
			to: Math.min(this.currentPage * this.displayColumns + this.displayColumns, this.itemCount),
		};
		this.itemsVisible.emit(visibleItems);
	}
}
