import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppSettingsService, ShopperService } from '@woolworthsnz/styleguide';
import {
	AddShopperInstructionsRequest,
	BonusProductModel,
	BonusProductSelectedRequest,
} from '@woolworthsnz/trader-api';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OrderService } from '../services';
import { OrderNoteAddendums, OrderNoteAddendumsService } from '../services/order-note-addendums.service';

export enum NoteType {
	fulfilmentInstructions = 'fulfilmentInstructions',
	shopperInstructions = 'shopperInstructions',
}

// TODO - POD-6919 remove when a better back-end solution has been implemented
/**
 * This entire interceptor is here to hack bonus product Yes/No selector into a utility for manipulating the order delivery notes.
 * As it stands there is only one special case - covid-19 customer self isolating. When that fake bonus product is added to the bonus products the customer can select this interceptor keeps track of whether they select yes or no and modifies the delivery notes so that delivery drivers are aware ofthis state. It does this behind the scenes by adding the relevant text when the delivery note is posted and stripping it from the order response so it's invisible to the customer.
 */
@Injectable()
export class OrderDeliveryNotesInterceptor implements HttpInterceptor {
	constructor(
		private orderNoteAddendumService: OrderNoteAddendumsService,
		private orderService: OrderService,
		private shopperService: ShopperService,
		private appSettingsService: AppSettingsService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// intercept addNote request if we need to add/remove an addendum
		if (request.url.endsWith(this.appSettingsService.getEndpoint('fulfilmentNotes'))) {
			const notes: OrderNoteAddendums = this.orderNoteAddendumService.state;
			const instructions: string = request.body?.instructions || '';
			const notesToPrepend = Object.values(notes).filter(
				(note) => note.selected && !instructions?.includes(note.value)
			);
			notesToPrepend.forEach((note) => {
				request = request.clone({
					body: {
						instructions: `${note.value}\\n${instructions}`,
					},
				});
			});
		}

		// intercept getOrder response and check for addendums
		return next.handle(request).pipe(
			filter((event) => event instanceof HttpResponse),
			map((event) => event as HttpResponse<any>),
			map((event: HttpResponse<any>) => {
				// intercept getOrder response and modify order of bonus products (so the special ones go at the top) and modify fulfilment note so when it gets back to the UI it doesn't have the special notes in it
				if (request.url.endsWith(this.appSettingsService.getEndpoint('getOrder'))) {
					const notes: OrderNoteAddendums = this.orderNoteAddendumService.state;

					// re-order products so that overrides are first
					let bonusProducts = event?.body?.order?.bonusProducts;
					if (bonusProducts) {
						bonusProducts.sort((a: BonusProductModel, b: BonusProductModel) => {
							if (Object.keys(notes).find((noteName) => noteName === a.name)) {
								return -1;
							} else if (Object.keys(notes).find((noteName) => noteName === b.name)) {
								return 1;
							}
							return 0;
						});
					}

					event = event.clone({
						...event.body,
						order: {
							...event.body?.order,
							bonusProducts,
						},
					});

					// clean up note to just be users entered note
					let orderNotes = event?.body?.order?.[NoteType.fulfilmentInstructions];
					if (orderNotes) {
						orderNotes = this.updateUsersFulfilmentNotes(orderNotes);

						// set the bonus product selected status to match any previous order note addendums
						bonusProducts = bonusProducts.map((product: any) => {
							product.bonusProductSelected =
								this.orderNoteAddendumService.state[product.name]?.selected ?? product.bonusProductSelected;
							return product;
						});

						return event.clone({
							body: {
								...event.body,
								order: {
									...event.body?.order,
									[NoteType.fulfilmentInstructions]: orderNotes,
									bonusProducts,
								},
							},
						});
					}
				}

				// if the response is a 204 coming back from the fulfilment notes service update our state to hold the new note (since we no longer get the entire order again)
				if (request.url.endsWith(this.appSettingsService.getEndpoint('fulfilmentNotes')) && event.status === 200) {
					const orderNotes: string = request.body?.instructions || '';
					this.updateUsersFulfilmentNotes(orderNotes);
					return event;
				}

				// intercept response to bonus product updates and update our addendum state to match
				if (
					request.url.endsWith(this.appSettingsService.getEndpoint('bonusProducts')) &&
					request.method === 'PUT' &&
					event.status === 200 &&
					this.orderService.state.order
				) {
					const bonusProductUpdate: BonusProductSelectedRequest = request.body;
					const bonusProductName = this.orderService.state.order.bonusProducts.find(
						(product: BonusProductModel) => product.id === bonusProductUpdate.id
					)?.name;

					if (bonusProductName && this.orderNoteAddendumService.state[bonusProductName]) {
						this.orderNoteAddendumService.setState({
							[bonusProductName]: {
								...this.orderNoteAddendumService.state[bonusProductName],
								selected: bonusProductUpdate.selected || false,
							},
						});

						// fire a post of the delivery notes if they have changed
						const noteRequest: AddShopperInstructionsRequest = {};
						noteRequest.instructions = this.orderNoteAddendumService.orderNotes;
						this.shopperService.addOrUpdateFulfilmentNotes(noteRequest).subscribe();
					}
				}

				return event;
			})
		);
	}

	private updateUsersFulfilmentNotes(orderNotes: string): string {
		const notes: OrderNoteAddendums = this.orderNoteAddendumService.state;
		// iterate ordernotes addendums, if any exist on the note response remove them but update state in service to reflect

		Object.entries(notes).forEach(([name, note]) => {
			if (orderNotes.includes(note.value)) {
				orderNotes = orderNotes.replace(note.value, '');
				this.orderNoteAddendumService.setState({
					...this.orderNoteAddendumService.state,
					[name]: {
						value: note.value,
						selected: true,
					},
				});
			}
		});
		orderNotes = orderNotes.replace(/\\n/, '\n').trim();
		// update the note in our state
		this.orderNoteAddendumService.orderNotes = orderNotes;
		return orderNotes;
	}
}
