import { Injectable } from '@angular/core';
import { StatefulService } from '@woolworthsnz/styleguide';

export interface OrderNoteAddendums {
	[name: string]: {
		value: string;
		selected: boolean;
	};
}

// TODO - POD-6919 remove when a better solution has been implemented
@Injectable({
	providedIn: 'root',
})
export class OrderNoteAddendumsService extends StatefulService<OrderNoteAddendums> {
	private _orderNotes: string;

	constructor() {
		// Addendum name should match BonusProduct name from site manage
		// value is the message that should appear in delivery notes
		super({
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'F2037 Doorstep Delivery Option': {
				value: 'CUSTOMER SELF ISOLATING',
				selected: false,
			},
		});
	}

	get orderNotes(): string {
		return this._orderNotes;
	}

	set orderNotes(notes: string) {
		this._orderNotes = notes;
	}
}
