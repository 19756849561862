import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LastOrderFulfilment } from '../../../ui-models/LastOrderFulfilment';
import { ButtonComponent } from '../../../4_atoms/components/button/button.component';
import { NgIf } from '@angular/common';
import { SvgIconComponent } from '../../../4_atoms/components/svg-icon/svg-icon.component';
import { MaybeExternalLinkDirective } from '../../../directives/maybe-external-link/maybe-external-link.directive';

@Component({
	selector: 'cdx-edit-order-notification',
	templateUrl: './edit-order-notification.component.html',
	styleUrls: ['./edit-order-notification.component.scss'],
	animations: [
		trigger('slideInOut', [
			transition(':enter', [
				style({ transform: 'translateX(-100%)' }),
				animate('500ms cubic-bezier(1,-0.69,.1,1.53)', style({ transform: 'translateX(0%)' })),
			]),
			transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))]),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MaybeExternalLinkDirective, SvgIconComponent, NgIf, ButtonComponent],
})
export class EditOrderNotificationComponent {
	@Output() editOrder = new EventEmitter<void>();
	@Input() lastOrderFulfilment: LastOrderFulfilment;
	@Input() enableAnimation = true;
	@Input() editOrderUrl: string;

	public handleEditOrderClick(): void {
		this.editOrder.emit();
	}
}
