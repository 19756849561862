import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, InjectionToken, Optional, PLATFORM_ID } from '@angular/core';
import { ContextResponse } from '@woolworthsnz/trader-api';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StatefulService } from './stateful.service';
import { CustomWindow, WINDOW } from './window.service';
import EnabledFeaturesEnum = ContextResponse.EnabledFeaturesEnum;
// TODO POD-8213 Remove all references to 'Shell' feature flag
export class FeatureState {
	updatedFromServer? = false;
	enabledFeatures: (ContextResponse.EnabledFeaturesEnum | 'Shell')[];
}

export const BOOTSTRAP_FEATURES = new InjectionToken('bootstrap_features');

@Injectable({
	providedIn: 'root',
})
export class FeatureService extends StatefulService<FeatureState> {
	constructor(
		@Inject(WINDOW) private window: CustomWindow,
		@Inject(PLATFORM_ID) private platformId: Object,
		@Optional() @Inject(BOOTSTRAP_FEATURES) private bootstrapFeatures: any
	) {
		super(<FeatureState>{});

		this.setState(this.getEnabledFeaturesFromWindowBootstrap());
	}

	get enabledFeatures(): (ContextResponse.EnabledFeaturesEnum | 'Shell')[] {
		return this.state.enabledFeatures || [];
	}

	getEnabledFeaturesFromWindowBootstrap(): FeatureState | { enabledFeatures: any } {
		const window = this.window;
		if (isPlatformServer(this.platformId)) {
			this.window.BOOTSTRAP_DATA = {
				enabledFeatures: this.bootstrapFeatures,
			};
			return {
				enabledFeatures: this.bootstrapFeatures,
			};
		}
		if (window && window.BOOTSTRAP_DATA && window.BOOTSTRAP_DATA.enabledFeatures) {
			return {
				enabledFeatures: window.BOOTSTRAP_DATA.enabledFeatures || [],
			};
		}

		return new FeatureState();
	}

	// TODO POD-8213 Remove all references to 'Shell' feature flag
	isFeatureEnabled = (feature: EnabledFeaturesEnum | 'Shell'): boolean => this.enabledFeatures.includes(feature);
	areFeaturesEnabled = (...features: EnabledFeaturesEnum[]): boolean =>
		features.every((feature) => this.enabledFeatures.includes(feature));

	setState(newState: FeatureState): void {
		const isUpdatedFromServer = newState?.updatedFromServer || false;
		const fixedState = this.window?.BOOTSTRAP_DATA?.enabledFeatures || [];

		// The use of a Set here allows the prevention of duplicate features being added
		const newStateAndFixedState = new Set([...fixedState, ...(newState?.enabledFeatures || [])]);

		super.setState({
			updatedFromServer: isUpdatedFromServer,
			enabledFeatures: Array.from(newStateAndFixedState),
		});
	}

	public isEnabled(feature: EnabledFeaturesEnum): Observable<boolean> {
		return this.state$.pipe(
			filter(({ updatedFromServer }) => !!updatedFromServer),
			map(({ enabledFeatures }) => enabledFeatures.includes(feature))
		);
	}
}
