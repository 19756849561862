function mapEmptyValues(values: (string | number | null | undefined)[]): (string | number | null | undefined)[] {
	// convert null & undefined values to empty strings for analytics purposes
	return values.map((value) => {
		if (value === null || value === undefined) {
			return '';
		}
		return value;
	});
}

export function sanitize(data: any): any {
	const result: any = {};
	for (const key in data) {
		if (Array.isArray(data[key])) {
			result[key] = mapEmptyValues(data[key]);
		} else {
			result[key] = data[key];
		}
	}
	return result;
}

export function safeStringSplit(
	input: string | number | null | undefined,
	delimiter: string
): Array<string | number | null | undefined> {
	if (typeof input !== 'string') {
		return [];
	}
	return input.split(delimiter);
}
