import { group, query, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeInSteps, fadeOutSteps, slideDownSteps, slideUpSteps } from '../../../animations';
import { HistoryService } from '../../../services';

@Component({
	selector: 'cdx-routed-modal-base',
	template: ``,
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		'[@modalOneView]': '',
	},
	animations: [
		// Define animations for the ROUTABLE VIEW itself, which has a HOST BINDING for
		// this animation trigger.
		trigger('modalOneView', [
			transition(':enter', [
				// Since we're going to be animating the modal in from an off-
				// screen location, we want to disable any local overflow so that
				// we don't see the interim scrollbars.
				style({
					overflow: 'hidden',
				}),
				group([
					query('.modal-contentContainer', slideUpSteps, { optional: true }),
					query('.modal-backdrop', fadeInSteps, { optional: true }),
				]),
			]),
			transition(':leave', [
				// Since we're going to be animating the modal out to an off-
				// screen location, we want to disable any local overflow so that
				// we don't see the interim scrollbars.
				style({
					overflow: 'hidden',
				}),
				group([
					query('.modal-contentContainer', slideDownSteps, { optional: true }),
					query('.modal-backdrop', fadeOutSteps, { optional: true }),
				]),
			]),
		]),
	],
	standalone: true
})
export class RoutedModalBaseComponent {
	public constructor(
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected historyService: HistoryService
	) { }

	close(options?: { shouldReplaceUrl?: boolean; url?: string }): void {
		this.router.navigateByUrl(
			options?.url ? options.url : this.historyService.getPreviousNonModalUrlForOutlet(this.activatedRoute.outlet),
			{
				replaceUrl: options?.shouldReplaceUrl,
			}
		);
	}

	goBack(): void {
		this.router.navigateByUrl(this.historyService.getPreviousUrl());
	}

	get modalInHistory(): boolean {
		return this.historyService.getPreviousUrl().includes(`modal:`);
	}
}
