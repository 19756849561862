import { Directive, Input, HostBinding, ChangeDetectorRef, AfterViewInit } from '@angular/core';

/**
 * @description Apply this to a component for CSS Grids
 * Has a dependency of a parent with grid. Usually
 * best added inside a hostbinding
 */
@Directive({
	selector: '[cdxGrid]',
	standalone: true
})
export class GridDirective implements AfterViewInit {
	@Input() cdxGrid: number[];
	@Input() class = '';
	@HostBinding('class')
	get hostClasses(): string {
		return [this.class, ...this.setGridClasses()].join(' ');
	}

	gridClasses: string[];

	constructor(private cdr: ChangeDetectorRef) { }

	ngAfterViewInit(): void {
		// Called after every check of the component's view. Applies to components only.
		// Add 'implements AfterViewChecked' to the class.

		if (!Array.isArray(this.cdxGrid) || this.cdxGrid.length < 3 || this.cdxGrid.length > 3) {
			throw Error('[GridDirective]: Expects an array with 3 values, one for mobile, tablet and desktop.');
		}

		this.cdr.markForCheck();
	}

	setGridClasses = (): string[] => [
		`u-grid-mspan${this.cdxGrid[0]}`,
		`u-grid-tspan${this.cdxGrid[1]}`,
		`u-grid-span${this.cdxGrid[2]}`,
	];
}
