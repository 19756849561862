import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { LegacyNoAjaxDirective } from '../../../directives/legacy-noajax.directive';
import { NgIf, NgFor } from '@angular/common';

export class PersuasionBarElement {
	icon: string;
	url: string;
	text: string;
}

/**
 * @description PersuasionBar
 * @export
 */
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'cdx-persuasion-bar',
	template: `
		<div class="persuasionBar-track" [attr.data-items]="itemCountLength" *ngIf="items">
			<ul class="persuasionBar-list">
				<li class="persuasionBar-item" *ngFor="let item of items">
					<a href="{{ item.url }}" cdxLegacyNoAjax>
						<span
							role="img"
							[attr.data-icon-color]="'light'"
							[attr.data-icon]="item.icon"
							class="persuasionBar-itemImage"
						></span>
						{{ item.text }}
					</a>
				</li>
			</ul>
		</div>
	`,
	styleUrls: ['./persuasion-bar.component.scss'],
	standalone: true,
	imports: [NgIf, NgFor, LegacyNoAjaxDirective]
})
export class PersuasionBarComponent implements OnInit {
	_items: PersuasionBarElement[];

	get itemCountLength(): number {
		return this._items.length;
	}

	@Input() set items(items: PersuasionBarElement[]) {
		this._items = items;
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-expressions
	get items(): PersuasionBarElement[] {
		return this._items;
	}

	ngOnInit(): void {
		if (!this.items) {
			// eslint-disable-next-line no-console
			console.warn('[Persuasion Bar]: No items passed through. Bar will not render');
		}
	}
}
