import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { APP_INITIALIZER, PLATFORM_ID, Provider } from '@angular/core';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { ShellService } from '@woolworthsnz/shop';
import {
	AppSettingsService,
	CustomWindow,
	DynatraceService,
	FlagService,
	HistoryService,
	MedalliaSurveyDataPushService,
	RouterScrollService,
	SearchRelevanceExperimentsService,
	ServerBrowserTransitionService,
	WINDOW,
	WindowInnerHeightService,
} from '@woolworthsnz/styleguide';
import { fromEvent } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication/services';
import { ExternalInterfaceService } from './core/services/external-interface.service';
import { JQueryDeprecationService } from './core/services/jquery-deprecation.service';
import { NativeBasketService } from './core/services/native-basket.service';
import { CookieService } from 'ngx-cookie-service';
import { EDRStartupService } from '@woolworthsnz/everyday-rewards';

export const startupFactory =
	// The injected services needs to match the deps array declared in providers below, otherwise the last parameter acts like a ...args and will be mapped to an array of the remaining deps


		(
			appSettingsService: AppSettingsService,
			authenticationService: AuthenticationService,
			shellService: ShellService,
			medalliaSurveyDataPushService: MedalliaSurveyDataPushService,
			windowInnerHeightService: WindowInnerHeightService,
			edrStartupService: EDRStartupService,
			platformId: string,
			nativeBasketService: NativeBasketService,
			jQueryDeprecationService: JQueryDeprecationService,
			document: Document,
			window: CustomWindow,
			tealiumUtagService: TealiumUtagService,
			serverBrowserTransitionService: ServerBrowserTransitionService,
			flagService: FlagService,
			searchRelevanceExperimentsService: SearchRelevanceExperimentsService,
			cookieService: CookieService,
			dynatraceService: DynatraceService
		) =>
		() => {
			const appRoot = document.body.querySelector('wnz-content') as HTMLElement;
			if (isPlatformBrowser(platformId) && appRoot.attributes.getNamedItem('data-renderer')?.value === 'server') {
				const transitionStyles = Array.from(document.querySelectorAll('style[ng-transition]'));

				const clientRoot = appRoot.cloneNode() as HTMLElement;

				appRoot.setAttribute('ng-non-bindable', '');
				clientRoot.style.display = 'none';

				document.body.insertBefore(clientRoot, appRoot);

				transitionStyles.forEach((element: Element) => {
					element.removeAttribute('ng-transition');
				});

				fromEvent(window as Window, 'load').subscribe(() => {
					transitionStyles.forEach((el: Element) => el.remove());

					clientRoot.style.display = 'block';
					appRoot.remove();
					serverBrowserTransitionService.setTransitionedToBrowser();
				});
			}

			return appSettingsService
				.initialize()
				.pipe(
					switchMap(() => {
						// set SSU default ON for web
						appSettingsService.setSSUCookie();
						// CEPFF-1957 - Must clear 'cw-ssoc' cookie for existing mobile users who are already in "freezing" app state
						if (
							isPlatformBrowser(platformId) &&
							cookieService.check('cw-ssoc') &&
							cookieService.get('cw-ssoc') === 'undefined'
						) {
							const domain = window.location.hostname;
							dynatraceService.triggerAction('jwt-undefined-before-delete');
							cookieService.delete('cw-ssoc', '/', domain, true, 'None');
							dynatraceService.triggerAction(
								`jwt-undefined-after-delete-${cookieService.get('cw-ssoc')}`
							);
						}
						return shellService.getApplicationShell();
					}),
					tap(() => {
						// after getting MobileSSU flag from shell response then we can turn on/off SSU for mobile embed web
						appSettingsService.handleSSUCookie();

						if (appSettingsService.useSSU()) {
							authenticationService.initialiseSSU();
						}

						// these things are only relevant on the browser
						if (isPlatformBrowser(platformId)) {
							flagService.init();
							searchRelevanceExperimentsService.setExperimentCookies();
						}

						// these things need initialising on both browser and server
						authenticationService.init();

						// Fetch global store state for Everyday Rewards
						edrStartupService.init();

						// these things are only relevant on the browser
						if (isPlatformBrowser(platformId)) {
							medalliaSurveyDataPushService.pushShopperSpecificInformation();
							medalliaSurveyDataPushService.rehydrateFromLocalStorage();
							windowInnerHeightService.initWindowInnerHeightCSSVariable();
							nativeBasketService.init();
							jQueryDeprecationService.init();
						}
					})
				)
				.toPromise();
		};

export const startupServiceProvider: Provider = {
	provide: APP_INITIALIZER,
	useFactory: startupFactory,
	deps: [
		AppSettingsService,
		AuthenticationService,
		ShellService,
		MedalliaSurveyDataPushService,
		WindowInnerHeightService,
		EDRStartupService,
		PLATFORM_ID,
		NativeBasketService,
		JQueryDeprecationService,
		DOCUMENT,
		WINDOW,
		TealiumUtagService,
		ServerBrowserTransitionService,
		FlagService,
		SearchRelevanceExperimentsService,
		CookieService,
		DynatraceService,
		// dependencies below this line just get the singleton initialised early - but they aren't used by the startup factory
		HistoryService,
		RouterScrollService,
		ExternalInterfaceService,
		TealiumUtagService,
	],
	multi: true,
};
