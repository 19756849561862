import { Injectable } from '@angular/core';
import { distinctUntilShopperLoggedInStateChanged } from '@woolworthsnz/fulfilment';
import {
	ApiService,
	AppSettingsService,
	LocalStorageService,
	mergeUnique,
	ShopperService,
	ShopperState,
	StatefulService,
} from '@woolworthsnz/styleguide';
import { ShoppingListsRequest } from '@woolworthsnz/trader-api';
import equal from 'fast-deep-equal';
import { Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';

export class ShoppingListState {
	items?: string[] = [];
	isOpen? = false;
	selectedItemIndex? = -1;
}

export enum ShoppingListLocation {
	api,
	localStorage,
}

@Injectable({
	providedIn: 'root',
})
export class ShoppingListService extends StatefulService<ShoppingListState> {
	localStorageKey = 'countdown-shoppingList';

	constructor(
		private apiService: ApiService,
		private shopperService: ShopperService,
		private localStorageService: LocalStorageService,
		private appSettingsService: AppSettingsService
	) {
		super(new ShoppingListState());

		this.shopperService.state$
			.pipe(take(1), distinctUntilShopperLoggedInStateChanged(), filter(this.isLoggedInShopper))
			.subscribe(this.handleShopperLoggedInStateChange);
	}

	get isOpen(): boolean | undefined {
		return this.state.isOpen;
	}

	get items(): string[] | undefined {
		return this.state.items || [];
	}

	get shoppingListsEndpoint(): string {
		return this.appSettingsService.getEndpoint('shoppingLists');
	}

	get shoppingListLocation(): ShoppingListLocation {
		return !this.shopperService.isShopper ? ShoppingListLocation.localStorage : ShoppingListLocation.api;
	}

	clearShoppingList(): void {
		if (this.shoppingListLocation === ShoppingListLocation.localStorage) {
			this.clearFromLocalStorage();
			return;
		}

		this.apiService.delete(`${this.shoppingListsEndpoint}/my/items`, {}).pipe(take(1)).subscribe();
	}

	getFromLocalStorage = (): any => this.localStorageService.getItem(this.localStorageKey);

	/**
	 * When a state change comes through to signify the shopper is logged in,
	 * we attempt to merge their guest shopping list (in local storage)
	 * with what comes back from the API.
	 */
	handleShopperLoggedInStateChange = (): void => {
		const guestShoppingListItems = this.getFromLocalStorage();

		if (!guestShoppingListItems?.length) {
			return;
		}

		const newList = mergeUnique(guestShoppingListItems, this.items || []);

		if (equal(newList, this.items)) {
			return;
		}

		this.updateShoppingList(newList);
	};

	isLoggedInShopper = (s: ShopperState): boolean => Boolean(s.isLoggedIn && s.isShopper);

	updateShoppingList(items: string[]): void {
		if (this.shoppingListLocation === ShoppingListLocation.localStorage) {
			this.setToLocalStorage(items);
			return;
		}

		const body: ShoppingListsRequest = {
			items,
		};

		this.apiService.post(`${this.shoppingListsEndpoint}/my/items`, body).pipe(take(1)).subscribe();
	}

	setToLocalStorage(items: string[] = []): void {
		if (items.length > 0) {
			this.localStorageService.setItem(this.localStorageKey, JSON.stringify(items));
			return;
		}

		this.clearFromLocalStorage();
	}

	clearFromLocalStorage(): void {
		this.localStorageService.removeItem(this.localStorageKey);
	}

	removeItemFromShoppingList = (index: number): Observable<any> => {
		if (this.shoppingListLocation !== ShoppingListLocation.localStorage) {
			const itemTobeDeleted = this.items && this.items[index];
			return this.apiService.delete(`${this.shoppingListsEndpoint}/my/items/${itemTobeDeleted}`);
		}

		const tempItems = this.items ? [...this.items] : [];

		tempItems.splice(index, 1);

		this.setState({ items: tempItems });

		this.setToLocalStorage(tempItems);

		return of(true);
	};
}
