import { Injectable } from '@angular/core';
import {
	AlertType,
	ApiService,
	AppSettingsService,
	DatalayerService,
	ModalEvent,
	ModalOverlayService,
	SessionSettingsEvent,
	ShopperService,
	ShopperState,
} from '@woolworthsnz/styleguide';
import {
	AddOrUpdateAddressResponse,
	BasketTotalsResponse,
	FulfilmentMethodsResponse,
	FulfilmentStoreResponse,
	FulfilmentSuburbsResponse,
	FulfilmentWindowsSummaryResponse,
	FulfilmentWindowSummarySlot,
	OrderViewModel,
	ShopperFulfilmentDetails,
	UpdateFulfilmentTimeSlotsRequest,
	UpdateFulfilmentTimeSlotsResponse,
	UpdatePickupAddressResponse,
} from '@woolworthsnz/trader-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { defer, EMPTY, Observable, Subscription, throwError } from 'rxjs';
import { catchError, finalize, switchMap, take, tap } from 'rxjs/operators';
import { DaySlots, PickupType } from '../ui-models';
import { FulfilmentState, FulfilmentStoreService } from './fulfilment-store.service';
import { ManageAddressService } from './manage-address.service';

dayjs.extend(utc);

// tslint:disable-next-line: no-unused-expression
export const finalizeWithValue = (callback: (value: any) => void) => (source: Observable<any>) =>
	defer(() => {
		let lastValue: any;
		return source.pipe(
			tap((value) => (lastValue = value)),
			finalize(() => callback(lastValue))
		);
	});

@Injectable({
	providedIn: 'root',
})
export class FulfilmentService {
	private clearTimeSlotSubscription?: Subscription;
	private getTimeSlotsSubscription?: Subscription;

	constructor(
		private apiService: ApiService,
		private appSettingsService: AppSettingsService,
		private datalayerService: DatalayerService,
		private fulfilmentStoreService: FulfilmentStoreService,
		private manageAddressService: ManageAddressService,
		private modalOverlayService: ModalOverlayService,
		private shopperService: ShopperService
	) {}

	get inDeliveryZone(): boolean | undefined {
		return this.fulfilmentStoreService.state?.isAddressInDeliveryZone;
	}

	get method(): OrderViewModel.DeliveryMethodEnum | undefined {
		return this.fulfilmentStoreService.state?.method;
	}

	get perishableCode(): ShopperFulfilmentDetails.PerishableCodeEnum | undefined {
		return this.fulfilmentStoreService.state?.perishableCode;
	}

	get fulfilmentStoreId(): number | undefined {
		return this.fulfilmentStoreService.state?.fulfilmentStoreId;
	}

	get postSuburbEndpoint(): string {
		return this.appSettingsService.getEndpoint('postSuburb');
	}

	get postMethodEndpoint(): string {
		return this.appSettingsService.getEndpoint('postMethod');
	}

	get postStoreEndpoint(): string {
		return this.appSettingsService.getEndpoint('postPickupAddress');
	}

	get postAddressEndpoint(): string {
		return this.appSettingsService.getEndpoint('postAddress');
	}

	get postPrimaryAddressEndpoint(): string {
		return this.appSettingsService.getEndpoint('postPrimaryAddress');
	}

	get fulfilmentStoresEndpoint(): string {
		return this.appSettingsService.getEndpoint('fulfilmentStores');
	}

	get suburb(): string | { id: number; name?: string } | undefined {
		return this.fulfilmentStoreService.state?.suburb;
	}

	get getTimeslotsEndpoint(): string {
		return this.appSettingsService.getEndpoint('getTimeslotsSummary');
	}

	get postTimeslotEndpoint(): string {
		return this.appSettingsService.getEndpoint('postTimeslot');
	}

	get deleteTimeslotsEndpoint(): string {
		return this.appSettingsService.getEndpoint('deleteTimeslots');
	}

	get isExpressSlot(): boolean | undefined {
		return this.fulfilmentStoreService.state?.expressFulfilment?.isExpressSlot;
	}

	get pickupAddress(): string | undefined {
		return this.fulfilmentStoreService.state?.address;
	}

	getReducedRangeAlert(): { title: string; description: string; alertType: AlertType } | undefined {
		if (this.method !== 'Courier') {
			return;
		}

		if (!this.inDeliveryZone) {
			return {
				title: this.appSettingsService.getMessage('outOfZoneAlertTitle'),
				description: this.appSettingsService.getMessage('outOfZoneAlertDescription'),
				alertType: AlertType.warning,
			};
		}

		if (this.perishableCode === ShopperFulfilmentDetails.PerishableCodeEnum.L) {
			return {
				title: this.appSettingsService.getMessage('limitedPerishableTitle'),
				description: this.appSettingsService.getMessage('limitedPerishableDescription'),
				alertType: AlertType.info,
			};
		}

		if (this.perishableCode === ShopperFulfilmentDetails.PerishableCodeEnum.N) {
			return {
				title: this.appSettingsService.getMessage('nonPerishableTitle'),
				description: this.appSettingsService.getMessage('nonPerishableDescription'),
				alertType: AlertType.info,
			};
		}

		return;
	}

	getDeliverySubscriptionAlert(
		eligibleForDeliverySubscriptionDiscount: BasketTotalsResponse.EligibilityForDeliverySubscriptionDiscountEnum
	): { title: string; description: string; alertType: AlertType } | undefined {
		if (
			eligibleForDeliverySubscriptionDiscount ===
			BasketTotalsResponse.EligibilityForDeliverySubscriptionDiscountEnum.DeliveryToNotPerishableAddress
		) {
			return {
				title: this.appSettingsService.getSubscriptionMessage('deliverySubscriptionOutOfZoneTitle'),
				description: '',
				alertType: AlertType.warning,
			};
		}

		if (
			eligibleForDeliverySubscriptionDiscount ===
			BasketTotalsResponse.EligibilityForDeliverySubscriptionDiscountEnum.DeliveryToNotServicedAddress
		) {
			return {
				title: this.appSettingsService.getSubscriptionMessage('deliverySubscriptionNotServicedTitle'),
				description: '',
				alertType: AlertType.warning,
			};
		}

		if (
			eligibleForDeliverySubscriptionDiscount ===
			BasketTotalsResponse.EligibilityForDeliverySubscriptionDiscountEnum.Eligible
		) {
			return {
				title: this.appSettingsService.getSubscriptionMessage('deliverySubscriptionAppliedTitle'),
				description: '',
				alertType: AlertType.info,
			};
		}

		return;
	}

	trackFulfilmentChangeInDatalayer(state: FulfilmentState): void {
		const {
			method,
			areaId,
			fulfilmentStoreId: storeId,
			startTime,
			endTime,
			selectedDate,
			pickupType,
			serverTimeZoneOffset,
			slots,
			expressFulfilment,
			isExpressAbandoned,
		} = state;

		// The spelling mistake here is intentional. That's the way the datalayer wants it
		const fulfillmentWindow = this.getFulfilmentWindowString(selectedDate, startTime, endTime);
		const fulfillmentMethod = this.mappedToClientMethod(method || 'Courier');
		const pickupInfo = this.getPickupTypeInformation(fulfillmentMethod, pickupType);

		let event: SessionSettingsEvent = {
			fulfillmentMethod,
			fulfillmentWindow,
			areaId,
			storeId,
			pickUpType: pickupInfo,
			isExpress: !!expressFulfilment?.isExpressSlot,
			isExpressAbandoned,
		};

		// Try to find out whether the Delivery Window selected has any discount with it.
		const slot = this.findSlot(selectedDate, startTime, endTime, serverTimeZoneOffset, slots);

		// for any slot.discount is not 0
		if (slot && slot.discount) {
			event = {
				...event,
				discountedWindow: 'yes',
				discountValue: slot.discount,
			};
		}

		this.datalayerService.trackSessionSettings(event);
	}

	changeFulfilmentMethod = (method: 'Pickup' | 'Courier'): Observable<FulfilmentMethodsResponse> =>
		this.apiService
			.put(`${this.postMethodEndpoint}/${method.toLowerCase()}`, {})
			.pipe(take(1), tap(this.getTimeslots));

	changeSelectedTraderSuburb = (id: number): Observable<FulfilmentSuburbsResponse> =>
		this.apiService.put(`${this.postSuburbEndpoint}/${id}`, {}).pipe(
			catchError((e) => {
				this.handleError(e);

				return throwError(e);
			}),
			tap(this.getTimeslots)
		);

	changeSelectedPickupStore = (addressId: number): Observable<UpdatePickupAddressResponse> =>
		this.apiService.put(this.postStoreEndpoint, { addressId }).pipe(
			take(1),
			catchError((e) => {
				this.handleError(e);

				return throwError(e);
			}),
			tap(this.getTimeslots)
		);

	changeSelectedDeliveryAddress = (addressId: string): Observable<AddOrUpdateAddressResponse> =>
		this.apiService.put(`${this.postPrimaryAddressEndpoint}/${addressId}`, {}).pipe(
			take(1),
			catchError((e) => {
				this.handleError(e);

				return throwError(e);
			}),
			tap(this.getTimeslots),
			finalize(() => {
				this.manageAddressService.getAddresses();
			})
		);

	changeTimeslot = (
		request: UpdateFulfilmentTimeSlotsRequest
	): Observable<UpdateFulfilmentTimeSlotsResponse | never> =>
		this.shopperService.state$.pipe(
			take(1),
			switchMap((state: ShopperState) => {
				if (!state.isShopper || !state.isLoggedIn) {
					this.modalOverlayService.open({
						eventType: ModalEvent.selectFulfilmentSlot,
					});
					return EMPTY;
				}

				return this.apiService.put(this.appSettingsService.getEndpoint('postTimeslot'), request).pipe(
					catchError((e) => {
						this.handleError(e);
						return throwError(e);
					}),
					finalize(() => {
						this.fulfilmentStoreService.setState({
							currentReservationAlertsFired: {
								closingSoon: false,
								closingNow: false,
								closed: false,
							},
						});
						this.getTimeslots();
					})
				);
			})
		);

	handleError = (e: { status: any; message: string | undefined }): void => {
		this.fulfilmentStoreService.setState({
			error: e,
			completed: false,
		});
	};

	clearTimeslot(): Subscription {
		if (this.clearTimeSlotSubscription && !this.clearTimeSlotSubscription.closed) {
			this.clearTimeSlotSubscription.unsubscribe();
		}

		this.clearTimeSlotSubscription = this.apiService
			.delete(this.deleteTimeslotsEndpoint)
			.pipe(
				catchError((error) => {
					this.handleError(error);
					return throwError(error);
				})
			)
			.subscribe();

		return this.clearTimeSlotSubscription;
	}

	/**
	 * This has to be written as a member instead of a function to avoid regression issues
	 */
	getTimeslots = (): Subscription => this.getTimeslotsInternal();

	private getTimeslotsInternal(): Subscription {
		if (this.getTimeSlotsSubscription && !this.getTimeSlotsSubscription.closed) {
			this.getTimeSlotsSubscription.unsubscribe();
		}

		this.getTimeSlotsSubscription = this.apiService
			.get(this.getTimeslotsEndpoint)
			.subscribe((r: FulfilmentWindowsSummaryResponse) => {
				this.fulfilmentStoreService.setState({
					...r,
					daySlots: this.groupSlotsByDays(r.slots || []),
				});
			});

		return this.getTimeSlotsSubscription;
	}

	// Returns the pickup type information for session event
	getPickupTypeInformation = (method: 'Pickup' | 'Delivery', pickupType: PickupType | undefined): string =>
		method === 'Delivery' ? '' : pickupType && pickupType === PickupType.Locker ? 'locker' : 'in-store';

	/**
	 * Takes an array of slots with start DateTime and groups them by day (according to server timezone)
	 * @param slots the array of slots to group
	 * @returns An object with arrays of slots keyed by the date on which those slots start
	 */

	groupSlotsByDays(slots: FulfilmentWindowSummarySlot[]): DaySlots {
		let currDate = this.getStartOfDay(slots[0]?.start);
		return slots?.reduce(
			(acc, slot: FulfilmentWindowSummarySlot) => {
				const startOfDay = this.getStartOfDay(slot.start);
				const dayStr = startOfDay.format();
				if (!acc[dayStr]) {
					// add any in-between dates
					let nextDay = currDate.add(1, 'day');
					while (nextDay?.isBefore(startOfDay)) {
						acc[nextDay.format()] = [];
						nextDay = nextDay.add(1, 'day');
					}
					// and define the day as well
					acc[dayStr] = [];
				}
				acc[dayStr]?.push(slot);
				currDate = startOfDay;
				return acc;
			},
			<any>{}
		);
	}

	/*
    This is used over dayjs .startof because dayjs doesn't handle different timezones... you can either be local or in utc, but neither is sufficient to manipulate times that come back with a different utc offset to the local offset. So here we have to set the offset explicitly according to what the server returns and then set the time to midnight.
  */
	getStartOfDay(t?: string): dayjs.Dayjs {
		const utcOffset = parseInt(t?.split('+')[1].split(':')[0] || '0', 10);
		// to prevent issues with DST start of day is 2am (or 3am if clocks go forward and 1 am if they go back). Server side is only interested in the Date - time is irrelevant anyway.
		return dayjs(t).utcOffset(utcOffset).hour(2).minute(0).second(0).millisecond(0);
	}

	// We need this because the API uses 'Courier' to mean delivery
	// TODO - update when we do POD-5430 and the related back-end work
	mappedToClientMethod(serverMethod: 'Courier' | 'Pickup'): 'Pickup' | 'Delivery' {
		return serverMethod === 'Courier' ? 'Delivery' : serverMethod;
	}

	// We need this because the API uses 'Courier' to mean delivery
	// TODO - update when we do POD-5430 and the related back-end work
	mappedToServerMethod(clientMethod: 'Pickup' | 'Delivery'): 'Pickup' | 'Courier' {
		return clientMethod === 'Delivery' ? 'Courier' : clientMethod;
	}

	getAnalyticsData(): any {
		return {
			fufilment_method: this.fulfilmentStoreService.state.method || '',
			fulfilment_pickup_type: this.fulfilmentStoreService.state.pickupType?.toString() || '',
			fulfilment_store_id: this.fulfilmentStoreService.state.fulfilmentStoreId || '',
			fulfilment_date: this.fulfilmentStoreService.state.selectedDate?.toLocaleString() || '',
			fulfilment_time: this.fulfilmentStoreService.state.startTime
				? `${this.fulfilmentStoreService.state.startTime} - ${this.fulfilmentStoreService.state.endTime}`
				: '',
			fulfilment_location: this.fulfilmentStoreService.state.address || '',
		};
	}

	fetchFulfilmentStore(fulfilmentStoreId: number): Observable<FulfilmentStoreResponse> {
		return this.apiService.get(`${this.appSettingsService.getEndpoint('fulfilmentStores')}/${fulfilmentStoreId}`);
	}

	/**
	 * Gets fulfilment slot as a formatted string for the Datalayer push
	 * @param selectedDate
	 * @param startTime
	 * @param endTime
	 */
	private getFulfilmentWindowString(
		selectedDate: string | Date | undefined,
		startTime: string | undefined,
		endTime: string | undefined
	): string | undefined {
		if (!selectedDate) {
			return;
		}

		return `${dayjs(selectedDate).startOf('day').format('DD/MM/YYYY')} ${startTime} - ${endTime}`;
	}

	/**
	 * try to locate the selected slot object by selectedDate, startTime, endTime, serverTimeZoneOffset.
	 */
	private findSlot(
		selectedDate: Date | string | undefined,
		startTime: string | undefined,
		endTime: string | undefined,
		serverTimeZoneOffset: string,
		slots: Array<FulfilmentWindowSummarySlot> | undefined
	): FulfilmentWindowSummarySlot | undefined {
		if (!selectedDate || !startTime || !endTime || !slots) {
			return;
		}

		const start = this.buildDate(selectedDate, startTime, serverTimeZoneOffset);
		const end = this.buildDate(selectedDate, endTime, serverTimeZoneOffset);

		return slots.find((s) => dayjs(s.start).isSame(start) && dayjs(s.end).isSame(end));
	}

	/**
	 * build Dayjs object from input parameters.
	 *
	 * @param date looks like '2021-05-18T00:00:00'
	 * @param time looks like '8:30PM'
	 * @param serverTimeZoneOffset looks like '+12:00'
	 */
	private buildDate(
		date: Date | string | undefined,
		time: string | undefined,
		serverTimeZoneOffset: string
	): dayjs.Dayjs {
		if (!date || !time) {
			return dayjs('1970-01-01');
		}

		const day = date instanceof Date ? date.toISOString().split('T')[0] : date.split('T')[0];

		const timePart1 = time.slice(0, -2);
		const timePart2 = time.slice(-2);

		// '2021-05-18 8:30 PM +12:00'
		return dayjs(`${day} ${timePart1} ${timePart2} ${serverTimeZoneOffset}`, 'YYYY-MM-DD h:mm A Z');
	}
}
