import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { openClose } from '../../../animations';
import { NgClass } from '@angular/common';

@Component({
	selector: 'cdx-collapsible-content',
	template: `
		<div
			[@openClose]="isOpen"
			class="collapsibleContent icon"
			[ngClass]="{ 'icon-inverted': invertIcon }"
			[class.open]="isOpen"
			(click)="toggleFilters()"
		>
			<div class="content">
				<ng-content></ng-content>
			</div>
		</div>
	`,
	styleUrls: ['./collapsible-content.component.scss'],
	animations: [...openClose],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass],
})
export class CollapsibleContentComponent {
	@Input() invertIcon = false;
	@Input() isOpen = false;

	toggleFilters = (): void => {
		this.isOpen = !this.isOpen;
	};
}
