import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[cdxFocusOnChange]',
	standalone: true
})
export class FocusOnChangeDirective {
	_val: any;

	constructor(private elementRef: ElementRef) { }

	@Input() set cdxFocusOnChange(val: any) {
		if (val !== this._val) {
			this.elementRef?.nativeElement?.focus();
		}
		this._val = val;
	}
}
