import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { BasketService, BasketState, MODAL_ROUTES } from '@woolworthsnz/shop';
import { ModalRoutingService } from '@woolworthsnz/styleguide';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class EmptyBasketRedirectGuard {
	constructor(public basketService: BasketService, private modalRoutingService: ModalRoutingService) {}

	canActivate(): Observable<boolean | UrlTree> {
		return this.basketService.state$.pipe(
			map((s: BasketState) => {
				const itemsInBasket = (s.totalItems ?? 0) > 0;
				if (!itemsInBasket) {
					this.modalRoutingService.open(MODAL_ROUTES.MODAL_OUTLET, MODAL_ROUTES.EMPTY_TROLLEY_PATH);
				}
				return itemsInBasket;
			})
		);
	}
}
