import { InjectionToken, Provider } from '@angular/core';
import { CustomWindow, WINDOW } from './window.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import * as SSRAppInsights from 'applicationinsights';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AppInsights = new InjectionToken<ApplicationInsights>('application insights');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ServerAppInsights = new InjectionToken<SSRAppInsights.TelemetryClient>('application insights');

const appInsightsFactory = (customWindow: CustomWindow): ApplicationInsights | undefined => {
	if (customWindow?.BOOTSTRAP_DATA?.AppInsights?.config) {
		return new ApplicationInsights({
			config: customWindow.BOOTSTRAP_DATA.AppInsights.config,
		});
	}
	return undefined;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AppInsightsProvider: Provider = {
	provide: AppInsights,
	useFactory: appInsightsFactory,
	deps: [WINDOW],
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ServerAppInsightsProvider: Provider = {
	provide: ServerAppInsights,
	useFactory: () => SSRAppInsights?.defaultClient,
};
