import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

// *WARNING* This is intended to be used as an HTML component on legacy for V1
@Component({
	selector: 'cdx-warning',
	templateUrl: './warning.component.html',
	styleUrls: ['./warning.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf]
})
export class WarningComponent {
	@Input() isVisible = false;
	@Input() hasCloseButton = true;
	@Input() message = '';
}
