import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakPointService, Device } from '../../../services';
import { NgStyle, NgIf, AsyncPipe } from '@angular/common';

@Component({
	selector: 'cdx-full-bleed-image-header',
	templateUrl: './full-bleed-image-header.component.html',
	styleUrls: ['./full-bleed-image-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgStyle, NgIf, AsyncPipe],
})
export class FullBleedImageHeaderComponent implements OnInit {
	@Input() backgroundImagePath: string;
	@Input() backgroundColor: string;
	@Input() smallBackgroundImagePath: string;

	public isMobile$: Observable<boolean>;

	constructor(private breakPointService: BreakPointService) {}

	ngOnInit(): void {
		this.isMobile$ = this.breakPointService.device$.pipe(map((device) => device === Device.MOBILE));
	}
}
