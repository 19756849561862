import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { StatusCodes } from 'http-status-codes';
import { AppSettingsService } from '../services';
import { isPlatformServer } from '@angular/common';
import { AuthFacade } from '../states';

@Injectable({ providedIn: 'root' })
export class HttpErrorsInterceptor implements HttpInterceptor {
	constructor(
		private appSettingsService: AppSettingsService,
		private authFacade: AuthFacade,
		@Inject(PLATFORM_ID) private platformId: Object
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				const errorMsg = this.setError(error);
				/* eslint-disable no-console */
				console.warn(errorMsg);
				return throwError(() => error);
			})
		);
	}

	setError(error: HttpErrorResponse): string {
		let errorMsg = 'Unknown error occured';
		if (error.error instanceof ErrorEvent) {
			// Client side errors
			errorMsg = error.error.message || 'Error comes from client';
		} else {
			// Server side errors
			if (error.status !== 0) {
				switch (error.status) {
					case StatusCodes.UNAUTHORIZED:
						errorMsg = `Unauthorized error ${error.status}, the request is not valid now you might need to authenticate first`;
						if (!isPlatformServer(this.platformId) && this.appSettingsService.useSSU()) {
							this.authFacade.refreshAuthToken();
						}
						break;
					case StatusCodes.BAD_REQUEST:
						errorMsg = `Unauthorized error ${error.status}, the request is not valid now you might need to authenticate first`;
						break;
					case StatusCodes.NOT_FOUND:
						errorMsg = `Not found error ${error.status}, the request resource can not be found`;
						break;
					case StatusCodes.UNPROCESSABLE_ENTITY:
						errorMsg = `Unprocessable content error ${error.status}, server was unable to process this request`;
						break;
					case StatusCodes.INTERNAL_SERVER_ERROR:
						errorMsg = `Internal server error ${error.status}, something wrong with the server, please try it later`;
						break;
					default:
						errorMsg = `Unexpected server error ${error.status}, ${error.error}`;
				}
			}
		}
		return errorMsg;
	}
}
