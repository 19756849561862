import { createAction, props } from '@ngrx/store';
import { EdrBalanceResponseV2, RewardMessage } from '@woolworthsnz/trader-api';

const featureGroupName = '[EDR]';

/** Fetch EDR Balance **/
export const fetchEdrBalance = createAction(`${featureGroupName} Fetch EDR Balance`);
export const fetchEdrBalanceFailed = createAction(
	`${featureGroupName} Fetch EDR Balance Failed`,
	props<{
		error: any;
	}>()
);
export const fetchEdrBalanceSuccess = createAction(
	`${featureGroupName} Fetch EDR Balance Success`,
	props<{
		edrBalanceResponse: EdrBalanceResponseV2;
	}>()
);

/** Fetch Boost Offers Count **/
export const fetchBoostOffersCount = createAction(`${featureGroupName} Fetch Boost Offers Count`);
export const fetchBoostOffersCountFailed = createAction(
	`${featureGroupName} Fetch Boost Offers Count Failed`,
	props<{
		error: any;
	}>()
);
export const fetchBoostOffersCountSuccess = createAction(
	`${featureGroupName} Fetch Boost Offers Count Success`,
	props<{
		boostOffersCount: number;
	}>()
);

/** Fetch Reward Messages **/
export const fetchRewardMessages = createAction(`${featureGroupName} Fetch Reward Messages`);
export const fetchRewardMessagesFailed = createAction(
	`${featureGroupName} Fetch Reward Messages Failed`,
	props<{
		error: any;
	}>()
);
export const fetchRewardMessagesSuccess = createAction(
	`${featureGroupName} Fetch Reward Messages Success`,
	props<{
		rewardMessages: RewardMessage[];
	}>()
);

/** Delete Reward Message **/
export const deleteRewardMessage = createAction(
	`${featureGroupName} Delete Reward Message`,
	props<{
		messageId: string;
	}>()
);
export const deleteRewardMessageFailed = createAction(
	`${featureGroupName} Delete Reward Message Failed`,
	props<{
		error: any;
	}>()
);
export const deleteRewardMessageSuccess = createAction(`${featureGroupName} Delete Reward Message Success`);
