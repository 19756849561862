import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivationStart, Router, UrlSegment, UrlTree } from '@angular/router';
import { SearchLibService, SearchQueryParams } from '@woolworthsnz/search';
import { MODAL_ROUTES } from '@woolworthsnz/shop';
import {
	AppSettingsService,
	BreadcrumbItem,
	BreadcrumbProviderService,
	buildBreadcrumbAsQueryParams,
	buildBreadcrumbAsUrlTree,
	FeatureService,
	kebabCase,
	ModalRoutingService,
	replaceSpecialCharacters,
	SearchTarget,
	StatefulService,
} from '@woolworthsnz/styleguide';
import { Breadcrumb, ContextResponse } from '@woolworthsnz/trader-api';
import { filter, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
/* eslint-disable brace-style */
export class BreadCrumbService
	extends StatefulService<{ breadcrumbs: BreadcrumbItem[] }>
	implements BreadcrumbProviderService
{
	/* eslint-disable brace-style */
	constructor(
		private router: Router,
		private appSettingsService: AppSettingsService,
		private modalRoutingService: ModalRoutingService,
		private searchLibService: SearchLibService,
		private featureService: FeatureService
	) {
		super({ breadcrumbs: [] });

		this.router.events
			.pipe(
				takeUntilDestroyed(),
				filter((routerEvent) => routerEvent instanceof ActivationStart),
				map((routerEvent) => routerEvent as ActivationStart),
				// Ignore modal routes as they won't have the relevant data properties
				filter(
					(routerEvent: ActivationStart) =>
						!this.modalRoutingService.isInModal(MODAL_ROUTES.MODAL_OUTLET, routerEvent.snapshot)
				)
			)
			.subscribe((e) => {
				if (!e.snapshot.data.breadcrumb) {
					this.setBreadcrumbItems([]);
				}
			});
	}

	get routeUrl(): string {
		return this.router.url.split('?') && this.router.url.split('?')[0];
	}

	buildUrlPath({
		breadcrumb,
		urlSegments = [],
		target = SearchTarget.Search,
		dasFilterName = '',
	}: {
		breadcrumb: Breadcrumb | undefined;
		urlSegments?: UrlSegment[];
		target?: string | SearchTarget | null | undefined;
		dasFilterName?: string;
	}): string {
		if (target === SearchTarget.Search) {
			return `/${this.appSettingsService.getSetting('pageUrl').productSearch}`;
		}

		if (
			target === SearchTarget.ProductGroup ||
			target === SearchTarget.DynamicProductGroup ||
			target === SearchTarget.BoostOffer
		) {
			return this.routeUrl;
		}

		const segmentStrings = this.getSegmentStrings(urlSegments, target);

		let urlPath: UrlTree;
		if (!!breadcrumb?.aisle || !!breadcrumb?.shelf) {
			urlPath = this.router.createUrlTree([...segmentStrings.slice(0, 4), kebabCase(dasFilterName)]);
		} else if (!!breadcrumb?.department) {
			urlPath = this.router.createUrlTree([...segmentStrings.slice(0, 3), kebabCase(dasFilterName)]);
		} else {
			urlPath = this.router.createUrlTree([...segmentStrings.slice(0, 2), kebabCase(dasFilterName)]);
		}

		if (!!breadcrumb?.shelf && !dasFilterName) {
			urlPath = this.router.createUrlTree(segmentStrings.slice(0, 5));
		}

		return this.router.serializeUrl(urlPath);
	}

	getSegmentStrings = (urlSegments: UrlSegment[], target?: string | SearchTarget | null | undefined): string[] => {
		let segmentStrings = [...urlSegments.map((u: any): string => u.path || u)];
		// Reset to a browse page if we are on a sku list page
		if (target === SearchTarget.SKUs) {
			segmentStrings = this.appSettingsService.getSetting('pageUrl').browse.split('/');
		}

		if (segmentStrings.length > 0 && segmentStrings[0].indexOf('shop') === -1) {
			segmentStrings.unshift(this.appSettingsService.getSetting('BASE_PAGE_URL'));
		}
		return segmentStrings;
	};

	getBreadcrumbItems({
		target,
		breadcrumb,
		prefixItem,
		suffixItem,
		queryParams,
	}: {
		target: string | SearchTarget;
		breadcrumb: Breadcrumb;
		prefixItem?: BreadcrumbItem;
		suffixItem?: BreadcrumbItem;
		queryParams?: SearchQueryParams;
	}): BreadcrumbItem[] {
		const dasFilters: any[] = [];
		const items = ['department', 'aisle', 'shelf'].reduce((prev: any, itemKey) => {
			const item = breadcrumb[itemKey as keyof Breadcrumb];

			const breadcrumbTarget = target === 'ProductDetails' ? SearchTarget.Browse : target;
			if (!!item && typeof item !== 'string') {
				dasFilters.push(this.searchLibService.buildFilterQueryString(item));

				const previousItemUrl = prev.length && prev[prev.length - 1].routeUrl;
				const itemName = replaceSpecialCharacters(item.name || '');
				prev.push(
					breadcrumbTarget !== SearchTarget.Browse
						? buildBreadcrumbAsQueryParams(item.name || '', this.routeUrl, {
								...queryParams,
								dasFilter: [...dasFilters],
						  })
						: buildBreadcrumbAsUrlTree(
								item.name || '',
								this.buildUrlPath({
									breadcrumb,
									urlSegments: [
										previousItemUrl || breadcrumbTarget,
										kebabCase(itemName).replace(/-{2,}/g, '-'),
									],
									target,
								}),
								queryParams
						  )
				);
			}

			return prev;
		}, []);

		if (prefixItem) {
			items.unshift(prefixItem);
		}

		if (suffixItem) {
			items.push(suffixItem);
		}
		if (this.featureService.isFeatureEnabled(ContextResponse.EnabledFeaturesEnum.GreatToLowPrice)) {
			items.forEach((item: any) => {
				if (item.text.toLowerCase() === 'great price') {
					item.text = 'Low Price';
				}
			});
		}
		return items;
	}

	setBreadcrumbs = (
		target: string,
		breadcrumb: Breadcrumb,
		prefixItem?: BreadcrumbItem,
		suffixItem?: BreadcrumbItem,
		queryParams?: SearchQueryParams
	): void => {
		this.setState({
			breadcrumbs: this.getBreadcrumbItems({
				target,
				breadcrumb,
				prefixItem,
				suffixItem,
				queryParams,
			}),
		});
	};

	setBreadcrumbItems = (items: BreadcrumbItem[]): void => {
		this.setState({ breadcrumbs: items });
	};
}
