import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { ChangeOrderService, ChangeOrderState, HOME_PATH } from '@woolworthsnz/shop';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
	NOTE: Side-effects. This guard cannot return a URLTree until URLTrees support the navigationExtras option of replaceUrl. While it continues to use navigateByUrl it introduces side-effect that mean it should not be used with other guards in parallel.
	*/
@Injectable({
	providedIn: 'root',
})
export class HowWhereWhenPageGuard {
	constructor(private changeOrderService: ChangeOrderService, private router: Router) {}

	canActivate(): Observable<boolean | UrlTree> {
		return this.changeOrderService.state$.pipe(
			map((changeOrderState: ChangeOrderState) => {
				if (changeOrderState.isChangingOrder) {
					this.router.navigateByUrl(HOME_PATH, { replaceUrl: true });
					return false;
				}
				return true;
			})
		);
	}
}
