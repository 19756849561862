import { Component, Input, HostBinding, ChangeDetectionStrategy } from '@angular/core';
export type BadgeSize = 'small' | 'default';

@Component({
	selector: 'cdx-sustainable-badge',
	templateUrl: './sustainable-badge.component.html',
	styleUrls: ['./sustainable-badge.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class SustainableBadgeComponent {
	@HostBinding('attr.size')
	@Input()
	size?: BadgeSize;
}
