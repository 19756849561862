import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EVERYDAY_REWARDS_FEATURE_KEY, EverydayRewardsEffects, EverydayRewardsFacade } from './+state';
import * as fromEDR from './+state/everyday-rewards.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature(EVERYDAY_REWARDS_FEATURE_KEY, fromEDR.everydayRewardsReducer),
		EffectsModule.forFeature([EverydayRewardsEffects]),
	],
	providers: [EverydayRewardsFacade],
})
export class EDRStateModule {}
