import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	InjectionToken,
	Input,
	Optional,
	Output,
} from '@angular/core';
import { ModalSize } from '../../../ui-models';
import { ButtonComponent } from '../../../4_atoms/components/button/button.component';
import { SvgIconComponent } from '../../../4_atoms/components/svg-icon/svg-icon.component';
import { ModalComponent } from '../modal/modal.component';
import { NgIf, NgStyle, NgClass } from '@angular/common';

export const GENERIC_MODAL_DATA = new InjectionToken<GenericModal>('GENERIC_MODAL_DATA');

export class GenericModal {
	hasActionButton? = true;
	buttonText? = 'Ok, got it';
	icon? = 'alert';
	iconFill? = 'alert';
	title? = `We're having technical issues at present`;
	description?: string;
	hasMultipleParagraphDesc? = false;
	align? = 'center';
	hasCancelButton?: boolean;
	cancelButtonText? = 'Cancel';
	fitContent?: boolean;
	modalSize?: ModalSize;
	skipTracking? = false;
	isNewTextStyle?: boolean = false;
	isButtonPrimary?: boolean = false;
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	ctaAction?: Function = () => {};
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	closeAction?: Function = () => {};
	hasLinkButton?: boolean;
	linkButtonText?: string;
}

@Component({
	selector: 'cdx-generic-modal',
	templateUrl: './generic-modal.component.html',
	styleUrls: ['./generic-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, ModalComponent, SvgIconComponent, NgStyle, ButtonComponent, NgClass],
})
export class GenericModalComponent {
	@Output() modalCTAEmitter: EventEmitter<any> = new EventEmitter();

	@Input() modalData: GenericModal = new GenericModal();

	constructor(@Optional() @Inject(GENERIC_MODAL_DATA) public injectedModalData: GenericModal) {
		if (injectedModalData) {
			this.modalData = {
				...this.modalData,
				...injectedModalData,
			};
		}
	}

	closeModal(): void {
		if (typeof this.modalData.closeAction === 'function') {
			this.modalData.closeAction();
		}
	}

	ctaEmit(): void {
		if (typeof this.modalData.ctaAction === 'function') {
			this.modalData.ctaAction();
		}

		this.modalCTAEmitter.emit();
	}
}
