import { ComponentPortal } from '@angular/cdk/portal';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FeatureService, GenericModalComponent, ModalEvent, ModalOverlayService } from '@woolworthsnz/styleguide';
import { Observable, Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { HOW_WHERE_WHEN_PATH } from '../shop.routes';
import { ContextResponse } from '@woolworthsnz/trader-api';

export interface ModalOptions {
	title: any;
	description: any;
	buttonText: any;
	cancelButtonText?: string;
	hasCancelButton?: boolean;
	fitContent?: boolean;
	closeAction?: Function;
	isNewTextStyle?: boolean;
	isButtonPrimary?: boolean;
}

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {
	routerEventsSubscription = Subscription.EMPTY;

	constructor(
		private featureService: FeatureService,
		private modalOverlayService: ModalOverlayService,
		private router: Router
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap((event: HttpEvent<any>) => {
				if (!(event instanceof HttpResponse) || !event.body) {
					return;
				}

				this.alertIfTrolleyNeedsReview(event.body);

				this.alertIfLiquorItemsCannotBeDelivered(event.body);
			})
		);
	}

	alertIfTrolleyNeedsReview = (r: any): void => {
		if (r.trolleyNeedsReview) {
			this.openModalOnNextMajorRouteChange({
				title: 'Please check your trolley',
				description: 'Some items in your trolley are not available for this location and time slot.',
				buttonText: 'Review items',
				isNewTextStyle: true,
				isButtonPrimary: true,
			});
		}
	};

	alertIfLiquorItemsCannotBeDelivered = (r: any): void => {
		if (r.liquorCannotBeDelivered) {
			if (r.isExpressDelivery) {
				this.openModalOnNextMajorRouteChange({
					title: `We're sorry, restricted items are not available via ${this.featureService.state.enabledFeatures.includes(ContextResponse.EnabledFeaturesEnum.EnableRebrandToDeliveryNow) ? 'Delivery Now' : 'Express Delivery'}.`,
					description:
						'To proceed, please remove these items from your trolley, or choose Standard Delivery or Express Pick up.',
					buttonText: 'Review and remove items',
					cancelButtonText: 'Get my order via another method',
					hasCancelButton: true,
					fitContent: true,
					closeAction: this.closeModalAndNavigateToHWWPage,
				});
			} else {
				this.openModalOnNextMajorRouteChange({
					title: 'Liquor items cannot be delivered within the selected delivery period',
					// TODO: Update once the API sends back some useful messages
					description: '', // 'Please choose a delivery time between 8:00 a.m and 2:00 p.m.',
					buttonText: 'Review Trolley',
				});
			}
		}
	};

	closeModalAndNavigateToReviewPage = (): void => {
		this.modalOverlayService.close();
		this.router.navigateByUrl('/reviewtrolley');
	};

	closeModalAndNavigateToHWWPage = (): void => {
		this.modalOverlayService.close();
		this.router.navigateByUrl(`/${HOW_WHERE_WHEN_PATH}`);
	};

	openModalOnNextMajorRouteChange = (modalOptions: ModalOptions): void => {
		const currentUrl = this.router.url;
		this.routerEventsSubscription.unsubscribe();
		this.routerEventsSubscription = this.router.events
			.pipe(
				filter((routerEvent) => routerEvent instanceof NavigationEnd),
				filter((routerEvent) => !(<NavigationEnd>routerEvent).url.match(/\(modal:/)),
				filter((routerEvent) => (<NavigationEnd>routerEvent).url !== currentUrl),
				take(1)
			)
			.subscribe(() => {
				this.openModal(modalOptions);
			});
	};

	openModal = ({
		title,
		description,
		buttonText,
		hasCancelButton,
		cancelButtonText,
		fitContent,
		closeAction,
		isNewTextStyle,
		isButtonPrimary,
	}: ModalOptions): void => {
		this.modalOverlayService.open({
			eventType: ModalEvent.reviewTrolley,
			templateRef: new ComponentPortal(
				GenericModalComponent,
				null,
				this.modalOverlayService.createInjector({
					title,
					description,
					icon: 'alert',
					iconFill: 'warning',
					ctaAction: this.closeModalAndNavigateToReviewPage,
					buttonText,
					cancelButtonText,
					hasCancelButton,
					fitContent,
					closeAction,
					isNewTextStyle,
					isButtonPrimary,
				})
			),
		});
	};
}
