<div class="modal-backdrop overlay--opaque" (click)="onClose()"></div>
<div class="modal-contentContainer" [ngClass]="'modal--' + modalStyle">
	<cdx-card
		cdkTrapFocus
		[cdkTrapFocusAutoCapture]="canDismiss"
		[center]="center"
		[padding]="padding"
		[attr.data-size]="size"
		[attr.data-mobile-full-height]="mobileFullHeight"
		[attr.data-mobile-browser]="(isMobile$ | async) && !isPC && !isEmbeddedInApp"
		roundedCorners="medium"
	>
		<button cdkFocusInitial aria-label="Dismiss modal" class="modal-closeBtn" *ngIf="canDismiss" (click)="onClose()">
			<cdx-svg-icon name="cross" size="small" [fill]="modalStyle === 'dark-green' ? 'light' : 'dark'"></cdx-svg-icon>
		</button>
		<div
			#content
			class="modal-slotContainer"
			[ngClass]="{
				'modal-slotContainer--withPaddingMedium': verticalPadding === 'medium',
				'modal-slotContainer--withPaddingNone': verticalPadding === 'none',
				'modal-slotContainer--withPaddingDefault': verticalPadding === 'default'
			}"
		>
			<div class="modal-headerSlot" [ngClass]="{ 'modal-headerSlot--row': showBack }">
				<button
					cdxButton
					*ngIf="showBack"
					i18n="@@modal-backBtn"
					fillStyle="link"
					(click)="back.emit()"
					aria-label="Go back"
				>
					<cdx-svg-icon name="chevron-left" size="small" fill="dark"></cdx-svg-icon>
				</button>
				<ng-content select="[modal-header]"></ng-content>
			</div>
			<div class="modal-contentSlot">
				<ng-content select="[modal-content]"></ng-content>
			</div>
			<div class="modal-footerSlot" [ngClass]="footerOverrideClass">
				<ng-content select="[modal-footer-primary]"></ng-content>
				<ng-content select="[modal-footer-secondary]"></ng-content>
			</div>
		</div>
	</cdx-card>
</div>
