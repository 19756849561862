<div [@slideInOut] [@.disabled]="!enableAnimation">
	<cdx-svg-icon
		[name]="lastOrderFulfilment.method === 'Pickup' ? 'pick-up' : 'truck-delivery'"
		fill="green"
		size="default"
	></cdx-svg-icon>
	<div>
		<p *ngIf="lastOrderFulfilment.method === 'Pickup'" i18n="@@editOrder-pickup-confirmed">
			Pick up order confirmed
		</p>
		<p *ngIf="lastOrderFulfilment.method === 'Courier'" i18n="@@editOrder-pickup-confirmed">
			Delivery order confirmed
		</p>
		<p class="address-limiter">{{ lastOrderFulfilment.address }}</p>
		<p>{{ lastOrderFulfilment.deliveryTimeSpan }}</p>
	</div>
	<a
		[href]="editOrderUrl"
		class="edit-order"
		[tabIndex]="0"
		maybeExternal
		[maybeExternalCurrentTab]="true"
		(click)="handleEditOrderClick()"
		i18n="@@editOrder-notification-button"
	>
		Edit order
	</a>
</div>
