<div *ngIf="list?.length > 0">
  <button
    class="prev"
    type="button"
    (click)="handlePrev()"
    [ngClass]="{ 'disabled': currentItem.index === 0 }"
    [disabled]="currentItem.index === 0"
    i18n-aria-label="@@tickerPrevBtn"
    aria-label="Previous"
  >
    <cdx-svg-icon name="chevron-left" size="small" [fill]="currentItem.index === 0 ? 'light-gray' : 'light'"></cdx-svg-icon>
  </button>

  <div class="ticker--text">
    <h4 class="heading--4">
      {{ currentItem.text }}
    </h4>
  </div>

  <button
    class="next"
    type="button"
    (click)="handleNext()"
    [ngClass]="{ 'disabled': currentItem.index === list.length }"
    [disabled]="currentItem.index === list.length - 1"
    i18n-aria-label="@@tickerNextBtn"
    aria-label="Next"
  >
    <cdx-svg-icon name="chevron-right" size="small" [fill]="currentItem.index === list.length - 1 ? 'light-gray' : 'light'"></cdx-svg-icon>
  </button>
</div>