import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const defaultColumnCount = 4;

@Injectable()
export class Carousel2Service {
	public $columns = new BehaviorSubject<number>(defaultColumnCount);
	public isAutoRotating = false;
}
