import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IconFill, IconSize, SvgIconComponent } from '../svg-icon/svg-icon.component';
import { NgIf } from '@angular/common';

@Component({
	selector: 'cdx-icon-circle',
	templateUrl: './icon-circle.component.html',
	styleUrls: ['./icon-circle.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, SvgIconComponent]
})
export class IconCircleComponent {
	@Input() name?: string;
	@Input() iconSize?: IconSize;

	@HostBinding('style.borderWidth')
	@Input()
	borderWidth?: string = '1px';

	@HostBinding('attr.borderColor') @Input() borderColor?: IconFill;
	@HostBinding('attr.iconFill') @Input() iconFill?: IconFill;
	@HostBinding('attr.background') @Input() background?: IconFill;
	@HostBinding('attr.size') @Input() size?: IconSize;
	@HostBinding('attr.display') @Input() display: 'inline' | 'block' = 'block';
}
