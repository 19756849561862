import { Injectable } from '@angular/core';
import {
	ApiService,
	AppSettingsService,
	ShopperService,
	ShopperState,
	StatefulService,
} from '@woolworthsnz/styleguide';
import { AddOrUpdateAddressResponse, Address, ShopperAddress } from '@woolworthsnz/trader-api';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

export class AddressState {
	addresses: ShopperAddress[];
	error?: any;
	completed?: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class ManageAddressService extends StatefulService<AddressState> {
	get addressEndpoint(): string {
		return this.appSettingsService.getEndpoint('address');
	}

	get endpoint(): string {
		return `${this.addressEndpoint}/my/items`;
	}

	constructor(
		private appSettingsService: AppSettingsService,
		private apiService: ApiService,
		private shopperService: ShopperService
	) {
		super(new AddressState());
	}

	addAddress = (body: any): Observable<any> =>
		this.shopperService.state$.pipe(
			take(1),
			switchMap((state: ShopperState) => {
				if (state.isShopper) {
					return this.apiService.post(this.endpoint, body);
				}

				return EMPTY;
			}),
			catchError((error) => {
				this.setState({
					error,
					completed: false,
				});
				return throwError(error);
			})
		);

	buildAddress(address: Address = {}): string[] {
		if (!address) {
			return [];
		}

		const { street1, street2, postcode } = address;

		const splitAtComma = (i: string): string[] => (i || '').split(',').map((j) => j.trim());

		const formatted = [...splitAtComma(street1 || ''), ...splitAtComma(street2 || ''), postcode || ''].filter(Boolean);

		return formatted;
	}

	/**
	 * Runs immediately to update the addresses in `state$`. <br/>
	 *
	 * This can be helpful when subscribing to `state$` to ensure you start with the latest addresses:
	 * ```ts
	 * this.manageAddressService.state$.subscribe(...);
	 * this.manageAddressService.getAddresses();
	 * ```
	 * or with an async pipe on `state$`.
	 */
	getAddresses = (): void => {
		if (!this.shopperService.isShopper) {
			return;
		}
		this.apiService
			.get(this.endpoint, {})
			.pipe(
				map(this.mapAddresses),
				catchError((error) => {
					this.setState({
						error,
						completed: false,
					});
					return throwError(error);
				}),
				take(1)
			)
			.subscribe((addresses) => {
				this.setState({
					addresses,
				});
			});
	};

	updateAddress = (body: any): Observable<any> =>
		this.shopperService.state$.pipe(
			take(1),
			switchMap((state: ShopperState) => {
				if (state.isShopper) {
					return this.apiService.put(this.endpoint, body);
				}

				return EMPTY;
			}),
			catchError((error) => {
				this.setState({
					error,
					completed: false,
				});
				return throwError(error);
			})
		);

	deleteAddress = (guid: string): void => {
		this.shopperService.state$
			.pipe(
				take(1),
				switchMap((state: ShopperState) => {
					if (state.isShopper) {
						return this.apiService.delete(`${this.addressEndpoint}/my/${guid}`);
					}

					return EMPTY;
				}),
				catchError((error) => {
					this.setState({
						error,
						completed: false,
					});
					return throwError(error);
				})
			)
			.subscribe(this.updateShopperAddresses);
	};

	mapAddresses = (response: AddOrUpdateAddressResponse): ShopperAddress[] => {
		const addresses: ShopperAddress[] = response.shopperAddresses?.filter(Boolean) || [];
		return this.setPrimaryAddressToFirst(addresses);
	};

	updateShopperAddresses = (response: any): void => {
		this.setState({
			addresses: this.mapAddresses(response),
		});
	};

	setPrimaryAddressToFirst = (addresses: ShopperAddress[]): ShopperAddress[] =>
		addresses.reduce((prev: ShopperAddress[], a: ShopperAddress) => {
			if (a.isPrimary) {
				prev.unshift(a);
			} else {
				prev.push(a);
			}

			return prev;
		}, []);
}
