import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent } from '../../../4_atoms/components/button/button.component';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'cdx-not-found-error',
	template: `
		<picture>
			<source srcset="/assets/images/404/icecream-900.png" media="(min-width: 900px)" />
			<img alt="Page Not Found" src="/assets/images/404/icecream-450.png" role="presentation" />
		</picture>
		<h1 i18n="@@notFound-title">Oops! That didn't go to plan</h1>
		<p i18n="@@notFound-message">404 page not found.</p>
		<a routerLink="/" cdxButton i18n="@@notFound-backToHome">Back to Homepage</a>
	`,
	styleUrls: ['./not-found-error.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterLink, ButtonComponent]
})
export class NotFoundErrorComponent { }
