import { Action, createReducer, on } from '@ngrx/store';
import * as EDRActions from './everyday-rewards.actions';
import { EdrBalanceResponseV2, RewardMessage } from '@woolworthsnz/trader-api';

export const EVERYDAY_REWARDS_FEATURE_KEY = 'everyday_rewards';

export interface EverydayRewardsState {
	edrBalance?: EdrBalanceResponseV2;
	fetchingEdrBalance: boolean;
	fetchedEdrBalance: boolean;
	errorEdrBalance?: unknown;
	boostOffersCount?: number;
	fetchingBoostOffersCount: boolean;
	fetchedBoostOffersCount: boolean;
	errorBoostOffers?: unknown;
	fetchingRewardMessages: boolean;
	fetchedRewardMessages: boolean;
	rewardMessages: RewardMessage[];
	deletingRewardMessage: boolean;
	deletedRewardMessage: boolean;
	errorRewardMessages?: unknown;
}

export const everydayRewardsInitialState: EverydayRewardsState = {
	edrBalance: undefined,
	fetchingEdrBalance: false,
	fetchedEdrBalance: false,
	boostOffersCount: undefined,
	fetchingBoostOffersCount: false,
	fetchedBoostOffersCount: false,
	fetchingRewardMessages: false,
	fetchedRewardMessages: false,
	rewardMessages: [],
	deletingRewardMessage: false,
	deletedRewardMessage: false,
	errorBoostOffers: undefined,
};

const reducer = createReducer(
	everydayRewardsInitialState,
	on(
		EDRActions.fetchEdrBalance,
		(state): EverydayRewardsState => ({
			...state,
			fetchingEdrBalance: true,
			fetchedEdrBalance: false,
		})
	),
	on(
		EDRActions.fetchEdrBalanceSuccess,
		(state, { edrBalanceResponse }): EverydayRewardsState => ({
			...state,
			edrBalance: edrBalanceResponse,
			fetchingEdrBalance: false,
			fetchedEdrBalance: true,
			errorEdrBalance: undefined,
		})
	),
	on(
		EDRActions.fetchEdrBalanceFailed,
		(state, { error }): EverydayRewardsState => ({
			...state,
			fetchingEdrBalance: false,
			errorEdrBalance: error,
		})
	),
	on(
		EDRActions.fetchBoostOffersCount,
		(state): EverydayRewardsState => ({
			...state,
			fetchingBoostOffersCount: true,
			fetchedBoostOffersCount: false,
			errorBoostOffers: undefined,
		})
	),
	on(
		EDRActions.fetchBoostOffersCountSuccess,
		(state, { boostOffersCount }): EverydayRewardsState => ({
			...state,
			boostOffersCount,
			fetchingBoostOffersCount: false,
			fetchedBoostOffersCount: true,
			errorBoostOffers: undefined,
		})
	),
	on(
		EDRActions.fetchBoostOffersCountFailed,
		(state, { error }): EverydayRewardsState => ({
			...state,
			fetchingBoostOffersCount: false,
			errorBoostOffers: error,
		})
	),
	on(
		EDRActions.fetchRewardMessages,
		(state): EverydayRewardsState => ({
			...state,
			fetchingRewardMessages: true,
			fetchedRewardMessages: false,
			errorRewardMessages: undefined,
		})
	),
	on(
		EDRActions.fetchRewardMessagesSuccess,
		(state, { rewardMessages }): EverydayRewardsState => ({
			...state,
			errorRewardMessages: undefined,
			fetchingRewardMessages: false,
			fetchedRewardMessages: true,
			rewardMessages,
		})
	),
	// Fetch reward messages failed
	on(
		EDRActions.fetchRewardMessagesFailed,
		(state, { error }): EverydayRewardsState => ({
			...state,
			fetchingRewardMessages: false,
			errorRewardMessages: error,
		})
	),
	on(
		EDRActions.deleteRewardMessage,
		(state): EverydayRewardsState => ({
			...state,
			deletingRewardMessage: true,
			deletedRewardMessage: false,
			errorRewardMessages: undefined,
		})
	),
	on(EDRActions.deleteRewardMessageSuccess, (state): EverydayRewardsState => {
		// Remove reward message from the store
		const rewardMessages = state.rewardMessages.filter(
			(message) => message.messageId !== state.rewardMessages[0].messageId
		);

		return {
			...state,
			rewardMessages,
			deletingRewardMessage: false,
			deletedRewardMessage: true,
		};
	}),
	on(
		EDRActions.deleteRewardMessageFailed,
		(state, { error }): EverydayRewardsState => ({
			...state,
			deletingRewardMessage: false,
			errorRewardMessages: error,
		})
	)
);

export function everydayRewardsReducer(state: EverydayRewardsState, action: Action): EverydayRewardsState {
	return reducer(state, action);
}
