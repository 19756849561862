import { ShopperState } from '@woolworthsnz/styleguide';
import equal from 'fast-deep-equal';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * Exposes the option to listen to the shopper state
 * to see if the isLoggedIn and isShopper state is changed.
 */
export const distinctUntilShopperLoggedInStateChanged =
	() =>
	(source: Observable<ShopperState>): Observable<ShopperState> =>
		source.pipe(
			distinctUntilChanged((a, b) => {
				const mapShopperState = (
					input: ShopperState
				): {
					isShopper: boolean | undefined;
					isLoggedIn: boolean | undefined;
				} => ({
					isShopper: input.isShopper,
					isLoggedIn: input.isLoggedIn,
				});

				return equal(mapShopperState(a), mapShopperState(b));
			})
		);
