import { ChangeDetectionStrategy, Component, HostBinding, Input, SkipSelf } from '@angular/core';
import {
	ImpressionTrackingBehaviourType,
	ProductImpressionArgs,
	PromoImpressionArgs,
	RecipeImpressionArgs,
} from '../../../ui-models';
import { ContainerDisplayType } from '../carousel2/carousel2.component';
import { Carousel2Service } from '../carousel2/carousel2.service';
import { TrackImpressionDirective } from '../../../directives/track-impression.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'cdx-carousel2-item',
	templateUrl: './carousel2-item.component.html',
	styleUrls: ['./carousel2-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [TrackImpressionDirective],
})
export class Carousel2ItemComponent {
	@HostBinding('attr.data-display-type')
	@Input()
	displayType: ContainerDisplayType = ContainerDisplayType.dynamic;

	/**
	 * If you want to/need to provide a fallback way of tracking for users that browsers that don't support the intersectionObserver
	 * you can take a look at the trackImpressions method in the Dynamic Content Component.
	 */
	@Input() impressionTrackingEnabled = false;

	/**
	 * Provide impressionArgs if you have set impressionTrackingBehaviour to something other than Disabled
	 */
	@Input() impressionArgs: PromoImpressionArgs | ProductImpressionArgs | RecipeImpressionArgs | undefined;

	/**
	 * Used to calculate the width of carousel items when displayType is set to ContainerDisplayType.dynamic
	 */
	@HostBinding('attr.data-columns') public columns = 4;

	public get impressionTrackingBehaviour(): ImpressionTrackingBehaviourType {
		if (!this.impressionTrackingEnabled) {
			return ImpressionTrackingBehaviourType.Disabled;
		}
		return this.carousel2Service.isAutoRotating
			? ImpressionTrackingBehaviourType.TrackOnEachIntersection
			: ImpressionTrackingBehaviourType.TrackOnFirstIntersectionOnly;
	}

	constructor(@SkipSelf() private carousel2Service: Carousel2Service) {
		this.carousel2Service.$columns.pipe(takeUntilDestroyed()).subscribe((columns) => (this.columns = columns));
	}
}
