import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ShopUIMainModule } from './app/shop-ui-main.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
	/* eslint-disable no-console */
	platformBrowserDynamic()
		.bootstrapModule(ShopUIMainModule)
		.catch((err) => console.error(err));
	/* eslint-enable no-console */
});

// Unstable interfaces only for chrome / android
interface BeforeInstallPromptEvent extends Event {
	readonly platforms: string[];
	readonly userChoice: Promise<{
		outcome: 'accepted' | 'dismissed';
		platform: string;
	}>;
	prompt(): Promise<void>;
}

declare global {
	interface WindowEventMap {
		beforeinstallprompt: BeforeInstallPromptEvent;
	}
}

if (window.matchMedia('(max-width: 767px)').matches) {
	let deferredPrompt: any;
	// Turns out chrome desktop will prompt for a PWA app install same as Android, but significantly less useful to the end user! So we'll just stick to responding to the prompt if the device is mobile.
	const beforeInstallPrompt = (e: any): void => {
		// Prevent Chrome 67 and earlier from automatically showing the prompt
		e.preventDefault();
		// for now just show the prompt on the next user interaction - but could stash it for use later (eg checking if they are in checkout flow and defer until checkout complete etc)
		deferredPrompt = e;
		const nextClick = (): void => {
			window.document?.body.removeEventListener('click', nextClick);
			window.removeEventListener('beforeinstallprompt', beforeInstallPrompt);
			deferredPrompt.prompt();
		};
		window.document?.body.addEventListener('click', nextClick);
	};

	window.addEventListener('beforeinstallprompt', beforeInstallPrompt);
	window.addEventListener('appinstalled', () => {
		deferredPrompt = null;
	});
} else {
	window.addEventListener('beforeinstallprompt', (e) => {
		// Prevents prompt display
		e.preventDefault();
	});
}
