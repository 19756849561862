import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PopupService {
	private showPopup = new Subject();
	private closePopup = new Subject();

	get showPopup$(): Observable<string> {
		return this.showPopup.asObservable() as Observable<string>;
	}

	get closePopup$(): Observable<string> {
		return this.closePopup.asObservable() as Observable<string>;
	}

	open = (id: string): void => {
		this.showPopup.next(id);
	};

	close = (id: string): void => {
		this.closePopup.next(id);
	};
}
