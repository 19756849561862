import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppSettingsService, CustomWindow, FeatureService, WINDOW } from '../services';

@Injectable({
	providedIn: 'root',
})
export class FeatureGuard {
	constructor(
		@Inject(WINDOW) protected window: CustomWindow,
		protected featureService: FeatureService,
		protected appSettingsService: AppSettingsService,
		protected router: Router
	) {}

	canLoad(route: Route): Observable<boolean> {
		return this.featureService.isEnabled(route.data?.feature);
	}

	canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
		return this.featureService.isEnabled(next.data?.feature);
	}
}
