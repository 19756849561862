import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { ENTER } from '@angular/cdk/keycodes';
import {
	AfterViewInit,
	Directive,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	OnDestroy,
	Output,
	QueryList,
	ViewChildren,
} from '@angular/core';
import { OptionListItemComponent } from '../option-list-item/option-list-item.component';
import { Subject, takeUntil } from 'rxjs';

@Directive()
export abstract class OptionListBaseComponent implements AfterViewInit, OnDestroy {
	@ViewChildren(OptionListItemComponent) items: QueryList<OptionListItemComponent>;
	@Output() optionSelect: EventEmitter<any> = new EventEmitter();
	@Output() optionFocus: EventEmitter<any> = new EventEmitter();
	@Input() idPrefix: string;

	@HostBinding('attr.role') role = 'listbox';

	protected keyManager: ActiveDescendantKeyManager<OptionListItemComponent>;
	private destroyed$: Subject<boolean> = new Subject();

	@HostListener('document:keydown', ['$event'])
	onKeydown(event: KeyboardEvent): void {
		if (event.keyCode === ENTER && this.keyManager.activeItem) {
			const activeItemIndex = this.keyManager.activeItemIndex;
			const activeItem = activeItemIndex !== null ? this.getItem(activeItemIndex) : null;
			if (!activeItem) {
				return;
			}
			(document.activeElement as HTMLElement)?.blur();
			this.optionSelect.emit(activeItem);
		} else {
			this.keyManager.onKeydown(event);
		}
	}

	ngAfterViewInit(): void {
		this.keyManager = new ActiveDescendantKeyManager(this.items).withWrap();
		this.keyManager.change.pipe(takeUntil(this.destroyed$)).subscribe((i) => this.optionFocus.emit(i));
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onClick(index: number): void {
		this.optionSelect.emit(this.getItem(index));
	}

	getItemId(index: number): string {
		return `${this.idPrefix}${index}`;
	}

	abstract getItem(index: number): any;
}
