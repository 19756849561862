import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, isDevMode } from '@angular/core';
import { AnalyticsCollectionBuffer, AnalyticsCollectionType } from '../collect';
import { sanitize } from '../helpers/analytics.helper';
import { Subject } from 'rxjs';
import { ViewItemData, ProductImpressionData, ViewItemListImpressionData } from '../models/product-impression';
import { ProductResponse } from '@woolworthsnz/trader-api';

declare global {
	interface Window {
		dataLayer: any[];
	}
}

const isProductAvailable = (product?: ProductResponse): boolean =>
	!!product?.price?.salePrice && !!product?.quantity?.max;

@Injectable({
	providedIn: 'root',
})
export class TealiumUtagService {
	static collectionBuffers = {} as Record<AnalyticsCollectionType, AnalyticsCollectionBuffer>;
	static scriptSrc = '';
	// data that will be passed with every tracking event
	private viewItemBatches = new Map<string, ViewItemData[]>();
	private viewItemBatchSubject = new Subject<void>();
	private noCarouselName = '**NONE**';
	private sharedAnalyticsData = {};
	enableDataLayerLogging = false;

	constructor(@Inject(PLATFORM_ID) private platform: any) {
		if (isPlatformServer(this.platform)) {
			return;
		}

		// Set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
		(window as any).utag_cfg_ovrd = { noview: true };
		(window as any).utag_data = {};
	}

	// The below code should be kept as is or updated from:
	// https://docs.tealium.com/platforms/angular/install/
	/* eslint-disable */

	// Generic script loader with callback
	getScript(src: string, callback: () => void) {
		const d = document;
		const fn = () => {};
		const o = { callback: callback || fn };
		let s: any;
		let t: any;

		if (typeof src === 'undefined') {
			return;
		}

		s = d.createElement('script');
		s.language = 'javascript';
		s.type = 'text/javascript';
		s.async = 1;
		s.charset = 'utf-8';
		s.src = src;
		if (typeof o.callback === 'function') {
			if (d.addEventListener) {
				d.addEventListener(
					'load',
					() => {
						o.callback();
					},
					false
				);
				s.addEventListener(
					'load',
					() => {
						o.callback();
					},
					false
				);
			} else {
				// old IE support
				s.onreadystatechange = function () {
					if (this.readyState === 'complete' || this.readyState === 'loaded') {
						this.onreadystatechange = null;
						o.callback();
					}
				};
			}
		}
		t = d.getElementsByTagName('script')[0];
		t.parentNode.insertBefore(s, t);
	}

	// Config settings used to build the path to the utag.js file
	setConfig(config: { account: string; profile: string; environment: string }) {
		if (config.account !== undefined && config.profile !== undefined && config.environment !== undefined) {
			TealiumUtagService.scriptSrc = `https://tags.tiqcdn.com/utag/${config.account}/${config.profile}/${config.environment}/utag.js`;
		}
	}

	// Data layer is optional set of key/value pairs
	track(tealiumEvent: string, data?: any) {
		if (TealiumUtagService.scriptSrc === '') {
			if ((window as any)?.BOOTSTRAP_DATA?.Tealium) {
				this.setConfig((window as any).BOOTSTRAP_DATA.Tealium);
			} else {
				return;
			}
		}
		const trackData = { ...data, ...this.sharedAnalyticsData };
		if ((window as any).utag === undefined) {
			this.getScript(TealiumUtagService.scriptSrc, () => {
				(window as any).utag.track(tealiumEvent, trackData);
			});
		} else {
			(window as any).utag.track(tealiumEvent, trackData);
		}
	}

	view(data?: any, copyToLegacyDataLayer: boolean = false) {
		this.track('view', data);
		if (copyToLegacyDataLayer) {
			this.logToLegacyDataLayer(data);
		}
	}

	link(data?: any, copyToLegacyDataLayer: boolean = false) {
		this.track('link', sanitize(data));
		if (copyToLegacyDataLayer) {
			this.logToLegacyDataLayer(data);
		}
	}

	private logToLegacyDataLayer(data?: any) {
		if (isPlatformBrowser(this.platform) && window['dataLayer']) {
			window['dataLayer'].push(data);
		}
	}

	// Debounces events of a given type into a single call to 'link'
	collect(type: AnalyticsCollectionType, data?: any) {
		if (isPlatformServer(this.platform)) {
			return;
		}
		if (!(type in TealiumUtagService.collectionBuffers)) {
			TealiumUtagService.collectionBuffers[type] = new AnalyticsCollectionBuffer(this.link.bind(this));
		}

		TealiumUtagService.collectionBuffers[type].push(data);
	}

	collectViewItems(type: AnalyticsCollectionType, data?: any) {
		if (isPlatformServer(this.platform)) {
			return;
		}
		if (!(type in TealiumUtagService.collectionBuffers)) {
			TealiumUtagService.collectionBuffers[type] = new AnalyticsCollectionBuffer(this.link.bind(this));
		}
		TealiumUtagService.collectionBuffers[type].pushItemList(data);
	}

	setSharedData(data: {} = {}) {
		this.sharedAnalyticsData = { ...this.sharedAnalyticsData, ...data };
	}

	private mapItemToDatalayerEvent = (products: ProductResponse[] | ViewItemListImpressionData[]): any => {
		let ar: any = [];

		products.map((p, i) => {
			if (p.type === 'Product') {
				const availabilityStatus = isProductAvailable(p) ? p.availabilityStatus ?? 'In Stock' : 'Unavailable';

				ar = [
					...ar,
					{
						id: p.sku,
						name: p.name,
						position: (<ProductImpressionData>p).position ?? i + 1,
						promotionProductTags: p.productTag?.tagType ?? '',
						discout: p.price?.discount,
						itemBrand: p.brand ?? '',
						itemCategory: p.departments?.[0]?.name ?? '',
						itemCategory2: p.departments?.[1]?.name ?? '',
						itemCategory3: p.departments?.[2]?.name ?? '',
						itemListId: '',
						itemListName: p.brand === 'online sample' ? 'Online Sample' : '',
						itemVariant: p.variety ?? '',
						price: p.price ? p.price.salePrice : null,
						hasShopperNotes: p.hasShopperNotes,
						quantity: p.quantity?.value,
						eachKg: p.unit ?? '',
						availabilityStatus,
					},
				];
			}
		});
		return ar;
	};

	trackViewItemList(products: ProductResponse[] | ProductImpressionData[]): void {
		if (!Array.isArray(products)) {
			return;
		}

		const productDatalayerEvent = this.mapItemToDatalayerEvent(products);

		if (isDevMode() && console.table && this.enableDataLayerLogging) {
			console.info('[DatalayerService]: trackViewItemList called');
			console.table(productDatalayerEvent);
		}

		this.link(
			{
				event: 'viewItemList',
				ecommerce: {
					currencyCode: 'NZD',
					impressions: [...productDatalayerEvent],
				},
			},
			true
		);
	}

	/**
	 * This will add a viewItemList to an existing batch of viewItemList.
	 * It also triggers the observer event
	 * @param viewItems
	 * @param carouselName
	 */
	addViewItemsToBatch(viewItems: ViewItemData[], carouselName?: string): void {
		const key = carouselName || this.noCarouselName;

		if (this.viewItemBatches.has(key)) {
			const batchedProducts = this.viewItemBatches.get(key) ?? [];
			this.viewItemBatches.set(key, [...batchedProducts, ...viewItems]);
		} else {
			this.viewItemBatches.set(key, [...viewItems]);
		}

		// Trigger batch processing
		this.viewItemBatchSubject.next();
	}
	/* eslint-enable */
}
