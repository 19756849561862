// There are situations where a user can have a reduced range.
// This enum is an alert for that

export enum ReducedRangeAlertType {
	// The customer's current address is outside of any delivery zone
	// They probably live halfway up a mountain
	OutOfZone = 'outOfZone',

	// The customer's current address is in a non perishable area
	// We only show them canned goods
	NonPerishable = 'nonPerishable',

	// The customer's current address is in a limited perishable area
	// We don't show them things which can perish relatively quickly
	LimitedPerishable = 'limitedPerishable',
}
