/* eslint-disable @typescript-eslint/naming-convention */
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class NavigationFactoryService {
	constructor(@Inject(PLATFORM_ID) private platformId: object, private injector: Injector) {}

	/**
	/* NOTE: when defining new custom elements name the tag differently to the one in angular to avoid weird side effects
	/* Suffixing `-element` is probably safe
	/* E.g. angular component <cdx-olive-roundel> vs custom element <olive-element>	 *
	 * @param injector
	 * @param isBrowser
	 */
	static async CreateCustomElements(injector: Injector, isBrowser: boolean): Promise<void> {
		if (isBrowser) {
			const { createCustomElement } = require('@angular/elements');

			if (!customElements.get('global-nav') && !customElements.get('global-nav-footer')) {
				const { NavComponent, FooterComponent } = await import(
					/* webpackChunkName: "global-nav-lib"*/
					'@woolworthsnz/global-nav'
				);
				customElements.define(
					'global-nav',
					createCustomElement(NavComponent, {
						injector,
					})
				);

				customElements.define(
					'global-nav-footer',
					createCustomElement(FooterComponent, {
						injector,
					})
				);
			}
		}
	}

	bootstrapNavElements(): Promise<void> {
		return NavigationFactoryService.CreateCustomElements(this.injector, isPlatformBrowser(this.platformId));
	}
}
