import { isPlatformServer } from '@angular/common';
import { CustomWindow } from '../services';

export function isIEOrEdge(window?: CustomWindow): any {
	return window?.navigator?.userAgent?.match(new RegExp('msie|trident|edge', 'i'));
}

export const removeNextUIFromURLString = (url: string, router: any): string => {
	if (url.indexOf('https://') > -1) {
		// eslint-disable-next-line no-console
		console.error('Dont pass a full URL. Just the pathname');
	}

	const parsedUrl = router.parseUrl(url);

	if (parsedUrl.queryParams.nextUI) {
		delete parsedUrl.queryParams.nextUI;
	}

	return router.serializeUrl(parsedUrl);
};

export function noIntersectionObserver(platformId: object, window: CustomWindow): boolean {
	return (
		isPlatformServer(platformId) ||
		!('IntersectionObserver' in window) ||
		!('IntersectionObserverEntry' in window) ||
		!('intersectionRatio' in window.IntersectionObserverEntry?.prototype)
	);
}
