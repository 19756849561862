import { Inject, Injectable } from '@angular/core';
import { ActivationStart, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { CustomWindow, WINDOW } from '../window.service';

@Injectable({
	providedIn: 'root',
})
export class OliveService {
	private currentUrl: string;
	constructor(private router: Router, @Inject(WINDOW) private window: CustomWindow) {
		this.handleRouteChange();
	}
	private $isVisible = new BehaviorSubject<boolean>(true);

	get isVisible$(): Observable<boolean> {
		return this.isVisible();
	}

	private isAuxiliaryRoute(url: string): boolean {
		return !!url.match(/\(/);
	}

	private handleRouteChange(): void {
		this.router.events.pipe(takeWhile((e) => !!e)).subscribe((event): void => {
			if (event instanceof NavigationStart) {
				if (this.isAuxiliaryRoute(event.url) || event.url === this.currentUrl) {
					return;
				}

				this.updateVisibility(true);
				this.currentUrl = event.url;
			}

			if (event instanceof ActivationStart && event.snapshot.data.disableOlive) {
				this.updateVisibility(false);
			}
		});
	}

	public updateVisibility(isVisible: boolean): void {
		this.$isVisible.next(isVisible);

		if (!isVisible && this.window.chatWidget && this.window.chatWidget.closeChat) {
			this.window.chatWidget.closeChat();
		}
	}

	public isVisible(): Observable<boolean> {
		return this.$isVisible.asObservable().pipe(distinctUntilChanged());
	}
}
