import { Directive, HostListener, Input } from '@angular/core';
import { DatalayerService, TrackingEvent } from '../services';
import { NotificationType } from '../ui-models';

export interface TrackingData {
	event?: TrackingEvent;
	type: NotificationType;
	name: string;
	value?: string | number;
}

/**
 * @description Apply this to anything whose click should be tracked in the datalayer. Arguments can be passed in the template as an object whose shape matches the TrackingData interface: e.g:
 * <a [cdxTrackEvent]="{event:TrackingEvent.NotificationEvent,type:NotificationType.Link, name:'the name of the thing to track'}"></a>
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[cdxTrackEvent]',
	standalone: true,
})
export class TrackEventDirective {
	private args: TrackingData | undefined;

	@Input() set cdxTrackEvent(val: TrackingData) {
		this.args = val;
	}

	constructor(private dataLayerService: DatalayerService) {}

	@HostListener('click')
	handleClick(): void {
		if (this.args?.event) {
			this.dataLayerService.trackEvent(this.args?.event, this.args?.type, this.args?.name, this.args?.value);
		}
	}
}
