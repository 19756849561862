export const sentenceCase = (pathName: string): string => pathName.charAt(0).toUpperCase() + pathName.substr(1);

export const kebabCase = (str: string): string =>
	str &&
	str
		.replace(/[^a-zA-Z-\d\s]/g, '')
		.replace(/(,\s+)|(\s+&\s+)|\s+/g, '-')
		.replace(/^-|-$/, '')
		.toLowerCase();

export const camelCase = (str: string): string =>
	// eslint-disable-next-line @typescript-eslint/naming-convention
	str.replace(/([-_][a-z])/gi, ($1: string) => $1.toUpperCase().replace('-', '').replace('_', ''));

export const pascalCase = (str: string): string =>
	camelCase(str)
		.split(' ')
		.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
		.join(' ');

export const queryParamsStringToObject = (str: string): string =>
	JSON.parse('{"' + decodeURI(str).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

/**
 * Replaces whitespace not preceded by a comma with a non-breaking space and replaces comma's not followed by whitespace with a comma followed a space
 * This means the sentence will wrap (via default css) on the spaces after commas if possible.
 *
 * @param inString
 */
export function convertSentenceToBreakOnCommas(inString: string): string {
	return inString
		.replace(/([^,\s])\s+/g, '$1&nbsp;') // whitespace not preceded by commas
		.replace(/,([^\s])/g, ', $1'); // Commas without a space replaced by commas and a space
}

// TODO: [POD-6057] Implement this utility in product stamp where required
export function parseStringToIntOrDefault(
	input: string | null | undefined = '',
	defaultNumber: number
): number {
	if (input === null || input === undefined) {
		return defaultNumber;
	}
	const maybeInt = parseInt(input, 10);
	return isNaN(maybeInt) ? defaultNumber : maybeInt;
}

export function parseStringToFloatOrDefault(
	input: string | null | undefined = '',
	defaultNumber: number
): number {
	if (input === null || input === undefined) {
		return defaultNumber;
	}
	const maybeFloat = parseFloat(input);
	return isNaN(maybeFloat) ? defaultNumber : maybeFloat;
}

export function parseCurrencyStringToFloatOrDefault(
	input: string | null | undefined = '',
	defaultNumber?: number
): number | undefined {
	if (input === null || input === undefined) {
		return defaultNumber;
	}
	const strippedInput = input.replace(/[^0-9.-]+/g, '');
	const maybeFloat = parseFloat(strippedInput);
	return isNaN(maybeFloat) ? defaultNumber : maybeFloat;
}

export function replaceSpecialCharacters(value: string): string {
	return value?.replace(/[^\u0000-\u007E]|'/g, '-');
}
