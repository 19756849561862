import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'nullToZero',
	standalone: true
})
export class NullToZeroPipe implements PipeTransform {
	transform(input: number | null): number {
		if (input === null) {
			return 0;
		}
		return input;
	}
}
