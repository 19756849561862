import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { BreakPointService, Device } from '../../../services/break-point.service';
import { CallToActionResponse, ImageResponse, PaddingSize } from '../../../ui-models';
import { ButtonComponent } from '../../../4_atoms/components/button/button.component';
import { DeferLoadDirective } from '../../../directives/defer-load.directive';
import { NgIf, NgClass } from '@angular/common';
import { CardComponent } from '../card/card.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'cdx-cta, [cdxCta]',
	template: `
		<cdx-card [padding]="padding">
			<a
				(mouseenter)="hover(true)"
				(mouseleave)="hover(false)"
				class="cta-cover _noajax"
				[attr.href]="cta?.action?.link"
				[ngClass]="cta?.action?._class"
				*ngIf="cta?.action?.link"
				aria-label="linkText"
				[attr.title]="image?.alt"
				(click)="handleClick()"
			></a>
			<figure>
				<img deferLoad [src]="image?.src" [alt]="image?.alt" />
			</figure>
			<div
				class="cta-caption"
				*ngIf="cta?.action && cta?.action?.title"
				[ngClass]="{ 'cta-caption--underlay': cta?.action?.underlay }"
			>
				<p class="heading--5" *ngIf="cta?.content">{{ cta?.content }}</p>
				<button [ngClass]="{ hover: isHovering }" cdxButton fillStyle="primaryInverse" [fullWidth]="true">
					{{ cta?.action?.title }}
				</button>
			</div>
		</cdx-card>
	`,
	styleUrls: ['./cta.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CardComponent, NgIf, NgClass, DeferLoadDirective, ButtonComponent],
})
export class CtaComponent implements OnInit {
	device$ = this.breakPointService.device$.pipe(takeUntilDestroyed());
	@HostBinding('attr.data-type') type: 'card' | 'image';

	@Output() clickEmitter: EventEmitter<any> = new EventEmitter();
	@Output() initEmitter: EventEmitter<any> = new EventEmitter();

	@Input() set cta(cta: CallToActionResponse) {
		this.type = cta.content ? 'card' : 'image';
		this._cta = cta;
	}

	get cta(): CallToActionResponse {
		return this._cta;
	}

	get image(): ImageResponse | undefined {
		return this._device === Device.MOBILE ? this._cta.secondaryImage : this._cta.image;
	}

	padding = PaddingSize.None;
	isHovering = false;

	private _cta: CallToActionResponse;
	private _device: Device;

	constructor(private cdr: ChangeDetectorRef, public breakPointService: BreakPointService) {}

	ngOnInit(): void {
		if (!this.cta || !this.cta.image || !this.cta.secondaryImage) {
			// eslint-disable-next-line no-console
			console.error('[CTAComponent]: I need an image');
		}

		this.device$.subscribe((device) => {
			this._device = device;
			this.cdr.markForCheck();
		});

		this.initEmitter.emit(this.cta);
	}

	hover(isHovering: boolean): void {
		this.isHovering = isHovering;
	}

	handleClick(): void {
		this.clickEmitter.emit(this.cta);
	}
}
