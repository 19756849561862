import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StyleguideModule } from '@woolworthsnz/styleguide';
import {
	BasketInterceptor,
	ContextInterceptor,
	ExpressFulfilmentInterceptor,
	FulfilmentInterceptor,
	NotificationInterceptor,
	OrderDeliveryNotesInterceptor,
	TransferStateInterceptor,
} from './interceptors';

@NgModule({
	imports: [CommonModule, StyleguideModule],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ExpressFulfilmentInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: BasketInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: FulfilmentInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: NotificationInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ContextInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: OrderDeliveryNotesInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TransferStateInterceptor,
			multi: true,
		},
	],
})
export class ShopModule {}
