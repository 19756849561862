import { isPlatformServer, NgFor } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Inject,
	Input,
	OnInit,
	PLATFORM_ID,
	QueryList,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { NavigationItem } from '@woolworthsnz/trader-api';
import { AppSettings, AppSettingsService } from '../../../services';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface EmbeddedNavigationItem {
	url: string;
	text: string;
	exact?: boolean;
}

@Component({
	selector: 'cdx-embedded-nav, [embeddedNav]',
	templateUrl: './embedded-nav.component.html',
	styleUrls: ['./embedded-nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgFor, RouterLinkActive, RouterLink],
})
export class EmbeddedNavComponent implements OnInit, AfterViewInit {
	@ViewChild('navList') navList: ElementRef<HTMLUListElement>;
	@ViewChildren('navElements') navElements: QueryList<ElementRef>;

	private stickyHeaderObserver: IntersectionObserver | undefined;
	private _dynamicLinks: NavigationItem[] = [];
	private _navItems: EmbeddedNavigationItem[];
	appSettingState$ = this.appSettingsService.state$.pipe(takeUntilDestroyed());

	@Input() set stickyTarget(el: ElementRef) {
		if (el) {
			this.stickyHeaderObserver?.observe(el.nativeElement);
		}
	}

	@Input() set dynamicLinks(links: NavigationItem[]) {
		this._dynamicLinks = links;
	}

	get dynamicLinks(): NavigationItem[] {
		return this._dynamicLinks.filter((link) => link.isEnabled);
	}

	set navItems(val: EmbeddedNavigationItem[]) {
		this._navItems = this.removeSpecialLinkIfSpecialsAreDisabled(val);
		this.dynamicLinks
			.map(
				(link): EmbeddedNavigationItem => ({
					text: link.label || '',
					url: link.url || '',
				})
			)
			.forEach((link) => {
				this._navItems.push(link);
			});
	}

	get navItems(): EmbeddedNavigationItem[] {
		return this._navItems;
	}

	constructor(
		private appSettingsService: AppSettingsService,
		private hostElement: ElementRef,
		@Inject(PLATFORM_ID) private platformId: Object
	) {}

	ngOnInit(): void {
		this.appSettingState$.subscribe((appSettings: AppSettings) => (this.navItems = [...appSettings.navs.embedded]));
	}

	ngAfterViewInit(): void {
		if (isPlatformServer(this.platformId)) {
			return;
		}

		this.stickyHeaderObserver = new IntersectionObserver(
			([e]) => {
				e.target.toggleAttribute('data-stuckToTop', e.intersectionRatio < 1);
				this.hostElement?.nativeElement.toggleAttribute('flat', e.intersectionRatio < 1);
			},
			{
				threshold: [1],
			}
		);

		this.scrollActiveIntoView();
	}

	removeSpecialLinkIfSpecialsAreDisabled = (mainNav: any): any =>
		this.appSettingsService.getSetting('showSpecials')
			? mainNav
			: mainNav.filter((item: any) => item.text.toLowerCase() !== 'specials');

	scrollActiveIntoView(): void {
		// Have to put this in a setTimeout to give the routerLinkActive directive time to add the class
		setTimeout(() => {
			const activeChild = this.navElements
				.toArray()
				.find((el) => el.nativeElement.firstChild.classList.contains('active'));

			if (activeChild?.nativeElement?.offsetLeft) {
				this.navList.nativeElement.scrollLeft = activeChild.nativeElement.offsetLeft;
			}
		});
	}
}
