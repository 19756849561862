import { isPlatformServer } from '@angular/common';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppSettingsService, CustomWindow, WINDOW } from '@woolworthsnz/styleguide';

interface StoredResponse {
	status: number;
	statusText: string;
	body?: any;
	error?: any;
}

@Injectable({
	providedIn: 'root',
})
export class TransferStateInterceptor implements HttpInterceptor {
	constructor(
		private transferState: TransferState,
		@Inject(PLATFORM_ID) private platformId: any,
		@Inject(WINDOW) private window: CustomWindow,
		private appSettingsService: AppSettingsService
	) {}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.method !== 'GET') {
			return next.handle(request);
		}

		if (isPlatformServer(this.platformId)) {
			// requests on server are absolute url - but need to match with the baseless urls on the client
			const url = new URL(request.url);

			const key = makeStateKey<StoredResponse>(`${url.pathname}?${url.searchParams?.toString()}`);

			return next.handle(request).pipe(
				tap((event) => {
					if (event instanceof HttpResponse) {
						this.transferState.set<StoredResponse>(key, {
							body: event.body,
							status: event.status,
							statusText: event.statusText,
						});
					}
				}),
				catchError((err) => {
					// Currently the only error responses we want to store are GET 404s
					if (err instanceof HttpErrorResponse && err.status === 404) {
						this.transferState.set<StoredResponse>(key, {
							error: err.error,
							status: err.status,
							statusText: err.statusText,
						});
					}
					return throwError(err);
				})
			);
		} else {
			// baseless urls on the client
			let base = this.appSettingsService.getBase();
			if (!base) {
				base = `${this.window?.location?.protocol}//${this.window?.location?.hostname}`;
			}
			const url = new URL(`${base}${request.url}`);
			const key = makeStateKey<StoredResponse>(`${url.pathname}?${url.searchParams?.toString()}`);

			const storedResponse = this.transferState.get<StoredResponse | null>(key, null);
			if (storedResponse) {
				if (storedResponse.status === 404) {
					// Throw an error like a normal 404 API call would
					return throwError(
						new HttpErrorResponse({
							error: storedResponse.error,
							status: storedResponse.status,
							statusText: storedResponse.statusText,
						})
					);
				}

				const response = new HttpResponse({ body: storedResponse.body, status: storedResponse.status });
				this.transferState.remove(key);
				return of(response);
			} else {
				return next.handle(request);
			}
		}
	}
}
