import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgStyle } from '@angular/common';

@Component({
	selector: 'cdx-pointer',
	template: `
		<svg height="16" width="8" [attr.transform]="rotation" [ngStyle]="position">
			<path [attr.d]="rounded ? 'M8 0 V16 L4 12 Q0 8 4 4z' : 'M8 0 V16 L0 8'" />
		</svg>
	`,
	styleUrls: ['./pointer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgStyle]
})
export class PointerComponent {
	@Input()
	position: { left?: string; right?: string };

	@Input()
	side: 'left' | 'right' | 'top' | 'bottom' = 'top';

	@Input()
	rounded = false;

	get rotation(): string | null {
		switch (this.side) {
			case 'left':
				return null;
			case 'right':
				return 'rotate(180, 4, 0)';
			case 'top':
				return 'rotate(90, 4, 0)';
			case 'bottom':
				return 'rotate(270, 4, 0)';
			default:
				return null;
		}
	}
}
