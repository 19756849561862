import { sanitize } from './helpers/analytics.helper';

export const COLLECT_TIMEOUT_MS = 350;

export enum AnalyticsCollectionType {
	RecipeStampImpression,
	ProductImpression,
	ViewItemList,
	ViewPromotion,
}

export class AnalyticsCollectionBuffer {
	private events = [] as any[];
	private timer = 0;
	constructor(private link: Function) {}

	public push(data: any): void {
		if (window) {
			// do nothing in non-browser environments
			this.events.push(sanitize(data));
			window.clearTimeout(this.timer);
			this.timer = window.setTimeout(this.flush.bind(this), COLLECT_TIMEOUT_MS);
		}
	}
	public pushItemList(data: any): void {
		if (window) {
			// do nothing in non-browser environments
			this.events.push(sanitize(data));
			window.clearTimeout(this.timer);
			this.timer = window.setTimeout(this.flushItemList.bind(this), COLLECT_TIMEOUT_MS);
		}
	}

	private flush(): void {
		this.link(
			this.events.reduce((accum, curr) => {
				const result = { ...accum, ...curr }; // non-array values on the same key are overwritten
				for (const key in curr) {
					if (Array.isArray(curr[key]) && Array.isArray(accum[key])) {
						result[key] = accum[key].concat(curr[key]);
					}
				}
				return result;
			}, {})
		);
		this.events = [];
	}

	private flushItemList(): void {
		const items = this.events.filter((event) => event['0']).map((event) => event['0']);
		if (items.length > 0 && this.events[0].event && this.events[0].event === 'view_item_list') {
			const itemListResult = {
				event: this.events[0].event,
				tealium_event: this.events[0].tealium_event,
				ecommerce: {
					item_list_id: this.events[0].item_list_id,
					item_list_name: this.events[0].item_list_name,
					items: items,
				},
			};

			this.link(itemListResult);
		}
		this.events = [];
	}
}
