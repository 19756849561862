import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { FlexDirection, FlexJustification, ModalSize, PaddingSize } from '../../../ui-models';
import { AppSettingsService, BreakPointService, Device } from '../../../services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ButtonComponent } from '../../../4_atoms/components/button/button.component';
import { SvgIconComponent } from '../../../4_atoms/components/svg-icon/svg-icon.component';
import { A11yModule } from '@angular/cdk/a11y';
import { CardComponent } from '../card/card.component';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';

@Component({
	selector: 'cdx-slotted-modal',
	templateUrl: './slotted-modal.component.html',
	styleUrls: ['./slotted-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, CardComponent, A11yModule, NgIf, SvgIconComponent, ButtonComponent, AsyncPipe],
})
export class SlottedModalComponent implements OnInit {
	@ViewChild('content') content: ElementRef;

	@Input() size: ModalSize = ModalSize.Full;
	@Input() center = false;
	@Input() padding = PaddingSize.Medium;
	@Input() verticalPadding = PaddingSize.Large;
	@Input() canDismiss = true;
	@Input() canGoBack = false;
	@Input() mobileFullHeight = false;
	@Input() showBack = false;
	@Input() footerSlotFlexDirectionOverRide?: FlexDirection = undefined;
	@Input() footerSlotButtonPositionOverRide?: FlexJustification = undefined;
	@Input() modalStyle: 'default' | 'dark-green' | 'light-green' = 'default';
	@Input() applyBasicModalFooterStyle = false;
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() close = new EventEmitter<void>();
	@Output() back = new EventEmitter<void>();

	isMobile$: Observable<boolean>;
	isPC = false;
	isEmbeddedInApp = false;

	constructor(private breakPointService: BreakPointService, appSettingsService: AppSettingsService) {
		this.isMobile$ = this.breakPointService.device$.pipe(map((device) => device === Device.MOBILE));
		this.isPC = this.breakPointService.isPC();
		this.isEmbeddedInApp = appSettingsService.settings.isEmbeddedInApp;
	}

	footerOverrideClass = '';

	@HostListener('document:keydown', ['$event'])
	onKeydown(event: KeyboardEvent): void {
		// 'Esc' is IE/Edge specific, it's 'Escape' in every other browser
		if (event.key === 'Esc' || event.key === 'Escape') {
			this.onClose();
			event.preventDefault();
		}
	}

	ngOnInit(): void {
		if (this.footerSlotFlexDirectionOverRide) {
			this.footerOverrideClass = `modal-footerSlot__${this.footerSlotFlexDirectionOverRide}`;
		} else if (this.applyBasicModalFooterStyle) {
			this.footerOverrideClass = 'modal-footerSlot__default';
		}

		if (this.footerSlotButtonPositionOverRide) {
			this.footerOverrideClass += ` modal-footerSlot__${this.footerSlotButtonPositionOverRide}`;
		}
	}

	onClose(): void {
		if (this.canDismiss) {
			this.close.emit();
		}
	}

	forceRedraw(): void {
		// POD-8229 - this is only necessary due to a weird chrome layout bug that offsets the scrolled content until redrawn
		if (this.content.nativeElement.style['position'] === 'relative') {
			this.content.nativeElement.style['position'] = null;
		} else {
			this.content.nativeElement.style['position'] = 'relative';
		}
	}
}
