import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EVERYDAY_REWARDS_FEATURE_KEY, EverydayRewardsState } from './everyday-rewards.reducer';

export const selectEverydayRewardsState = createFeatureSelector<EverydayRewardsState>(EVERYDAY_REWARDS_FEATURE_KEY);

export const selectEdrBalance = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.edrBalance
);

export const selectRedemptionPreference = createSelector(selectEdrBalance, (balance) => balance?.redemptionPreference);

export const selectFetchingEdrBalance = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.fetchingEdrBalance
);

export const selectFetchedEdrBalance = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.fetchedEdrBalance
);

export const selectBoostOffersCount = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.boostOffersCount
);

export const selectFetchingBoostOffersCount = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.fetchingBoostOffersCount
);

export const selectFetchedBoostOffersCount = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.fetchedBoostOffersCount
);

export const selectHasError = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) =>
		state.errorBoostOffers !== undefined ||
		state.errorEdrBalance !== undefined ||
		state.errorRewardMessages !== undefined
);

/** Reward Messages **/
export const selectRewardMessages = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.rewardMessages
);
export const selectFetchingRewardMessages = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.fetchingRewardMessages
);
export const selectFetchedRewardMessages = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.fetchedRewardMessages
);

/** Delete reward message **/
export const selectDeletingRewardMessage = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.deletingRewardMessage
);
export const selectDeletedRewardMessage = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.deletedRewardMessage
);
