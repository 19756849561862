import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);
dayjs.extend(calendar);

// standard date time formats for CDX
// should try to use these formats when possible
// or add to this list rather than passing custom formats in
export const dateFormatTypes = {
	today: '[Today]',
	tomorrow: '[Tomorrow]',
	time: 'h:mma', // 9:30am
	date: 'Do MMMM', // 1st March
	dayOfWeekWithTime: 'dddd h:mma', // Monday 9:30am
	dayOfWeekWithDate: 'dddd Do MMMM', // Monday 1st January
	dateWithYear: 'Do MMMM YYYY', // 1st March 2019
};

export type CalendarOptions = Partial<
	Record<'sameDay' | 'nextDay' | 'nextWeek' | 'lastDay' | 'lastWeek', keyof typeof dateFormatTypes>
>;

/**
 * Formats a date using dayjs with standard format types for CDX
 * Can be used as a pipe in a template
 * e.g. {{ date | formattedDate:'dayOfWeekWithDate' }}
 *
 * or with calendar options
 * e.g. {{ date | formattedDate:'dayOfWeekWithDate' : { sameDay: 'today', nextDay: 'time' } }}
 *
 * Note that the default value when using calendar will use the format type in the first argument
 */
@Pipe({
	name: 'formattedDate',
	standalone: true
})
export class FormattedDatePipe implements PipeTransform {
	transform(
		date: Date | string | undefined | null,
		format?: keyof typeof dateFormatTypes,
		calendarOptions?: CalendarOptions
	): string {
		if (!date) {
			date = new Date();
		}
		if (!format) {
			format = 'date';
		}

		if (calendarOptions) {
			return dayjs(date).calendar(undefined, this.createOptions(format, calendarOptions));
		}

		return dayjs(date).format(this.tryGetFormatValue(format));
	}

	private tryGetFormatValue(keyedFormat?: keyof typeof dateFormatTypes): string | undefined {
		if (!keyedFormat) {
			return undefined;
		}
		return dateFormatTypes[keyedFormat];
	}

	private createOptions(defaultFormat: keyof typeof dateFormatTypes, calendarOptions: CalendarOptions): Record<string, string | undefined> {
		const defaultValue = this.tryGetFormatValue(defaultFormat);

		// must override all values to prevent dayjs from using the default values
		const options: Record<string, string | undefined> = {
			sameDay: defaultValue,
			nextDay: defaultValue,
			nextWeek: defaultValue,
			lastDay: defaultValue,
			sameElse: defaultValue,
		};

		Object.entries(calendarOptions).forEach(([key, value]) => {
			options[key] = this.tryGetFormatValue(value) || defaultValue;
		});

		return options;
	}
}
