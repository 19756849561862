import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
	selector: '[cdxLegacyNoAjax]',
	standalone: true
})
export class LegacyNoAjaxDirective {
	constructor(renderer: Renderer2, hostElement: ElementRef) {
		renderer.addClass(hostElement.nativeElement, '_noajax');
	}
}
