import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { PaddingSize } from '../../../ui-models';
import { BaseModal } from '../base-modal/base-modal';
import { SvgIconComponent } from '../../../4_atoms/components/svg-icon/svg-icon.component';
import { NgIf } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { CardComponent } from '../card/card.component';

@Component({
	selector: 'cdx-modal',
	template: `
		<cdx-card
			[padding]="padding"
			[center]="center"
			roundedCorners="medium"
			tabindex="0"
			cdkTrapFocus
			[cdkTrapFocusAutoCapture]="canDismiss"
		>
			<button
				cdkFocusInitial
				aria-label="Dismiss modal"
				class="baseModal-closeBtn"
				*ngIf="canDismiss"
				(click)="closeModal()"
			>
				<cdx-svg-icon name="cross" size="small" fill="dark"></cdx-svg-icon>
			</button>
			<ng-content></ng-content>
		</cdx-card>
	`,
	styleUrls: ['../base-modal/base-modal.scss', './modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CardComponent, A11yModule, NgIf, SvgIconComponent]
})
export class ModalComponent extends BaseModal {
	@HostBinding('class.baseModal--canDismiss')
	@Input()
	canDismiss: Boolean = true;

	@HostBinding('class.baseModal--fitContent')
	@Input()
	fitContent: Boolean = false;

	@Input() center = false;
	@Input() padding = PaddingSize.Default;
	@Input() roundedCorners = true;
	@Input() autoComplete = false;

	@HostBinding('class.baseModal--positioned')
	@Input()
	positioned: Boolean = false;

	@Output() afterClosed = new EventEmitter<void>();

	closeModal(): void {
		this.afterClosed.next();
		super.closeModal();
	}
}
