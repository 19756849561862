import { Component } from '@angular/core';

/**
 * @description
 * @export
 */
@Component({
	selector: 'cdx-footer',
	template: `
		<ng-content></ng-content>
	`,
	standalone: true
})
export class FooterComponent {

}
