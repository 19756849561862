import { Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[cdxCutoutModalTarget]',
	exportAs: 'cdxCutoutModalTarget',
	standalone: true
})
export class CutoutModalTargetDirective {
	constructor(public elementRef: ElementRef) { }
}
