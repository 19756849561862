import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ShopperService, ShopperState } from '@woolworthsnz/styleguide';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class NewShopperGuard implements CanActivate {
	constructor(private shopperService: ShopperService, private router: Router) {}

	canActivate(): Observable<boolean | UrlTree> {
		return this.shopperService.state$.pipe(
			skipWhile((s) => s.isLoggedIn === undefined),
			map((s: ShopperState) => {
				// A logged in user can be a shopper or non shopper example brand site user, as we use SSO any user from brunch,
				// pharmacy, or brandsite can log in to shop website

				if (!s.isShopper && s.isLoggedIn) {
					return this.router.createUrlTree(['/shop/register/shopperdetails']);
				}
				return true;
			})
		);
	}
}
