import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
	ActivationEnd,
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router,
} from '@angular/router';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { FulfilmentService } from '@woolworthsnz/fulfilment';
import { MODAL_ROUTES, ShellService } from '@woolworthsnz/shop';
import {
	AppSettingsService,
	checkFullActivationEndForData,
	CookieAppSettingsService,
	isSearchPage,
	ModalRoutingService,
	ShopperService,
	THEME_CONSTANTS,
} from '@woolworthsnz/styleguide';
import { BehaviorSubject, combineLatest, filter, map, ReplaySubject } from 'rxjs';
import { pageAnalytics } from '../constants/analytics.constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
	providedIn: 'root',
})
export class AppService {
	loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	loadingFade$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	mainHeaderVisible$: ReplaySubject<boolean> = new ReplaySubject(1);
	bannersVisible$: ReplaySubject<boolean> = new ReplaySubject(1);
	dynamicContentSecondaryPanelVisible$: ReplaySubject<boolean> = new ReplaySubject(1);
	useFunnelHeader$: ReplaySubject<boolean> = new ReplaySubject(1);
	mainFlex$: ReplaySubject<boolean> = new ReplaySubject(1);

	loadingText$ = new BehaviorSubject<string | undefined>(undefined);

	constructor(
		@Inject(PLATFORM_ID) private platformId: object,
		private shellService: ShellService,
		private appSettingsService: AppSettingsService,
		private fulfilmentService: FulfilmentService,
		private shopperService: ShopperService,
		private tealiumService: TealiumUtagService,
		private router: Router,
		private cookieAppSettingsService: CookieAppSettingsService,
		private modalRoutingService: ModalRoutingService
	) {
		router.events.pipe(takeUntilDestroyed()).subscribe((routerEvent) => {
			this.checkRouterEvent(routerEvent);
		});

		this.router.events
			.pipe(
				takeUntilDestroyed(),
				filter(
					(e) =>
						e instanceof ActivationEnd &&
						!this.modalRoutingService.isInModal(MODAL_ROUTES.MODAL_OUTLET, e.snapshot)
				),
				map((e) => e as ActivationEnd),
				map((e) => {
					if (e.snapshot.data) {
						const hideMainHeader = checkFullActivationEndForData(e, 'hideMainHeader');
						return !hideMainHeader;
					}
					return true;
				})
			)
			.subscribe(this.mainHeaderVisible$);

		combineLatest([
			this.router.events.pipe(
				takeUntilDestroyed(),
				filter(
					(e) =>
						e instanceof ActivationEnd &&
						!this.modalRoutingService.isInModal(MODAL_ROUTES.MODAL_OUTLET, e.snapshot)
				),
				map((e) => e as ActivationEnd)
			),
			this.appSettingsService.state$,
		])
			.pipe(map(([_, appState]) => !appState.isEmbeddedInApp || this.isHomePage()))
			.subscribe(this.bannersVisible$);

		this.router.events
			.pipe(
				takeUntilDestroyed(),
				filter(
					(e) =>
						e instanceof ActivationEnd &&
						!this.modalRoutingService.isInModal(MODAL_ROUTES.MODAL_OUTLET, e.snapshot)
				),
				map((e) => e as ActivationEnd),
				map((e) => {
					if (isSearchPage(this.router.url)) {
						return !e.snapshot.queryParams['page'] || e.snapshot.queryParams['page'] === '1';
					}
					return true;
				})
			)
			.subscribe(this.dynamicContentSecondaryPanelVisible$);

		this.router.events
			.pipe(
				takeUntilDestroyed(),
				filter(
					(e) =>
						e instanceof ActivationEnd &&
						!this.modalRoutingService.isInModal(MODAL_ROUTES.MODAL_OUTLET, e.snapshot)
				),
				map((e) => e as ActivationEnd),
				map((e) => {
					if (e.snapshot.data) {
						return !!checkFullActivationEndForData(e, 'funnelHeader');
					}
					return false;
				})
			)
			.subscribe(this.useFunnelHeader$);

		this.router.events
			.pipe(
				takeUntilDestroyed(),
				filter(
					(e) =>
						e instanceof ActivationEnd &&
						!this.modalRoutingService.isInModal(MODAL_ROUTES.MODAL_OUTLET, e.snapshot)
				),
				map((e) => e as ActivationEnd),
				map((e) => e.snapshot.data && e.snapshot.data.mainFlex)
			)
			.subscribe(this.mainFlex$);
	}

	setLoadingText(text: string): void {
		this.loadingText$.next(text);
	}

	clearLoadingText(): void {
		this.loadingText$.next(undefined);
	}

	checkRouterEvent(routerEvent: any): void {
		if (routerEvent instanceof NavigationStart && isPlatformBrowser(this.platformId)) {
			this.loading$.next(true);
			this.loadingFade$.next(true);
		}

		if (routerEvent instanceof NavigationEnd) {
			this.shellService.setState({
				showMobileNav: false,
				showDesktopSubNav: false,
			});

			// TODO - replace with history service
			this.appSettingsService.setState({
				currentUrl: routerEvent.url,
			});
			this.cookieAppSettingsService.setCookieSetting('previousUrl', routerEvent.url);

			this.trackTealiumView(routerEvent);
		}

		if (
			isPlatformBrowser(this.platformId) &&
			(routerEvent instanceof NavigationEnd ||
				routerEvent instanceof NavigationCancel ||
				routerEvent instanceof NavigationError)
		) {
			this.loadingFade$.next(false);
			setTimeout(
				() => {
					this.loading$.next(false);
				},
				parseInt(THEME_CONSTANTS.transitions.default, 10)
			);
		}
	}

	// TODO remove this and use the primary outlet path to work out
	isHomePage(): boolean {
		// Home page may have modal part in url after logged in. e.g. www.countdown.co.nz/(modal:change-order-onboarding).
		const baseUrl = this.router.url.split('?')[0];
		return baseUrl === '/' || baseUrl.startsWith('/(modal:');
	}

	trackTealiumView(routerEvent: NavigationEnd): void {
		if (routerEvent instanceof NavigationEnd) {
			this.tealiumService.view({
				page_previous: this.cookieAppSettingsService.getCookieSetting('previousUrl') || '',
				page_currency: pageAnalytics.currency,
				page_path: this.appSettingsService.getSetting('currentUrl') || '',
				...this.fulfilmentService.getAnalyticsData(),
				...this.shopperService.getAnalyticsData(),
			});
		}
	}
}
