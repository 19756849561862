import { Injectable } from '@angular/core';
import { UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { FilterCriteria, SearchResponse, ContextResponse } from '@woolworthsnz/trader-api';
import { ApiService, AppSettingsService, HttpArgs, FeatureService } from '@woolworthsnz/styleguide';
import { SearchQueryParams } from './models';

@Injectable({
	providedIn: 'root',
})
export class SearchLibService {
	private searchEndpoint: string;

	constructor(
		private apiService: ApiService,
		private featureService: FeatureService,
		appSettingsService: AppSettingsService
	) {
		this.searchEndpoint = appSettingsService.getEndpoint('search');
	}

	getFilteredGroups(facets: FilterCriteria[]): Array<FilterCriteria[]> {
		const filterGroupsObj = facets.reduce((result: any, facet: FilterCriteria) => {
			const modifiedFacet = { ...facet };
			let groupKey = modifiedFacet.group || '';

			if (this.featureService.isFeatureEnabled(ContextResponse.EnabledFeaturesEnum.GreatToLowPrice)) {
				if (groupKey === 'Specials & Great Prices') {
					modifiedFacet.group = 'Specials';
					groupKey = 'Specials';
				}
				if (
					modifiedFacet.key?.toLowerCase() === 'great price' ||
					modifiedFacet.name?.toLowerCase() === 'great price' ||
					modifiedFacet.value.toString().toLowerCase() === 'great price'
				) {
					modifiedFacet.key = 'Low Price';
					modifiedFacet.name = 'Low Price';
					modifiedFacet.value = 'Low Price';
				}
			}

			if (this.featureService.isFeatureEnabled(ContextResponse.EnabledFeaturesEnum.HaystackEDRChanges)) {
				if (
					modifiedFacet.key?.toLowerCase() === 'onecard club' ||
					modifiedFacet.name?.toLowerCase() === 'onecard club' ||
					modifiedFacet.value.toString().toLowerCase() === 'onecard club'
				) {
					modifiedFacet.key = 'Member Price';
					modifiedFacet.name = 'Member Price';
					modifiedFacet.value = 'Member Price';
				}
			}

			if (result[groupKey]) {
				result[groupKey].push(modifiedFacet);
			} else {
				result[groupKey] = [modifiedFacet];
			}
			return result;
		}, {});

		return Object.values(filterGroupsObj);
	}

	buildFilterQuery(selectedFilters: FilterCriteria[]): string[] | null {
		if (!selectedFilters || !selectedFilters.length) {
			return null;
		}

		return <string[]>selectedFilters.map(this.buildFilterQueryString).filter(Boolean);
	}

	buildFilterQueryString(filter: FilterCriteria): string | null {
		if (!filter || !Object.keys(filter).length) {
			return null;
		}

		const { key, value, name, isBooleanValue, group } = filter;

		return group
			? `${key};${value};${name};${isBooleanValue};${group}`
			: `${key};${value};${name};${isBooleanValue}`;
	}

	buildArrayOfStringifiedUrlSegments(url: UrlSegment[]): string[] {
		return [...url.map((u: UrlSegment): any => u.path || u)];
	}

	fetchProducts = (params: SearchQueryParams): Observable<SearchResponse> => {
		const args: HttpArgs = {
			params,
		};

		return this.apiService.get(this.searchEndpoint, args);
	};
}
