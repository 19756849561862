import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import {
	CommonModule,
	CurrencyPipe,
	DatePipe,
	DecimalPipe,
	I18nPluralPipe,
	Location,
	LocationStrategy,
	PathLocationStrategy,
	TitleCasePipe,
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_ID, ApplicationRef, CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { ShopModule } from '@woolworthsnz/shop';
import {
	ACCOUNT_BASE_URL,
	ANALYTICS_PROVIDER,
	ApiService,
	APP_SETTINGS,
	AppSettingsService,
	AuthEffects,
	authFeatureKey,
	authReducer,
	HttpErrorsInterceptor,
	LoggingService,
	LowerCaseUrlSerializer,
	REQ_RECEIVE_TIME,
	RequestHeaderInterceptor,
	ServerStateService,
	SSO_BASE_URL,
	StyleguideModule,
	EDR_BASE_URL,
} from '@woolworthsnz/styleguide';
import { localStorageSync } from 'ngrx-store-localstorage';
import { LottieModule } from 'ngx-lottie';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppSettings } from './app.settings';
import { startupServiceProvider } from './app.startup.service';
import { CoreModule } from './core/core.module';
import { NavigationFactoryService } from './core/services/navigation-factory.service';
import { ShopUIRoutingModule } from './shop-ui.routing.module';
import { ShoppingListInterceptor } from './shopping-list/interceptors/shopping-list.interceptor';
import { EDRStateModule, edrConfig } from '@woolworthsnz/everyday-rewards';

export function playerFactory(): Promise<typeof import('node_modules/lottie-web/build/player/lottie_svg')> {
	return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}

export const accountBaseUrlFactory = (appSettingsService: AppSettingsService): string =>
	appSettingsService.settings.accountBaseUrl;

export const ssoBaseUrlFactory = (appSettingsService: AppSettingsService): string =>
	appSettingsService.settings.ssoBaseUrl;

export function localStorageSyncReducer(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
	return localStorageSync({ keys: ['app'], rehydrate: true, restoreDates: true, checkStorageAvailability: true })(
		reducer
	);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
	imports: [
		CoreModule,
		CommonModule,
		BrowserModule.withServerTransition({ appId: 'app' }),
		BrowserAnimationsModule,
		OverlayModule,
		PortalModule,
		ShopUIRoutingModule,
		HttpClientModule,
		ShopModule,
		StyleguideModule.forRoot(),
		// ServiceWorkerModule.register('ngsw-worker.js', {
		// 	enabled: environment.production,
		// 	registrationStrategy: 'registerImmediately',
		// }),
		LottieModule.forRoot({ player: playerFactory }),
		StoreModule.forRoot({}, { metaReducers }),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production , connectInZone: true}),
		StoreModule.forFeature(authFeatureKey, authReducer),
		EffectsModule.forFeature([AuthEffects]),
		AppComponent,
		EDRStateModule,
	],
	providers: [
		provideClientHydration(),
		startupServiceProvider,
		{
			provide: APP_ID,
			useValue: 'app',
		},
		{ provide: APP_SETTINGS, useValue: AppSettings },
		{ provide: ACCOUNT_BASE_URL, useFactory: accountBaseUrlFactory, deps: [AppSettingsService] },
		{ provide: EDR_BASE_URL, useValue: edrConfig.url },
		{ provide: SSO_BASE_URL, useFactory: ssoBaseUrlFactory, deps: [AppSettingsService] },
		{ provide: LOCALE_ID, useValue: 'en-NZ' },
		{ provide: REQ_RECEIVE_TIME, useValue: Date.now() },
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		{
			provide: UrlSerializer,
			useClass: LowerCaseUrlSerializer,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestHeaderInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorsInterceptor,
			multi: true,
		},
		{
			// ideally this would be declared only in the shopping-list module which is lazily loaded and also imported for search. But for whatever reason it won't intercept when it's put there... apparently there is a bug if you (or a 3rd party lib) imports an HttpClientModule in the lazy loaded module - but I'm pretty sure we don't - so no idea why this refuses to work when put where it belongs.
			provide: HTTP_INTERCEPTORS,
			useClass: ShoppingListInterceptor,
			multi: true,
		},
		AppSettingsService,
		Location,
		ApiService,
		CurrencyPipe,
		DecimalPipe,
		TitleCasePipe,
		I18nPluralPipe,
		ServerStateService,
		DatePipe,
		// TODO - move this to analytics lib to provide (causes Circular Dependency until analytics / styleguide decoupled).
		{
			provide: ANALYTICS_PROVIDER,
			useExisting: TealiumUtagService,
		},
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ShopUIMainModule implements DoBootstrap {
	constructor(private navigationFactoryService: NavigationFactoryService, private loggingService: LoggingService) {}

	ngDoBootstrap(appRef: ApplicationRef): void {
		appRef.bootstrap(AppComponent);

		this.navigationFactoryService.bootstrapNavElements();
	}
}
