<div class="cutoutModalOverlay">
	<svg class="backdrop" *ngIf="cutout">
		<defs>
			<mask id="mask" x="0" y="0" *ngIf="hideCutoutOnMobileAndTablet && !viewportMobileOrTablet">
				<rect x="0" y="0" width="100%" height="100%" fill="#fff"></rect>
				<circle *ngIf="mode === 'circle'" [attr.cx]="cutoutLeft" [attr.cy]="cutoutTop" [attr.r]="radius"></circle>
				<rect
					*ngIf="mode === 'rect'"
					[attr.x]="cutoutLeft"
					[attr.y]="cutoutTop"
					[attr.width]="cutoutWidth"
					[attr.height]="cutoutHeight"
					[attr.rx]="cutoutRadius"
				></rect>
			</mask>
		</defs>
		<rect
			x="0"
			y="0"
			mask="url(#mask)"
			fill-opacity="0.25"
			fill="#000"
			width="100%"
			height="100%"
			(click)="closeModal()"
		></rect>
		<symbol viewBox="0 0 32 32" id="cross" xmlns="http://www.w3.org/2000/svg">
			<path
				fill="inherit"
				d="M27 28c-.3 0-.5-.1-.7-.3L16 17.5 5.8 27.7c-.4.4-1.1.4-1.5 0-.4-.4-.4-1.1 0-1.5L14.5 16 4.3 5.8c-.4-.4-.4-1.1 0-1.5s1.1-.4 1.5 0L16 14.5 26.2 4.3c.4-.4 1.1-.4 1.5 0 .4.4.4 1.1 0 1.5L17.5 16l10.2 10.2c.4.4.4 1.1 0 1.5-.2.2-.5.3-.7.3z"
			/>
		</symbol>
	</svg>
	<div (click)="closeModal()">
		<ng-content select="[additionalBackdrop]"></ng-content>
	</div>
	<div
		class="cutoutModal"
		[style.top.px]="modalTop"
		[style.left.px]="modalLeft"
		[class.cutoutModal--centered]="hideCutoutOnMobileAndTablet && this.viewportMobileOrTablet"
	>
		<div
			class="cutoutModal-pointer hidden-phone"
			[ngStyle]="pointerPos"
			*ngIf="hideCutoutOnMobileAndTablet && !viewportMobileOrTablet"
		></div>
		<cdx-modal
			padding="none"
			[center]="false"
			[canDismiss]="true"
			[size]="size"
			[positioned]="true"
			(closeEmitter)="closeModal()"
			(afterClosed)="updateState()"
		>
			<div #modalContent class="cutoutModal-content">
				<ng-content></ng-content>
				<button *ngIf="hideGotItButton === false" cdxButton cdkFocusInitial (click)="closeModal()">Got it</button>
			</div>
		</cdx-modal>
	</div>
</div>
