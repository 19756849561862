import { animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style } from '@angular/animations';
import { isPlatformServer, NgIf, NgFor, NgClass } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	HostBinding,
	HostListener,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	PLATFORM_ID,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { CarouselDirective } from '../../../directives';
import {
	AppSettingsService,
	BreakPointService,
	CustomWindow,
	DatalayerService,
	StatefulService,
	TrackingEvent,
	WINDOW,
} from '../../../services';
import { CallToActionResponse, CarouselDirectionEnum, NotificationType } from '../../../ui-models';
import { SvgIconComponent } from '../../../4_atoms/components/svg-icon/svg-icon.component';
import { CtaComponent } from '../../../5_molecules/components/cta/cta.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export class CarouselState {
	componentWidth: number;
	containerWidth: number;
	currentPage = 1;
	itemCount: number;
	itemWidth: number;
	translate = 0;
}

interface PageChangeEvent {
	currentPage: number;
}

/**
 * Please use cdx-carousel2 instead.
 */
@Component({
	selector: 'cdx-carousel',
	template: `
		<div class="carousel-wrapper">
			<article cdxCta [cta]="hero" *ngIf="hero?.image"></article>

			<div class="carousel-track" #container data-container="true">
				<ng-content></ng-content>
				<button
					class="carousel-button carousel-button--prev"
					aria-label="previous"
					(click)="prevClick()"
					*ngIf="displayMoveLeft"
				>
					<cdx-svg-icon size="small" name="chevron-left"></cdx-svg-icon>
				</button>
				<button
					class="carousel-button carousel-button--next"
					aria-label="next"
					(click)="nextClick()"
					*ngIf="displayMoveRight"
				>
					<cdx-svg-icon size="small" name="chevron-right"></cdx-svg-icon>
				</button>
			</div>
		</div>

		<nav
			role="navigation"
			aria-label="Carousel pagination"
			class="carousel-pagination"
			*ngIf="getPageCount()?.length > 1 && showPagination"
		>
			<ul>
				<li
					*ngFor="let p of getPageCount(); let i = index"
					[ngClass]="{ currentPage: i + 1 === state?.currentPage }"
				>
					<button attr.aria-labelledBy="{{ getAriaLabelId(i) }}" (click)="paginationClick(i)"></button>
				</li>
			</ul>
		</nav>
	`,
	styleUrls: ['./carousel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, CtaComponent, SvgIconComponent, NgFor, NgClass],
})
export class CarouselComponent
	extends StatefulService<CarouselState>
	implements OnInit, AfterViewInit, OnDestroy, OnChanges {
	@ContentChild(CarouselDirective)
	carouselContainer: CarouselDirective;

	@HostBinding('attr.aria-live') live = 'polite';
	@ViewChild('container') container: ElementRef;

	@Input() hero: CallToActionResponse;
	@Input() analyticsID?: string;
	@Input() autoRotate: Boolean = false;
	@Input() autoRotateInterval = 5;
	@Input() showPagination = true;
	@Input() contentChange: any = {};
	@Output() animateEmitter: EventEmitter<number> = new EventEmitter();
	@Output() prevClickEvent: EventEmitter<PageChangeEvent> = new EventEmitter<PageChangeEvent>();
	@Output() nextClickEvent: EventEmitter<PageChangeEvent> = new EventEmitter<PageChangeEvent>();
	device$ = this.breakPointService.device$.pipe(takeUntilDestroyed());

	@Input()
	set items(value: any[] | string) {
		// Needs to be able to parse json when loaded in legacy
		if (typeof value === 'string') {
			try {
				this._items = JSON.parse(value);
			} catch {}
		} else {
			this._items = value;
		}
	}

	get items(): any[] {
		return this._items;
	}

	@Input() name = 'Carousel 1';

	carouselElements: any[];
	timing = `${this.appSettingsService.getTransition('medium')} ${this.appSettingsService.getEasing('ease-in')}`;
	shadowTolerance = 0;
	timerId: number;

	get isOnFirstPage(): boolean {
		return this.state.currentPage === 1;
	}

	get isOnLastPage(): boolean {
		return this.state.currentPage >= this.getPageCount().length;
	}

	get displayMoveLeft(): Boolean {
		return this.autoRotate ? this.autoRotate : !this.isOnFirstPage;
	}

	get displayMoveRight(): Boolean {
		return this.autoRotate ? this.autoRotate : !this.isOnLastPage;
	}

	private player: AnimationPlayer;
	private _items: any[] = [];

	constructor(
		private cdr: ChangeDetectorRef,
		private builder: AnimationBuilder,
		public breakPointService: BreakPointService,
		private appSettingsService: AppSettingsService,
		private datalayerService: DatalayerService,
		@Inject(WINDOW) private window: CustomWindow,
		@Inject(PLATFORM_ID) private platformId: Object
	) {
		super(new CarouselState());
	}

	@HostListener('window:resize')
	handleResize(): void {
		this.initCarousel();
	}

	@HostListener('swiperight')
	prevClick(): void {
		this.resetTimeout();

		const currentPage = this.state.currentPage > 1 ? this.state.currentPage - 1 : this.getPageCount().length;
		const translate = this.getTranslateAmount(currentPage);

		this.setState({
			currentPage,
			translate,
		});

		this.doAnimation(translate);

		this.datalayerService.trackEvent(
			TrackingEvent.NotificationEvent,
			NotificationType.ScrollArrow,
			this.analyticsID ?? `Carousel Left Button - ${this.name}`,
			CarouselDirectionEnum.Left
		);

		this.prevClickEvent.emit({ currentPage });

		this.cdr.markForCheck();
	}

	@HostListener('swipeleft')
	nextClick(autoRotate = false): void {
		this.resetTimeout();

		const currentPage = this.state.currentPage < this.getPageCount().length ? this.state.currentPage + 1 : 1;
		const translate = this.getTranslateAmount(currentPage) + this.shadowTolerance;

		this.setState({
			currentPage,
			translate,
		});

		this.doAnimation(translate);

		if (!autoRotate) {
			this.datalayerService.trackEvent(
				TrackingEvent.NotificationEvent,
				NotificationType.ScrollArrow,
				this.analyticsID ?? `Carousel Right Button - ${this.name}`,
				CarouselDirectionEnum.Right
			);
		}

		this.nextClickEvent.emit({ currentPage });

		this.cdr.markForCheck();
	}

	ngOnChanges(changes: SimpleChanges): void {
		// re initialize carousel content when filters is applied
		if (changes.contentChange?.previousValue) {
			this.reInitCarousel(changes.contentChange.currentValue.products.length);
		}
	}

	ngOnInit(): void {
		this.device$.subscribe((device) => {
			this.timing = `${
				device === 'mobile'
					? this.appSettingsService.getTransition('short')
					: this.appSettingsService.getTransition('medium')
			} ${this.appSettingsService.getEasing('ease-in')}`;
		});
	}

	ngAfterViewInit(): void {
		this.carouselElements = Array.from(this.carouselContainer.el.nativeElement.children);
		this.initCarousel();
		this.animateEmitter.emit(this.state.currentPage);
	}

	ngOnDestroy(): void {
		clearTimeout(this.timerId);
	}

	getItemsPerPage(): number {
		return Math.floor(this.state.containerWidth / this.state.itemWidth) || 1;
	}

	getPageCount(): any[] {
		if (!this.state.itemCount || isNaN(this.state.itemWidth)) {
			return [];
		}
		return new Array(Math.ceil(this.state.itemCount / this.getItemsPerPage()));
	}

	doAnimation(translate: number): void {
		const anim: AnimationFactory = this.builder.build([
			animate(this.timing, style({ transform: `translateX(-${translate}px)` })),
		]);

		this.player = anim.create(this.carouselContainer.el.nativeElement);
		this.player.play();
		this.animateEmitter.emit(this.state.currentPage);
	}

	getComputedItemWidth(item: Element): number | undefined {
		if (!item || isPlatformServer(this.platformId)) {
			return;
		}
		const computedStyle = this.window.getComputedStyle(item);

		return item.clientWidth + parseInt(computedStyle.marginLeft, 10) + parseInt(computedStyle.marginRight, 10);
	}

	getTranslateAmount(page: number): number {
		const itemsAfterCurrent = page * this.getItemsPerPage() - this.state.itemCount;
		const isLastPage = itemsAfterCurrent > 0;

		if (isLastPage) {
			return (this.state.itemCount - this.getItemsPerPage()) * this.state.itemWidth;
		}

		return (page - 1) * this.getItemsPerPage() * this.state.itemWidth;
	}

	getVisibleCarouselItems(): any[] {
		if (isPlatformServer(this.platformId)) {
			return this.carouselElements || [];
		}
		return (this.carouselElements || []).filter((el) => getComputedStyle(el).display !== 'none');
	}

	getAriaLabelId(i: number): string {
		return this._items[i]?.id ?? '';
	}

	initCarousel(): void {
		if (!this.container.nativeElement) {
			return;
		}
		this.setState({
			itemCount: this.getVisibleCarouselItems().length,
			itemWidth: this.getComputedItemWidth(this.getVisibleCarouselItems()[0]),
			containerWidth: (this.container.nativeElement as HTMLElement).offsetWidth,
		});

		// Force redraw on next tick
		setTimeout(() => {
			this.cdr.markForCheck();
		});

		if (this.autoRotate && this.autoRotateInterval && !this.timerId && !isPlatformServer(this.platformId)) {
			this.setAutoRotateTimeout();
		}
	}

	paginationClick(page: number): void {
		this.resetTimeout();

		const currentPage = page + 1;
		const translate = this.getTranslateAmount(currentPage);

		this.setState({
			currentPage,
			translate,
		});

		this.doAnimation(translate);
		this.resetTimeout();

		// Track click event
		this.datalayerService.trackEvent(
			TrackingEvent.NotificationEvent,
			NotificationType.RadioButton,
			`Carousel Radio Button - ${this.name}`,
			currentPage
		);
	}

	resetTimeout = (): void => {
		if (this.autoRotate && this.autoRotateInterval) {
			clearTimeout(this.timerId);
			this.setAutoRotateTimeout();
		}
	};

	reInitCarousel(productLength: number): void {
		let itemWidth = this.getComputedItemWidth(this.getVisibleCarouselItems()[0]);
		itemWidth = Number.isNaN(itemWidth) ? 240 : itemWidth;
		const translate = 0;

		this.setState({
			containerWidth: (this.container.nativeElement as HTMLElement).offsetWidth,
			currentPage: 1,
			itemCount: productLength,
			itemWidth: itemWidth,
			translate: translate,
		});

		this.doAnimation(translate);

		this.cdr.markForCheck();
	}

	private setAutoRotateTimeout(): void {
		this.live = 'off';
		this.timerId = this.window.setTimeout(() => {
			this.nextClick(true);
		}, this.autoRotateInterval * 1000);
	}
}
