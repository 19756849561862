import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '../../../4_atoms/components/button/button.component';
import { NgIf, DecimalPipe } from '@angular/common';

@Component({
	selector: 'cdx-load-more',
	templateUrl: './load-more.component.html',
	styleUrls: ['./load-more.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, ButtonComponent, DecimalPipe],
})
export class LoadMoreComponent {
	@Input() currentlyShowing: number;
	@Input() totalItems: number;
	@Input() loading: boolean;
	@Output() loadMore = new EventEmitter<void>();

	get progress(): number {
		const progress = Math.floor((this.currentlyShowing / this.totalItems) * 100);

		if (progress > 100) {
			return 100;
		}

		return progress;
	}

	handleLoadMoreClick(): void {
		this.loadMore.emit();
	}
}
