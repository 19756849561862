import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { GetCardCaptureIFrameUrlResponse } from '@woolworthsnz/trader-api';
import equal from 'fast-deep-equal';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { ApiService, AppSettingsService, DatalayerService, LoggingService } from '../../../services';
import { CardFieldType } from '../../../ui-models';
import {
	DigitalPayErrorEventData,
	DigitalPayFocusGainData,
	DigitalPayFocusLostData,
	DigitalPayFrameComponent,
	DigitalPayInvalidData,
	DigitalPayLoadedData,
	DigitalPaySuccessEventData,
	DigitalPayValidData,
} from '../digital-pay-frame/digital-pay-frame.component';

@Component({
	selector: 'cdx-digital-pay-credit-card',
	providers: [ApiService],
	template: `
		<cdx-digital-pay-frame
			[url]="iframeUrl"
			(focusGain)="focusGain.next($event)"
			(focusLost)="handleFocusLost($event)"
			(success)="success.next($event)"
			(error)="error.next($event)"
			(valid)="valid.next($event)"
			(invalid)="invalid.next($event)"
			(loaded)="loaded.next($event)"
		></cdx-digital-pay-frame>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [DigitalPayFrameComponent],
})
export class DigitalPayCreditCardComponent implements OnInit, OnDestroy {
	@ViewChild(DigitalPayFrameComponent) digitalPayFrame: DigitalPayFrameComponent;

	/* eslint-disable @angular-eslint/no-output-native */
	@Output() success: EventEmitter<DigitalPaySuccessEventData> = new EventEmitter();
	@Output() error: EventEmitter<DigitalPayErrorEventData> = new EventEmitter();
	@Output() invalid: EventEmitter<DigitalPayInvalidData> = new EventEmitter();
	/* eslint-enable @angular-eslint/no-output-native */
	@Output() focusGain: EventEmitter<DigitalPayFocusGainData> = new EventEmitter();
	@Output() focusLost: EventEmitter<DigitalPayFocusLostData> = new EventEmitter();
	@Output() valid: EventEmitter<DigitalPayValidData> = new EventEmitter();
	@Output() loaded: EventEmitter<DigitalPayLoadedData> = new EventEmitter();

	iframeUrl: string;
	private destroyed$: Subject<boolean> = new Subject();

	private formFields = new Subject<{
		allFieldsEmpty: boolean;
		someFieldInvalid: boolean;
		allFieldsValid: boolean;
	}>();

	constructor(
		private apiService: ApiService,
		private appSettingsService: AppSettingsService,
		private loggingService: LoggingService,
		private datalayerService: DatalayerService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.reloadFrame();

		this.formFields
			.pipe(
				takeUntil(this.destroyed$),
				distinctUntilChanged((a, b) => equal(a, b)),
				filter((state) => !state.allFieldsEmpty)
			)
			.subscribe(({ someFieldInvalid, allFieldsValid }) => {
				if (someFieldInvalid) {
					return this.datalayerService.trackCardEvent(CardFieldType.newCard, false);
				}
				if (allFieldsValid) {
					return this.datalayerService.trackCardEvent(CardFieldType.newCard, true);
				}
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	submitCardDetails(options?: { verifyCard?: boolean; saveCard?: boolean }): void {
		this.digitalPayFrame.submitCardDetails(options);
	}

	reloadFrame(): void {
		this.apiService.get(this.appSettingsService.getEndpoint('cardCaptureUrl')).subscribe(
			(response: GetCardCaptureIFrameUrlResponse) => {
				if (response && response.url) {
					this.iframeUrl = response.url;
				} else {
					this.loggingService.trackException({ error: new Error('Card Capture iframe url invalid') });
				}
				this.cdr.markForCheck();
			},
			(error) => {
				this.loggingService.trackException(error);
				this.cdr.markForCheck();
			}
		);
	}

	handleFocusLost(event: DigitalPayFocusLostData): void {
		this.focusLost.next(event);

		const { allFieldsEmpty, cardValid, cvvValid, expiryMonthValid, expiryYearValid } = event;

		// Empty fields come back as `null` in the event so we explicitly check `true` or `false` in these
		const someFieldInvalid = [cardValid, cvvValid, expiryMonthValid, expiryYearValid].some(
			(field) => field === false
		);
		const allFieldsValid = [cardValid, cvvValid, expiryMonthValid, expiryYearValid].every(
			(field) => field === true
		);

		this.formFields.next({
			allFieldsEmpty,
			someFieldInvalid,
			allFieldsValid,
		});
	}
}
