import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextResponse } from '@woolworthsnz/trader-api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ShoppingListService } from '../services/shopping-list.service';

@Injectable()
export class ShoppingListInterceptor implements HttpInterceptor {
	constructor(private shoppingListService: ShoppingListService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap((event: HttpEvent<any>) => {
				if (
					event instanceof HttpResponse &&
					event.body &&
					<ContextResponse>event.body.context &&
					<string[]>event.body.context.shoppingListItems
				) {
					const { shopper } = event.body.context;

					const shoppingListItems =
						shopper && shopper.isShopper
							? event.body.context.shoppingListItems
							: this.shoppingListService.getFromLocalStorage() || [];

					this.shoppingListService.setState({ items: shoppingListItems.filter(Boolean) });
				}
			})
		);
	}
}
