import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@woolworthsnz/styleguide';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ShellService } from '../services/shell.service';

/**
 * Updates the shell `mainNavs` and `specials` state whenever fulfilment details change
 */
@Injectable()
export class FulfilmentInterceptor implements HttpInterceptor {
	constructor(private shellService: ShellService, private appSettingsService: AppSettingsService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const endpoints = [
			`${this.appSettingsService.getEndpoint('address')}/my/items`,
			`${this.appSettingsService.getEndpoint('postMethod')}/pickup`,
			`${this.appSettingsService.getEndpoint('postMethod')}/courier`,
			this.appSettingsService.getEndpoint('postPrimaryAddress'),
			this.appSettingsService.getEndpoint('postPickupAddress'),
			this.appSettingsService.getEndpoint('postSuburb'),
		];
		return next.handle(request).pipe(
			tap((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse && endpoints.some((endpoint) => request.url.includes(endpoint))) {
					if (event.body.mainNavs || event.body.specials) {
						this.shellService.setMainNavsAndSpecialCategories(event.body);
					}
				}
			})
		);
	}
}
