import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { REGISTRATION_SHOPPER_DETAILS_PATH } from '@woolworthsnz/shop';
import { AppSettingsService, ShopperService } from '@woolworthsnz/styleguide';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class EmbeddedShopperGuard {
	constructor(
		private appSettingsService: AppSettingsService,
		private shopperService: ShopperService,
		private router: Router
	) {}

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.appSettingsService.settings.isEmbeddedInApp) {
			return true;
		}

		/**
		 * There is an assumption that a shopper must be logged in to use trader - native wrapper handles !isLoggedIn state
		 */
		return this.shopperService.state$.pipe(
			skipWhile((s) => s.isLoggedIn === undefined || s.isLoggedIn === false || s.isShopper === undefined),
			map((s) =>
				s.isShopper
					? true
					: this.router.createUrlTree([`/shop/${REGISTRATION_SHOPPER_DETAILS_PATH}`], {
							queryParams: {
								autoRedirect: 'true',
							},
					  })
			)
		);
	}
}
