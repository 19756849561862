import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CustomWindow, WINDOW } from './window.service';
import { Observable } from 'rxjs/internal/Observable';
import { isStatusCode } from '../helpers';
import { ApiService } from './api.service';
import { AppSettingsService } from './app-settings.service';

@Injectable({
	providedIn: 'root',
})
export class InboundEmailParamsService {
	private queryStringMap: Map<string, string> = new Map();

	constructor(
		private apiService: ApiService,
		private location: Location,
		private appSettingsService: AppSettingsService,
		@Inject(WINDOW) private window: CustomWindow
	) {
		this.parseQueryStrings();
	}

	parseQueryStrings(): void {
		this.location
			.path()
			.split('?')[1]
			?.split('&')
			.forEach((qs) => {
				const kv = qs.split('=');
				this.queryStringMap.set(kv[0], kv[1]);
			});
	}

	setParameters(): void {
		this.setStore();
		this.setOnecardNumber();
	}

	setRedirected(): void {
		const urlParams = new URLSearchParams(this.window.location.search);
		urlParams.set('redirected', '1');
	}

	isFromEmail(): boolean {
		return !!(this.queryStringMap.get('storeId') || this.queryStringMap.get('oToken'));
	}

	isFromEmailandRedirected(): boolean {
		return !!(
			this.queryStringMap.get('storeId') ||
			(this.queryStringMap.get('oToken') && this.queryStringMap.get('redirected'))
		);
	}

	isShoppersPrimaryOnecard(): Observable<boolean> {
		const oToken = this.queryStringMap.get('oToken') ?? '';
		return this.apiService
			.getWithFullResponse(`${this.appSettingsService.getEndpoint('onecards')}/actions/validate`, {
				params: {
					oToken,
				},
			})
			.pipe(isStatusCode(204));
	}

	setStore(): void {
		const storeId = parseInt(this.queryStringMap?.get('storeId') ?? '', 10);
		if (!isNaN(storeId)) {
			this.apiService.put(this.appSettingsService.getEndpoint('postPickupAddress'), { addressId: storeId }).subscribe();
		}
	}

	setOnecardNumber(): void {
		const oToken = this.queryStringMap.get('oToken') ?? '';
		if (oToken !== '') {
			this.apiService.post(this.appSettingsService.getEndpoint('guestOnecards'), { oToken: oToken }).subscribe();
		}
	}
}
