export const edrConfig = {
	url: 'https://www.everydayrewards.co.nz',
	routes: {
		boosts: 'boosts',
		howBoostsWork: 'how-boosts-work',
		account: {
			base: 'account',
			pointsAndActivity: 'points-and-activity',
		},
	},
	colors: {
		primary: {
			orange: '#fd6400',
		},
	},
};
