import { OrderChangeContextResponse } from '@woolworthsnz/trader-api';

export function getFormattedAddressFromOrder(order: OrderChangeContextResponse): string {
	const deliveryMethod = order.deliveryMethod?.toLowerCase().trim();

	if (deliveryMethod === 'courier') {
		const { deliveryStreet1, deliveryStreet2, deliverySuburb, deliveryCity, deliveryPostalCode } = order;

		const splitAtComma = (i: string): string[] => (i || '').split(',').map((j) => j.trim());

		return [
			...splitAtComma(deliveryStreet1 || ''),
			...splitAtComma(deliveryStreet2 || ''),
			...splitAtComma(deliverySuburb || ''),
			...splitAtComma(deliveryCity || ''),
			deliveryPostalCode || '',
		]
			.filter(Boolean)
			.join(', ');
	}

	let pickUpLocation = order.deliverySuburb?.trim() || order.deliveryCity?.trim() || '';
	if (pickUpLocation.toLowerCase().includes('click')) {
		pickUpLocation = pickUpLocation.slice(0, pickUpLocation.toLowerCase().indexOf('click')).trim();
	}
	if (pickUpLocation.toLowerCase().includes('pickup')) {
		pickUpLocation = pickUpLocation.slice(0, pickUpLocation.toLowerCase().indexOf('pickup')).trim();
	}
	return `Woolworths ${pickUpLocation}`;
}

export function getDateFromOrder(deliveryDate: Date | string | undefined): Date | undefined {
	return typeof deliveryDate === typeof Date
		? (deliveryDate as Date)
		: deliveryDate
		? new Date(deliveryDate)
		: new Date('0001-01-01');
}
