import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BreadcrumbProviderService, BREADCRUMBS } from '../../../injection-tokens';
import { BreakPointService, Device } from '../../../services';
import { SvgIconComponent } from '../../../4_atoms/components/svg-icon/svg-icon.component';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor, AsyncPipe, TitleCasePipe } from '@angular/common';

@Component({
	selector: 'cdx-breadcrumb',
	template: `
		<ul *ngIf="(breadcrumbService.state$ | async)?.breadcrumbs.length && (this.isVisible$ | async)">
			<li>
				<a routerLink="/" class="_noajax" aria-label="Home" (click)="clicked.emit('home')">
					<cdx-svg-icon name="home" size="small" fill="dark"></cdx-svg-icon>
				</a>
			</li>
			<li *ngFor="let item of (breadcrumbService.state$ | async)?.breadcrumbs">
				<cdx-svg-icon name="chevron-right" fill="dark" size="extra-small"></cdx-svg-icon>
				<ng-container *ngIf="item.routeUrl">
					<a [routerLink]="item.routeUrl" [queryParams]="item.queryParams" (click)="clicked.emit(item.text)">{{
						item.text | titlecase
					}}</a>
				</ng-container>
				<ng-container *ngIf="!item.routeUrl">
					<span>{{ item.text | titlecase }}</span>
				</ng-container>
				<div class="separator"></div>
			</li>
		</ul>
	`,
	styleUrls: ['./breadcrumb.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, RouterLink, SvgIconComponent, NgFor, AsyncPipe, TitleCasePipe]
})
export class BreadcrumbComponent {
	isVisible$: Observable<boolean>;
	@Output() clicked = new EventEmitter<string>();

	constructor(
		@Inject(BREADCRUMBS) public breadcrumbService: BreadcrumbProviderService,
		breakpointService: BreakPointService
	) {
		this.isVisible$ = breakpointService.device$.pipe(
			map((device) => device === Device.DESKTOP || device === Device.LARGE || device === Device.XLARGE)
		);
	}
}
