import { Component, Inject, OnDestroy, OnInit, isDevMode, PLATFORM_ID, InjectionToken } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { isPlatformServer, NgIf } from '@angular/common';
import { AppSettingsService, CustomWindow, OLIVE_CHAT_SESSION_STORAGE_KEY, WINDOW } from '../../../../services';
import { SSO_BASE_URL } from '../../../../injection-tokens';

@Component({
	selector: 'cdx-olive-token-hidden-iframe',
	templateUrl: './olive-token-hidden-iframe.component.html',
	standalone: true,
	imports: [NgIf],
})
export class OliveTokenHiddenIframComponent implements OnInit, OnDestroy {
	getTokenUrl: SafeResourceUrl;
	useSSU: boolean = this.appSettingsService.useSSU();
	constructor(
		private domSanitizer: DomSanitizer,
		private appSettingsService: AppSettingsService,
		@Inject(PLATFORM_ID) private platform: InjectionToken<Object>,
		@Inject(SSO_BASE_URL) private ssoBaseUrl: string,
		@Inject(WINDOW) public window: CustomWindow
	) {}

	ngOnInit(): void {
		if (isPlatformServer(this.platform)) {
			return;
		}

		if (this.useSSU) {
			this.getTokenUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
				`${this.ssoBaseUrl}/auth/proxy.html?action=setOliveToken`
			);
		} else {
			this.getTokenUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('about:blank');
		}

		this.window.addEventListener('message', this.setTokenInSessionStorage.bind(this));
	}

	ngOnDestroy(): void {
		if (isPlatformServer(this.platform)) {
			return;
		}
		this.window.removeEventListener('message', this.setTokenInSessionStorage);
	}

	setTokenInSessionStorage(event: MessageEvent): void {
		if (isPlatformServer(this.platform)) {
			return;
		}
		if (event?.data?.type === OLIVE_CHAT_SESSION_STORAGE_KEY && (event.origin === this.ssoBaseUrl || isDevMode())) {
			this.window.sessionStorage?.setItem(OLIVE_CHAT_SESSION_STORAGE_KEY, event?.data?.value);
		}
	}
}
