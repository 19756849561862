import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ServerStateService } from '../services/server-state.service';
import Perfume from 'perfume.js';
import { IAnalyticsTrackerOptions } from 'perfume.js/dist/types/types';
import { LoggingService } from './logging.service';

export interface PerfumeConfig {
	firstContentfulPaint: boolean;
	firstInputDelay: boolean;
	dataConsumption: boolean;
	navigationTiming: boolean;
	resourceTiming: boolean;
	analyticsTracker: Function;
}

const fullMetricNames: { [key: string]: string } = {
	navigationTiming: 'navigationTiming',
	networkInformation: 'networkInformation',
	storageEstimate: 'storageEstimate',
	fp: 'firstPaint',
	fcp: 'firstContentfulPaint',
	fid: 'firstInputDelay',
	fidVitals: 'firstInputDelayVitals',
	lcp: 'largestContentfulPaint',
	lcpFinal: 'largestContentfulPaintFinal',
	cls: 'cumulativeLayoutShift',
	clsFinal: 'cumulativeLayoutShiftFinal',
	tbt: 'totalBlockingTime',
	tbt5S: 'totalBlockingTime5S',
	tbt10S: 'totalBlockingTime10S',
	tbtFinal: 'totalBlockingTimeFinal',
};

function mapName(metricName: string): string {
	return fullMetricNames[metricName] || metricName;
}

function isEmptyObject(object: any): boolean {
	return Object.keys(object).length === 0 && object.constructor === Object;
}

@Injectable({
	providedIn: 'root',
})
export class PerformanceTrackingService {
	tracker: Perfume;

	constructor(
		private loggingService: LoggingService,
		@Inject(PLATFORM_ID) private platformId: Object,
		private serverStateService: ServerStateService
	) {
		if (isPlatformBrowser(platformId)) {
			this.tracker = new Perfume({
				resourceTiming: false,
				elementTiming: true,
				analyticsTracker: ({
					metricName,
					data,
					eventProperties,
					navigatorInformation,
					vitalsScore,
				}: IAnalyticsTrackerOptions) => {
					const fullMetricName = mapName(metricName);
					// Always include the data property and the navigatorInformation
					const outputData: Partial<IAnalyticsTrackerOptions & { initiallyServerRendered: boolean }> = {
						data,
						navigatorInformation,
					};
					// Add eventProperties only if they are supplied
					if (!isEmptyObject(eventProperties)) {
						outputData.eventProperties = eventProperties;
					};
					// Add vitals only if there is a value
					if (vitalsScore) {
						outputData.vitalsScore = vitalsScore;
					};

					if (this.serverStateService.wasInitiallyServerRendered() && Object.keys(fullMetricNames).includes(metricName)) {
						outputData.initiallyServerRendered = true;
					}

					this.loggingService.trackEvent(`UI:Perf:${fullMetricName}`, outputData);
				},
			});
		}
	}

	startTracking(metricName: string): void {
		this.tracker?.start(metricName);
	}

	endPaintTracking(metricName: string, customProperties?: any): void {
		this.tracker?.endPaint(metricName, customProperties);
	}
}
