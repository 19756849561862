import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, AppSettingsService } from '@woolworthsnz/styleguide';
import { BoostOffersCountResponse, EdrBalanceResponseV2, RewardMessagesResponse } from '@woolworthsnz/trader-api';

@Injectable({
	providedIn: 'root',
})
export class EDRService {
	constructor(
		private apiService: ApiService,
		private appSettingsService: AppSettingsService
	) {}

	/**
	 * Fetch the EDR points balance and redeemable voucher value
	 */
	public fetchEdrBalance(): Observable<EdrBalanceResponseV2> {
		return this.apiService.get(`${this.appSettingsService.getEndpoint('loyalty', 2)}/edr/my/balances`);
	}

	/**
	 * Fetch the number of boost offers available
	 */
	public fetchBoostOffersCount(): Observable<BoostOffersCountResponse> {
		return this.apiService.get(`${this.appSettingsService.getEndpoint('myOffers')}/count`);
	}

	/**
	 * Fetch the reward messages / notifications
	 */
	public fetchRewardMessages(): Observable<RewardMessagesResponse> {
		return this.apiService.get(`${this.appSettingsService.getEndpoint('loyalty')}/edr/my/reward-messages`);
	}

	/**
	 * Delete a reward message once it has been shown to the user
	 * @param messageId
	 */
	public deleteRewardMessage(messageId: string): Observable<void> {
		return this.apiService.delete(
			`${this.appSettingsService.getEndpoint('loyalty')}/edr/my/reward-messages/${messageId}`
		);
	}
}
