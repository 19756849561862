import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { NgClass } from '@angular/common';

@Component({
	selector: 'cdx-powered-by-onecard',
	templateUrl: './powered-by-onecard.component.html',
	styleUrls: ['./powered-by-onecard.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, SvgIconComponent]
})
export class PoweredByOnecardComponent {
	@Input() alignIcon: 'right' | 'left' = 'left';
}
