import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'cdx-payment-instrument',
	template: `
		<section class="paymentInstrument" [class.paymentInstrument--disabled]="disabled" [attr.aria-disabled]="disabled">
			<div class="paymentInstrument-instrumentContainer">
				<div class="paymentInstrument-scheme">
					<img [src]="src" [alt]="cardScheme" width="40" height="32" (error)="handleError()" />
				</div>
				<div class="paymentInstrument-cardContainer">
					<div class="paymentInstrument-card">
						<p class="paymentInstrument-cardNumber">●●●● {{ cardSuffix }}</p>
						<p class="paymentInstrument-cardExpiry" [class.paymentInstrument-cardExpiry--expired]="isExpired">
							{{ isExpired ? 'Expired ' : 'Expires ' }} {{ expiryMonth }}/{{ expiryYear }}
						</p>
					</div>
					<div class="paymentInstrument-infoBadge">
						<ng-content select="[cdx-payment-instrument-infoBadge]"></ng-content>
					</div>
				</div>
			</div>
			<div>
				<ng-content select="[cdx-payment-instrument-actions]"></ng-content>
			</div>
		</section>
	`,
	styleUrls: ['./payment-instrument.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class PaymentInstrumentComponent {
	@Input() cardSuffix: string;
	@Input() expiryYear: string;
	@Input() expiryMonth: string;
	@Input() isExpired: boolean;
	@Input() disabled: boolean;

	@Input() set cardScheme(scheme: string) {
		// Reset image error state when card scheme changes
		if (this._cardScheme !== scheme) {
			this.imageErrored = false;
		}
		this._cardScheme = scheme;
	}

	get cardScheme(): string {
		return this._cardScheme;
	}

	private _cardScheme: string;
	private imageErrored = false;

	get src(): string {
		const scheme = this.imageErrored || !this.cardScheme ? 'default' : this.cardScheme.toLocaleLowerCase();
		return `/assets/images/payments/${scheme}.png`;
	}

	handleError(): void {
		this.imageErrored = true;
	}
}
