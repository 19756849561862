import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'breakOnComma',
	standalone: true
})
export class BreakOnCommaPipe implements PipeTransform {
	transform(value?: string | null): string | undefined | null {
		if (!value?.length) {
			return value;
		}

		return value.replace(/,\s*/g, `<br />`);
	}
}
