import { animate, animateChild, group, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { THEME_CONSTANTS } from '../1_settings/theme';

// These are building blocks for animations, so need to be wrapped in a transition and/or a trigger to work.
export const slideUpSteps = [
	style({ transform: 'translateY(20px)', opacity: 0 }),
	animate(
		`${THEME_CONSTANTS.transitions.medium} ${THEME_CONSTANTS.easings['out-quart']}`,
		style({ transform: 'translateY(0px)', opacity: 1 })
	),
];

export const slideDownSteps = [
	style({ transform: 'translateY(0)', opacity: 1 }),
	animate(
		`${THEME_CONSTANTS.transitions.medium} ${THEME_CONSTANTS.easings['out-quart']}`,
		style({ transform: 'translateY(20px)', opacity: 0 })
	),
];

export const fadeOutSteps = [
	style({ opacity: '*' }),
	animate(`${THEME_CONSTANTS.transitions.medium} ${THEME_CONSTANTS.easings.default}`, style({ opacity: 0 })),
];

export const fadeInSteps = [
	style({ opacity: 0 }),
	animate(`${THEME_CONSTANTS.transitions.medium} ${THEME_CONSTANTS.easings.default}`, style({ opacity: 1 })),
];

export const fadeInUpSteps = [
	style({ opacity: 0, transform: 'translateY(1rem)' }),
	stagger('100ms', [animate(`300ms ${THEME_CONSTANTS.easings['ease-out']}`, style({ opacity: 1, transform: 'none' }))]),
];

// These are the animations
export const openClose = [
	trigger('openClose', [
		state(
			'true',
			style({
				height: '*',
			})
		),
		state(
			'false',
			style({
				height: '0px',
			})
		),
		transition('1 => 0', [animate(`${THEME_CONSTANTS.transitions.default} ${THEME_CONSTANTS.easings['ease-in']}`)]),
		transition('0 => 1', [animate(`${THEME_CONSTANTS.transitions.default} ${THEME_CONSTANTS.easings['ease-out']}`)]),
	]),
];

export const opacity = [trigger('opacity', [transition(':enter', fadeInSteps)])];

export const opacityRevolve = [
	trigger('opacity', [
		transition(':enter', [
			style({ opacity: 0 }),
			animate(`${THEME_CONSTANTS.transitions.medium}  ${THEME_CONSTANTS.easings.default}`, style({ opacity: 1 })),
		]),
		transition(':leave', [
			style({ opacity: 1 }),
			animate(
				`${THEME_CONSTANTS.transitions.medium} ${THEME_CONSTANTS.transitions.medium}  ${THEME_CONSTANTS.easings.default}`,
				style({ opacity: 0 })
			),
		]),
	]),
];

export const rotate = [
	trigger('rotate', [
		state(
			'false',
			style({
				transform: 'rotate(0)',
			})
		),
		state(
			'true',
			style({
				transform: 'rotate(180)',
			})
		),
		transition('true => false', [
			animate(`${THEME_CONSTANTS.transitions.default} ${THEME_CONSTANTS.easings['ease-in']}`),
		]),
		transition('false => true', [
			animate(`${THEME_CONSTANTS.transitions.default} ${THEME_CONSTANTS.easings['ease-out']}`),
		]),
	]),
];

export const slideUp = [
	trigger('slideup', [
		transition(':enter', [
			style({ transform: 'translateY(20px)', opacity: 0 }),
			animate(
				`${THEME_CONSTANTS.transitions.short} ${THEME_CONSTANTS.transitions.medium} ${THEME_CONSTANTS.easings['out-quart']}`,
				style({ transform: 'translateY(0)', opacity: 1 })
			),
		]),
		transition(':leave', [
			style({ transform: 'scale(1)', opacity: 1, height: '*' }),
			animate(
				`${THEME_CONSTANTS.transitions.short} ${THEME_CONSTANTS.easings['in-quart']}`,
				style({
					transform: 'translateY(20px)',
					opacity: 0,
					height: '0px',
				})
			),
		]),
	]),
];

export const animateOpacityOfParentAndSlideUpChild = [
	trigger('opacity', [transition(':enter', [...fadeInSteps, query('@slideUp', [animateChild()], { optional: true })])]),
];

export const animateHeightOfParentAndOpacityOfChild = [
	trigger('height', [
		transition(':enter', [
			style({ height: '0px' }),
			group([
				animate(
					`${THEME_CONSTANTS.transitions.short} ${THEME_CONSTANTS.easings['in-quart']}`,
					style({
						height: '*',
					})
				),
				query('@show', [animateChild()], { optional: true }),
			]),
		]),
		transition(':leave', [
			group([
				animate(
					`${THEME_CONSTANTS.transitions.short} ${THEME_CONSTANTS.transitions.medium} ${THEME_CONSTANTS.easings['in-quart']}`,
					style({
						height: '0px',
					})
				),
				query('@show', [animateChild()], { optional: true }),
			]),
		]),
	]),
	trigger('show', [
		transition(':enter', [
			style({ opacity: 0 }),
			animate(
				`${THEME_CONSTANTS.transitions.default} ${THEME_CONSTANTS.transitions.medium} ${THEME_CONSTANTS.easings['ease-in']}`,
				style({ opacity: 1 })
			),
		]),
		transition(':leave', [
			style({ opacity: 1 }),
			animate(`${THEME_CONSTANTS.transitions.default} ${THEME_CONSTANTS.easings['ease-in']}`, style({ opacity: 0 })),
		]),
	]),
];

export const lozengeZoomInOut = [
	trigger('lozengeZoomInOut', [
		// don't transition on initial render
		transition(':enter', []),
		// scale up and down when value changes
		transition('* => *', [
			animate(
				`${THEME_CONSTANTS.transitions.default} ${THEME_CONSTANTS.easings['ease-out-back']}`,
				style({ transform: 'scale(1.2) translateY(-10%)' }) // lozenge by default is offset by 10% on y axis
			),
			animate(
				`${THEME_CONSTANTS.transitions.default} ${THEME_CONSTANTS.easings['ease-out-back']}`,
				style({ transform: 'scale(1) translateY(-10%)' })
			),
		]),
	]),
];

export const fadeSlideUp = [
	trigger('fadeSlideUp', [
		state('void', style({ opacity: 0 })),
		state('*', style({ opacity: 1 })),
		transition('void => *', [
			animate(`${THEME_CONSTANTS.transitions.long} ${THEME_CONSTANTS.easings['ease-out-back']}`, style({ opacity: 1 })),
		]),
		transition('* => void', [
			animate(
				`${THEME_CONSTANTS.transitions.long} ${THEME_CONSTANTS.easings['ease-out-back']}`,
				style({ opacity: 0, height: 0 })
			),
		]),
	]),
];
