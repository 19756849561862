import { Inject, Injectable, Optional } from '@angular/core';
import { BasketService } from '@woolworthsnz/shop';
import {
	AppSettingsService,
	BridgeEvent,
	CustomWindow,
	LoggingService,
	NativeBridgeService,
	REQ_RECEIVE_TIME,
	WINDOW,
} from '@woolworthsnz/styleguide';
import { BasketTotalsResponse } from '@woolworthsnz/trader-api';
import { takeWhile } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class NativeBasketService {
	constructor(
		@Inject(WINDOW) private window: CustomWindow,
		private basketService: BasketService,
		private nativeBridgeService: NativeBridgeService,
		private appSettingsService: AppSettingsService,
		private loggingService: LoggingService,
		@Optional() @Inject(REQ_RECEIVE_TIME) private originalTime: number = 0
	) {}

	init(): void {
		this.loggingService.log(`Native basket service init start at ${Date.now() - this.originalTime}ms`);

		// TODO - definitely no reason for a native basket service server side

		this.basketService.state$.pipe(takeWhile((e) => !!e)).subscribe((r: BasketTotalsResponse) => {
			if (this.appSettingsService.state.isEmbeddedInApp) {
				this.nativeBridgeService.broadcastNativeEvent(BridgeEvent.cartUpdated, r);
			}
			this.loggingService.log(
				`Native basket service subscribed to basket service start at ${Date.now() - this.originalTime}ms`
			);
		});
		this.loggingService.log(`Native basket service init end at ${Date.now() - this.originalTime}ms`);
	}
}
