import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { openClose } from '../../../animations';
import { BreakPointService, Device } from '../../../services/break-point.service';
import { SvgIconComponent } from '../../../4_atoms/components/svg-icon/svg-icon.component';
import { NgIf, NgClass } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	// TODO: POD-8321 remove deprecated selector 'cdx-search-filters'
	selector: 'cdx-collapsible-nav,cdx-search-filters',
	template: `
		<h2 class="heading--4" tabindex="-1" [class.open]="isOpen" (click)="toggleFilters()">
			<cdx-svg-icon
				*ngIf="includeIcon"
				name="list"
				size="small"
				fill="currentColor"
				class="listIcon"
			></cdx-svg-icon>
			{{ title }}
			<ng-content select="[title]"></ng-content>
		</h2>
		<button class="sr-only" (click)="toggleFilters()">{{ isOpen ? 'Collapse' : 'Expand' }} {{ title }}</button>
		<div [@openClose]="isOpen" class="filtersContent" [ngClass]="{ 'filtersContent--open': isOpen }">
			<ng-content></ng-content>
		</div>
	`,
	styleUrls: ['./collapsible-nav.component.scss'],
	animations: [...openClose],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, SvgIconComponent, NgClass],
})
export class CollapsibleNavComponent implements OnInit {
	@Input() isOpen = true;
	@Input() title = 'Categories';
	@Input() includeIcon = false;
	device$ = this.breakPointService.device$.pipe(takeUntilDestroyed());

	constructor(public breakPointService: BreakPointService) {}

	ngOnInit(): void {
		this.device$.subscribe((device) => {
			this.isOpen = device !== Device.MOBILE;
		});
	}

	toggleFilters = (): void => {
		this.isOpen = !this.isOpen;
	};
}
