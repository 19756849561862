/* eslint-disable @typescript-eslint/naming-convention */
export enum SearchTarget {
	Search = 'search',
	Browse = 'browse',
	Specials = 'specials',
	ProductGroup = 'productgroup',
	DynamicProductGroup = 'dynamicgroup',
	BoostOffer = 'boostoffer',
	DeliverySubscription = 'deliverysubscription',
	SKUs = 'SKUs',
}
/* eslint-enable @typescript-eslint/naming-convention */
