import { animate, query, style, transition, trigger } from '@angular/animations';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	Inject,
	Input,
	OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { THEME_CONSTANTS } from '../../../1_settings/theme';
import {
	AppSettingsService,
	BreakPointService,
	CustomWindow,
	Device,
	FeatureService,
	LocalStorageService,
	OliveService,
	WINDOW,
} from '../../../services';
import { ButtonComponent } from '../../../4_atoms/components/button/button.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	animations: [
		trigger('slideUp', [
			transition(':enter', [
				style({ transform: 'translateY(100px)', opacity: 0 }),
				query('.olive-openButtonCalloutAnimationWrapper', style({ width: 0, opacity: 0 })),
				animate(
					`${THEME_CONSTANTS.transitions.medium} ${THEME_CONSTANTS.transitions.long} ${THEME_CONSTANTS.easings['ease-out-back']}`,
					style({ transform: 'translateY(0)', opacity: 1 })
				),
				query(
					'.olive-openButtonCalloutAnimationWrapper',
					animate(
						`${THEME_CONSTANTS.transitions.short} ${THEME_CONSTANTS.easings['ease-out-back']}`,
						style({ width: '*', opacity: 1 })
					)
				),
			]),
		]),
	],
	selector: 'cdx-olive-roundel',
	template: `
		<ng-container *ngIf="oliveService.isVisible$ | async">
			<button
				attr.aria-label="Open Olive chat window"
				class="olive-openButton"
				(click)="showChatWidget()"
				*ngIf="canShowOlive && !chatWidgetIsActive"
				cdxButton
				fillStyle="flat"
				[@slideUp]
			>
				<img [src]="oliveRoundelImageUrl | async" width="76" height="77" alt="Olive Roundel" />
				<div class="olive-openButtonCalloutAnimationWrapper">
					<span class="olive-openButtonCallout">Ask Olive</span>
				</div>
			</button>
		</ng-container>
	`,
	styleUrls: ['./olive-roundel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, ButtonComponent, AsyncPipe],
})
export class OliveRoundelComponent implements OnInit, AfterViewInit {
	@Input() brickify = false;
	@HostBinding('class.oliveRoundel') cls = true;

	canShowOlive = false;
	chatWidgetIsActive = false;
	setFullScreen = false;
	// Future requirement for hiding certain parts of the roundel, like the text
	useLocalStorage = false;

	public oliveRoundelImageUrl: Observable<string>;
	device$ = this.breakpointService.device$.pipe(takeUntilDestroyed());

	constructor(
		@Inject(WINDOW) private window: CustomWindow,
		private appSettingsService: AppSettingsService,
		private breakpointService: BreakPointService,
		private localStorageService: LocalStorageService,
		private cdr: ChangeDetectorRef,
		private featureService: FeatureService,
		public oliveService: OliveService
	) {}

	public ngOnInit(): void {
		this.canShowOlive = this.window?.OliveChatHelper;
		this.oliveRoundelImageUrl = this.featureService
			.isEnabled('Brickify')
			.pipe(
				map((isEnabled) =>
					isEnabled
						? `/images/global-nav/brickify/olive-roundel.png`
						: '/assets/images/olive/olive-roundel.png'
				)
			);
	}

	public ngAfterViewInit(): void {
		this.device$.subscribe((d: Device) => {
			this.setFullScreen = d === Device.MOBILE;
		});

		this.initOliveChat();
	}

	initOliveChat(): void {
		if (!this.canShowOlive) {
			return;
		}

		const chatWidgetUrl = this.appSettingsService.getSetting('chatWidgetUrl');
		if (this.localStorageService.getItem('chatWidget-isChatStarted')) {
			this.localStorageService.setItem('chatWidget-isChatStarted', '');
		}

		if (!this.window.chatWidget) {
			this.window.OliveChatHelper.appendOliveChatToHead(chatWidgetUrl);
		}
		this.cdr.markForCheck();
	}

	pollLocalStorageToSeeIfClosed(): void {
		if ((this.localStorageService.getItem('chatWidget-isChatStarted') || '').toLowerCase() === 'started') {
			setTimeout(() => {
				this.pollLocalStorageToSeeIfClosed();
			}, 1000);

			return;
		}

		this.chatWidgetIsActive = false;
		this.cdr.markForCheck();
	}

	showChatWidget(): void {
		const setFullScreen = this.setFullScreen;

		this.chatWidgetIsActive = true;

		if (typeof this.window.chatWidget.onStartChat !== 'function') {
			setTimeout(() => {
				this.showChatWidget();
			}, 1000);

			return;
		}

		this.pollLocalStorageToSeeIfClosed();
		this.window.chatWidget.onStartChat({ setFullScreen });

		this.cdr.markForCheck();
	}
}
