import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BREADCRUMBS, StyleguideModule } from '@woolworthsnz/styleguide';

import { FunnelBreadcrumbsComponent } from './components/funnel-breadcrumbs/funnel-breadcrumbs.component';
import { IamHiddenIframComponent } from './components/iam-hidden-iframe/iam-hidden-iframe.component';
import { BreadCrumbService } from './services/breadcrumb.service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		OverlayModule,
		PortalModule,
		RouterModule,
		StyleguideModule.forRoot(),
		FunnelBreadcrumbsComponent,
		IamHiddenIframComponent,
	],
	exports: [FunnelBreadcrumbsComponent, IamHiddenIframComponent],
	providers: [
		{
			provide: BREADCRUMBS,
			useExisting: BreadCrumbService,
		},
	],
})
export class CoreModule {}
