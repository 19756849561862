import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	Output,
	ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first, timeout } from 'rxjs/operators';
import { LoggingService } from '../../../services/logging.service';
import { CustomWindow, WINDOW } from '../../../services/window.service';
import { FeatureService } from '../../../services/feature.service';
import { ContextResponse } from '@woolworthsnz/trader-api';
import { LoadingComponent } from '../../../4_atoms/components/loading/loading.component';
import { NgIf, AsyncPipe } from '@angular/common';

export interface DigitalPayFocusLostData {
	eventId: 'pay-frame-focus-lost';
	allFieldsEmpty: boolean;
	cardAccepted?: boolean;
	cardValid?: boolean;
	cvvValid?: boolean | null;
	expiryMonthValid?: boolean;
	expiryYearValid?: boolean;
	field: string;
}

export interface DigitalPayFocusGainData {
	eventId: 'pay-frame-focus-gain';
	allFieldsEmpty: boolean;
	field: string;
}

export interface DigitalPayValidData {
	eventId: 'pay-frame-valid';
	allFieldsEmpty: boolean;
}

export interface DigitalPayInvalidData {
	eventId: 'pay-frame-invalid';
	allFieldsEmpty: boolean;
}

export interface DigitalPayLoadedData {
	eventId: 'pay-frame-loaded';
}

export interface DigitalPaySuccessEventData {
	eventId: 'pay-frame-success';
	auditId: string;
	code: string;
	message: string;
	paymentInstrumentId: string;
	stepUpToken: string;
}

export interface DigitalPayErrorEventData {
	eventId: 'pay-frame-error';
	code: DigitalPayFrameErrorCodes;
	message: string;
}

export enum DigitalPayFrameErrorCodes {
	invalidFormFields = 'IF01', // Form fields invalid
	invalidRequestType = 'IF02', // Invalid request type submitted
	unknownEventType = 'IF03', // Unknown event submitted
	missingSchemeValue = 'IF04', // Missing Scheme value
	serverBusy = 'IF05', // Still processing previous request.
	iframeExpired = 'IF06', // Iframe already has previous success.
	iframeStale = 'IF97', // Iframe is stale
	errorRequestProcessing = 'IF98', // Unable to process request
	serverError = 'IF99', // Server Error
}

const FRAME_LOAD_TIMEOUT = 10000;

@Component({
	selector: 'cdx-digital-pay-frame',
	template: `
		<cdx-loading *ngIf="loading$ | async" fill="dark"></cdx-loading>
		<iframe
			#inputIframe
			*ngIf="iframeUrl$ | async; let iframeUrl"
			[src]="iframeUrl"
			[style.width]="frameWidth"
			[style.height]="frameHeight"
		></iframe>
		<span *ngIf="errored$ | async" class="digitalpay-errorText">
			<ng-container *ngIf="!url">An error has occurred, please refresh the page.</ng-container>
			<ng-container *ngIf="url">
				An error has occurred, please <a href="javascript:void(0);" (click)="loadIFrame()">try again</a>.
			</ng-container>
			If the problem persists please
			<a href="https://www.countdown.co.nz/about-us/contact-us" target="_blank">contact us</a>.
			<span *ngIf="displayDebugLink"
				>Click <a [href]="url" target="_blank">here</a> to open a new tab to help us diagnose the issue. Please
				include a screenshot of the new tab in your correspondence with us.</span
			>
		</span>
	`,
	styleUrls: ['./digital-pay-frame.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, LoadingComponent, AsyncPipe],
})
export class DigitalPayFrameComponent implements OnDestroy {
	@ViewChild('inputIframe') iframe: ElementRef;

	@Output() focusGain: EventEmitter<DigitalPayFocusGainData> = new EventEmitter();
	@Output() focusLost: EventEmitter<DigitalPayFocusLostData> = new EventEmitter();
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() success: EventEmitter<DigitalPaySuccessEventData> = new EventEmitter();
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() error: EventEmitter<DigitalPayErrorEventData> = new EventEmitter();
	@Output() valid: EventEmitter<DigitalPayValidData> = new EventEmitter();
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() invalid: EventEmitter<DigitalPayInvalidData> = new EventEmitter();
	@Output() loaded: EventEmitter<DigitalPayLoadedData> = new EventEmitter();

	@Input() frameWidth = '100%';
	@Input() frameHeight = '125px';

	@Input() set url(url: string) {
		// URL was not defined, likely couldn't load the iframe due to an API error
		if (!url) {
			this.loading$.next(false);
			this.errored$.next(true);
			return;
		}

		if (url === this._url) {
			return;
		}

		this._url = url;

		this.loadIFrame();
	}

	get url(): string {
		return this._url || '';
	}

	iframeUrl$ = new BehaviorSubject<SafeResourceUrl | undefined>(undefined);
	errored$ = new BehaviorSubject<boolean>(false);
	loading$ = new BehaviorSubject<boolean>(true);

	private numErrors = 0;
	private frameLoadTimeout = FRAME_LOAD_TIMEOUT;

	messageEventListener: Function;

	private _url?: string;
	private timeoutSubscription: Subscription;

	constructor(
		private sanitizer: DomSanitizer,
		private featureService: FeatureService,
		private loggingService: LoggingService,
		@Inject(WINDOW) private window: CustomWindow
	) {
		this.messageEventListener = this.messageCallback.bind(this);
	}

	ngOnDestroy(): void {
		this.window.removeEventListener('message', this.messageEventListener);
	}

	loadIFrame(): void {
		this.iframeUrl$.next(undefined);
		this.loading$.next(true);
		this.errored$.next(false);

		// Setup timeout for iframe to load
		this.timeoutSubscription?.unsubscribe();
		this.timeoutSubscription = this.setupTimeout(this.frameLoadTimeout);

		// remove previous listener
		this.window.removeEventListener('message', this.messageEventListener);
		this.window.addEventListener('message', this.messageEventListener);

		const iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
		this.iframeUrl$.next(iframeUrl);
	}

	get displayDebugLink(): boolean {
		const debugEnabled = this.featureService.isFeatureEnabled(
			ContextResponse.EnabledFeaturesEnum.DebugDigitalPayIFrame
		);
		return debugEnabled && this.numErrors >= 4;
	}

	// Emit all the iframe events to the parent
	messageCallback(event: MessageEvent): void {
		switch (event.data.eventId) {
			case 'pay-frame-focus-gain':
				this.focusGain.emit(event.data);
				break;
			case 'pay-frame-focus-lost':
				this.focusLost.emit(event.data);
				break;
			case 'pay-frame-success':
				this.success.emit(event.data);
				break;
			case 'pay-frame-error':
				this.error.emit(event.data);
				break;
			case 'pay-frame-valid':
				this.valid.emit(event.data);
				break;
			case 'pay-frame-invalid':
				this.invalid.emit(event.data);
				break;
			case 'pay-frame-loaded':
				this.loaded.emit(event.data);
				break;
			default:
				this.loggingService.trackException({
					error: new Error('Could not process event with ID: ' + event.data.eventId),
				});
				break;
		}
	}

	submitCardDetails(options?: { verifyCard?: boolean; saveCard?: boolean }): void {
		const message = {
			eventId: 'pay-frame-submit',
			verify: options?.verifyCard ?? false,
			save: options?.saveCard ?? false,
		};
		this.iframe.nativeElement.contentWindow.postMessage(message, this.iframe.nativeElement.src);
	}

	// sets up a timeout to check if the iframe has loaded within time
	// since we have no way to know if the iframe has actually loaded we can
	// wait for the load event from digital pay
	private setupTimeout(due: number): Subscription {
		return this.loaded.pipe(first(), timeout(due)).subscribe(
			{
				next: () => this.loading$.next(false),
				error: () => {
				// a timeout results in an error
				// load event was not received within timeout value
				this.window.removeEventListener('message', this.messageEventListener); //  remove any listeners

				++this.numErrors;

				this.loggingService.trackException({
					error: new Error(
						`Failed to receive DigitalPay iframe load event within ${due}ms - x ${this.numErrors}`
					),
				});

				if (this.numErrors === 1) {
					// on the first error, automatically reload the iframe
					this.loadIFrame();
					return;
				}

				// increase the timeout every time, to a max of 30 seconds
				this.frameLoadTimeout = Math.min(this.numErrors * FRAME_LOAD_TIMEOUT, 30000);

				// stop the loading indicator, and show error message
				this.loading$.next(false);
				this.errored$.next(true);
				this.iframeUrl$.next(undefined);
				}
			}
		);
	}
}
