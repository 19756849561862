import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { distinctUntilShopperLoggedInStateChanged } from '@woolworthsnz/fulfilment';
import { AppSettingsService, CustomWindow, FeatureService, ShopperService, WINDOW } from '@woolworthsnz/styleguide';
import { combineLatest, map, Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
	selector: 'wnz-iam-hidden-iframe',
	templateUrl: './iam-hidden-iframe.component.html',
	standalone: true,
	imports: [AsyncPipe],
})
export class IamHiddenIframComponent implements OnInit {
	keyCloakSessionUrl$: Observable<SafeResourceUrl | undefined>;
	keyCloakInitialized: boolean;
	loggedoutCheckInitialized: boolean;

	constructor(
		private shopperService: ShopperService,
		private appSettingsService: AppSettingsService,
		private domSanitizer: DomSanitizer,
		@Inject(WINDOW) private window: CustomWindow,
		private featureService: FeatureService
	) {}

	ngOnInit(): void {
		this.keyCloakSessionUrl$ = combineLatest([
			this.shopperService.state$.pipe(distinctUntilShopperLoggedInStateChanged()),
			this.appSettingsService.state$,
		]).pipe(
			map(([shopperState]) => {
				if (!this.loggedoutCheckInitialized && !shopperState.isLoggedIn) {
					this.loggedoutCheckInitialized = true;
					return this.domSanitizer.bypassSecurityTrustResourceUrl(
						`${this.appSettingsService.getEndpoint('oidcSignInEndpoint')}?redirectUrl=${encodeURIComponent(
							`${this.window.location.origin}/assets/silent-check-sso.html`
						)}`
					);
				} else {
					return '';
				}
			})
		);
	}
}
