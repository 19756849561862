import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { SvgIconComponent } from '../../../4_atoms/components/svg-icon/svg-icon.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
	selector: 'cdx-ticker',
	templateUrl: './ticker.component.html',
	styleUrls: ['./ticker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, NgClass, SvgIconComponent]
})
export class TickerComponent implements OnInit, OnChanges {
	public currentItem: { index: number; text: string };

	@Input() public list: string[] = [];
	@Input() public startAtEnd = false;
	@Output() next = new EventEmitter<{ index: number; text: string }>();
	@Output() prev = new EventEmitter<{ index: number; text: string }>();

	public ngOnInit(): void {
		this.handleListUpdates();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (!('list' in changes)) {
			return;
		}
		this.handleListUpdates();
	}

	private handleListUpdates(): void {
		if (!this.list || this.list.length === 0) {
			return;
		}
		const currentIndex = this.startAtEnd ? this.list.length - 1 : 0;
		this.setCurrentItem(currentIndex, this.list[currentIndex]);
	}

	private setCurrentItem(index: number, text: string): void {
		this.currentItem = { index, text };
	}

	public handleNext(): void {
		if (this.currentItem.index === this.list.length) {
			return;
		}
		const index = this.currentItem.index + 1;
		this.setCurrentItem(index, this.list[index]);
		this.next.emit({ index, text: this.list[index] });
	}

	public handlePrev(): void {
		if (this.currentItem.index === 0) {
			return;
		}
		const index = this.currentItem.index - 1;
		this.setCurrentItem(index, this.list[index]);
		this.prev.emit({ index, text: this.list[index] });
	}
}
