import { AfterViewInit, Directive, ElementRef, HostBinding, Renderer2 } from '@angular/core';

@Directive({
	selector: '[cdxCarousel]',
	standalone: true
})
export class CarouselDirective implements AfterViewInit {
	@HostBinding('attr.data-carousel') carousel = true;

	constructor(public el: ElementRef, private renderer: Renderer2) { }

	ngAfterViewInit(): void {
		this.renderer.setProperty(this.el.nativeElement, 'data-carousel', 'true');
	}
}
