import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { ApiService, AppSettingsService, CustomWindow, WINDOW } from '@woolworthsnz/styleguide';
import { AddOrUpdateTrolleyRequest } from '@woolworthsnz/trader-api';

/**
 * This acts as an interface for external systems to re-enter angular and run code within the angular zone.
 * Example: Weekly mailer is a jquery based app running within the angular framework but needs to call addToTrolley within the angular zone so that angular interceptors operate on the response.
 * Typically methods in here will be bound to this and invoked within ngZone.run to re-enter angular zone. They normally return promises rather than observables since external systems use these more frequently but response can be anything that the external system requires.
 */
@Injectable({
	providedIn: 'root',
})
export class ExternalInterfaceService {
	constructor(
		private apiService: ApiService,
		private ngZone: NgZone,
		@Inject(WINDOW) private window: CustomWindow,
		private appSettingsService: AppSettingsService,
		@Inject(PLATFORM_ID) private platformId: Object
	) {
		if (isPlatformServer(this.platformId)) {
			return;
		}
		this.window.cdx = this.window.cdx || {};
		this.window.cdx.addOrUpdateTrolley = this.addOrUpdateTrolley.bind(this);
	}

	addOrUpdateTrolley(body: AddOrUpdateTrolleyRequest): Promise<any> {
		return this.ngZone.run(() =>
			this.apiService.post(`${this.appSettingsService.getEndpoint('trolley')}/my/items`, body).toPromise()
		);
	}
}
