import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { REVIEW_ORDER_PATH } from '@woolworthsnz/shop/routes';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderService } from '@woolworthsnz/shop';

@Injectable({
	providedIn: 'root',
})
export class AcceptTsAndCsGuard {
	constructor(private orderService: OrderService, private router: Router) {}

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// if the basket state has been set check the acceptDiscalimer on state
		if (this.orderService.state.updatedFromServer) {
			return this.orderService.state.disclaimerAccepted ? true : this.router.createUrlTree([REVIEW_ORDER_PATH]);
		}
		// if it hasn't call the active order service to populate state
		return this.orderService
			.getActiveOrder()
			.pipe(map((s) => (s.disclaimerAccepted ? true : this.router.createUrlTree([REVIEW_ORDER_PATH]))));
	}
}
