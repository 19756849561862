import { Inject, Injectable } from '@angular/core';
import { CustomWindow, FeatureService, LoggingService, WINDOW } from '@woolworthsnz/styleguide';
import { Router } from '@angular/router';
import { ContextResponse } from '@woolworthsnz/trader-api';
import { filter, take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class JQueryDeprecationService {
	constructor(
		@Inject(WINDOW) private window: CustomWindow,
		private loggingService: LoggingService,
		private router: Router,
		private featureService: FeatureService
	) {}

	init(): void {
		this.featureService.state$
			.pipe(
				filter((state) => !!state.updatedFromServer),
				take(1)
			)
			.subscribe(() => {
				if (!this.featureService.isFeatureEnabled(ContextResponse.EnabledFeaturesEnum.JQueryDeprecationLogging)) {
					return;
				}

				if (this.window.$) {
					try {
						this.addWarningsToJQuery(this.window.$);
					} catch (e) {
						this.loggingService.trackException({ error: new Error(`Failed to add warnings to jQuery: ${e}`) });
					}
					return;
				}

				if (this.window.jQuery) {
					try {
						this.addWarningsToJQuery(this.window.jQuery);
					} catch (e) {
						this.loggingService.trackException({ error: new Error(`Failed to add warnings to jQuery: ${e}`) });
					}
					return;
				}
			});
	}

	// All ported from jquery-migrate-3.3.2 to just log warnings and use our logging service
	// https://github.com/jquery/jquery-migrate
	addWarningsToJQuery(jQuery: any): void {
		const service = this;

		const oldInit = jQuery.fn.init;
		jQuery.fn.init = function (arg1: any) {
			const args = Array.prototype.slice.call(arguments);

			if (typeof arg1 === 'string' && arg1 === '#') {
				service.logUsage("jQuery( '#' ) is not a valid selector");
			}

			return oldInit.apply(this, args);
		};
		jQuery.fn.init.prototype = jQuery.fn;

		this.logFunction(jQuery.fn, 'size', 'jQuery.fn.size() is deprecated and removed; use the .length property');
		this.logFunction(jQuery, 'parseJSON', 'jQuery.parseJSON is deprecated; use JSON.parse');
		this.logFunction(jQuery, 'holdReady', 'jQuery.holdReady is deprecated');
		this.logFunction(jQuery, 'unique', 'jQuery.unique is deprecated; use jQuery.uniqueSort');

		jQuery.expr = this.logProps(jQuery.expr, ['filters', ':'], 'is deprecated; use jQuery.expr.pseudos');

		const oldAjax = jQuery.ajax;
		jQuery.ajax = function () {
			const jQXHR = oldAjax.apply(this, arguments);

			// Be sure we got a jQXHR (e.g., not sync)
			if (jQXHR.promise) {
				service.logFunction(jQXHR, 'success', 'jQXHR.success is deprecated and removed');
				service.logFunction(jQXHR, 'error', 'jQXHR.error is deprecated and removed');
				service.logFunction(jQXHR, 'complete', 'jQXHR.complete is deprecated and removed');
			}

			return jQXHR;
		};

		const oldRemoveAttr = jQuery.fn.removeAttr;
		jQuery.fn.removeAttr = function (name: any) {
			jQuery.each(name.match(/\S+/g), function (_: any, attr: any) {
				if (jQuery.expr.match.bool.test(attr)) {
					service.logUsage('jQuery.fn.removeAttr no longer sets boolean properties: ' + attr);
				}
			});
			return oldRemoveAttr.apply(this, arguments);
		};

		const oldToggleClass = jQuery.fn.toggleClass;
		jQuery.fn.toggleClass = function (state: any) {
			if (state === undefined || typeof state === 'boolean') {
				service.logUsage('jQuery.fn.toggleClass( boolean ) is deprecated');
			}

			return oldToggleClass.apply(this, arguments);
		};

		const oldFnCss = jQuery.fn.css;
		function camelCase(s: string): string {
			return s.replace(/-([a-z])/g, function (_, letter) {
				return letter.toUpperCase();
			});
		}
		const rautoPx =
			/^(?:Border(?:Top|Right|Bottom|Left)?(?:Width|)|(?:Margin|Padding)?(?:Top|Right|Bottom|Left)?|(?:Min|Max)?(?:Width|Height))$/;
		function isAutoPx(prop: string): boolean {
			// The first test is used to ensure that:
			// 1. The prop starts with a lowercase letter (as we uppercase it for the second regex).
			// 2. The prop is not empty.
			return /^[a-z]/.test(prop) && rautoPx.test(prop[0].toUpperCase() + prop.slice(1));
		}

		jQuery.fn.css = function (name: any, value: any) {
			if (typeof value === 'number') {
				const camelName = camelCase(name);
				if (!isAutoPx(camelName) && !jQuery.cssNumber[camelName]) {
					service.logUsage('Number-typed values are deprecated for jQuery.fn.css( "' + name + '", value )');
				}
			}

			return oldFnCss.apply(this, arguments);
		};

		const oldData = jQuery.data;
		jQuery.data = function () {
			service.logUsage('jQuery.data() always sets/gets camelCased names');
			return oldData.apply(this, arguments);
		};

		const oldTweenRun = jQuery.Tween.prototype.run;
		jQuery.Tween.prototype.run = function () {
			if (jQuery.easing[this.easing].length > 1) {
				service.logUsage("'jQuery.easing." + this.easing.toString() + "' should use only one argument");
			}

			oldTweenRun.apply(this, arguments);
		};

		if (!!window.requestAnimationFrame) {
			let intervalValue = jQuery.fx.interval || 13;
			Object.defineProperty(jQuery.fx, 'interval', {
				configurable: true,
				enumerable: true,
				get: function () {
					if (!window.document.hidden) {
						service.logUsage('jQuery.fx.interval is deprecated');
					}
					return intervalValue;
				},
				set: function (newValue) {
					service.logUsage('jQuery.fx.interval is deprecated');
					intervalValue = newValue;
				},
			});
		}

		const oldEventAdd = jQuery.event.add;
		jQuery.event.add = function (elem: any, types: any) {
			if (elem === window && types === 'load' && window.document.readyState === 'complete') {
				service.logUsage("jQuery(window).on('load'...) called after load event occurred");
			}
			return oldEventAdd.apply(this, arguments);
		};

		this.logFunction(jQuery.fn, 'load', 'jQuery.fn.load() is deprecated');
		this.logFunction(jQuery.fn, 'unload', 'jQuery.fn.unload() is deprecated');
		this.logFunction(jQuery.fn, 'error', 'jQuery.fn.error() is deprecated');
		this.logFunction(jQuery.fn, 'bind', 'jQuery.fn.bind() is deprecated');
		this.logFunction(jQuery.fn, 'unbind', 'jQuery.fn.unbind() is deprecated');
		this.logFunction(jQuery.fn, 'delegate', 'jQuery.fn.delegate() is deprecated');
		this.logFunction(jQuery.fn, 'undelegate', 'jQuery.fn.undelegate() is deprecated');
		this.logFunction(jQuery.fn, 'hover', 'jQuery.fn.hover() is deprecated');

		[
			'blur',
			'focus',
			'focusin',
			'focusout',
			'resize',
			'scroll',
			'click',
			'dblclick',
			'mousedown',
			'mouseup',
			'mousemove',
			'mouseover',
			'mouseout',
			'mouseenter',
			'mouseleave',
			'change',
			'select',
			'submit',
			'keydown',
			'keypress',
			'keyup',
			'contextmenu',
		].forEach((shorthand) => {
			this.logFunction(jQuery, shorthand, `jQuery.fn.${shorthand}() event shorthand is deprecated`);
		});

		const oldOffset = jQuery.fn.offset;
		jQuery.fn.offset = function () {
			const elem = this[0];

			if (elem && (!elem.nodeType || !elem.getBoundingClientRect)) {
				service.logUsage('jQuery.fn.offset() requires a valid DOM element');
			}

			return oldOffset.apply(this, arguments);
		};

		const oldParam = jQuery.param;
		jQuery.param = function (data: any, traditional: any) {
			const ajaxTraditional = jQuery.ajaxSettings && jQuery.ajaxSettings.traditional;

			if (traditional === undefined && ajaxTraditional) {
				service.logUsage('jQuery.param() no longer uses jQuery.ajaxSettings.traditional');
			}

			return oldParam.call(this, data, traditional);
		};

		const oldSelf = jQuery.fn.andSelf;
		jQuery.fn.andSelf = function () {
			service.logUsage('jQuery.fn.andSelf() is deprecated and removed, use jQuery.fn.addBack()');
			return oldSelf.apply(this, arguments);
		};

		jQuery.Deferred = this.logProps(jQuery.Deferred, ['pipe'], 'is deprecated');
	}

	logUsage = (message: string): void => {
		this.loggingService.trackEvent('UI:Deprecated jQuery', {
			message,
			url: this.router.url,
		});
	};

	logFunction = (obj: any, functionName: string, message: string): void => {
		const oldFunction = obj[functionName];
		const logUsage = this.logUsage;
		obj[functionName] = function () {
			logUsage(`${functionName}: ${message}`);
			return oldFunction.apply(this, arguments);
		};
	};

	logProps(targetObject: object, props: PropertyKey[], message: string): object {
		const logUsage = this.logUsage;
		const handler = {
			get: function (target: object, prop: PropertyKey, receiver: any) {
				if (props.includes(prop)) {
					logUsage(`${String(prop)} - ${message}`);
				}
				return Reflect.get(target, prop, receiver);
			},
			set: function (target: object, prop: PropertyKey, value: any, receiver?: any) {
				if (props.includes(prop)) {
					logUsage(`${String(prop)} - ${message}`);
				}
				return Reflect.set(target, prop, value, receiver);
			},
		};

		return new Proxy(targetObject, handler);
	}
}
