<ul class="carousel-list" #list [attr.aria-live]="this.intervalId ? 'off' : 'polite'">
  <ng-content></ng-content>
</ul>

<ng-container *ngIf="pages.length">
  <button
    class="carousel-button carousel-button--prev"
    aria-label="previous"
    (click)="paginationClick(currentPage + -1)"
    *ngIf="enableArrowNavigation && currentPage !== 0"
  >
    <cdx-svg-icon size="small" name="chevron-left"></cdx-svg-icon>
  </button>

  <button
    class="carousel-button carousel-button--next"
    aria-label="next"
    (click)="paginationClick(currentPage + 1)"
    *ngIf="enableArrowNavigation && currentPage !== pages.length - 1"
  >
    <cdx-svg-icon size="small" name="chevron-right"></cdx-svg-icon>
  </button>
</ng-container>


<nav role="navigation" aria-label="Carousel pagination" class="carousel-pagination" *ngIf="pages.length > 1">
  <ul role="list">
    <li *ngFor="let p of pages; let i = index" [ngClass]="{ currentPage: i === currentPage }">
      <button role="listitem" [attr.aria-label]="'Show page ' + i + ' of ' + pages.length " (click)="paginationClick(i)"></button>
    </li>
  </ul>
</nav>