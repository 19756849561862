import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NEVER, Observable, of } from 'rxjs';
import { removeNextUIFromURLString } from '../helpers';
import { AppSettingsService, CustomWindow, FeatureService, WINDOW } from '../services';

/**
 	NOTE: Side-effects. This guard forces a full page reload in order to route through akamai. While it continues to use location.assign it introduces side-effects that mean it should not be used with other guards in parallel unless it is desired that the side effect here should override all other guards.
 */
@Injectable({
	providedIn: 'root',
})
export class BackToLegacyGuard {
	constructor(
		@Inject(WINDOW) protected window: CustomWindow,
		@Inject(PLATFORM_ID) private platformId: Object,
		protected featureService: FeatureService,
		protected appSettingsService: AppSettingsService,
		protected router: Router
	) {}

	canActivate(next: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> | UrlTree {
		const routeFeature = next.data.feature || null;
		if (!this.featureService.isFeatureEnabled(routeFeature)) {
			if (isPlatformServer(this.platformId)) {
				return this.router.parseUrl('/');
			} else {
				this.window.location.assign(removeNextUIFromURLString(routerState.url, this.router));
				// Returning false mangles the state by adding a ref to the homepage. This way we persist the history state
				return NEVER;
			}
		} else {
			return of(true);
		}
	}
}
