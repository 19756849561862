import { createAction, props } from '@ngrx/store';
import { AuthResponse } from '@woolworthsnz/trader-api';

/**
 * User Log Out
 */
export const userLogOut = createAction('[Authentication] User Initiated Log Out');
export const userLogOutComplete = createAction('[Authentication] User Initiated Log Out Complete');
export const userLoggedOut = createAction('[Authentication] User Logged Out');

export const userIsOnecardRegistered = createAction('[Authentication] User Is Onecard Registered');
export const userIsShopper = createAction('[Authentication] User Is a Shopper');

/**
 * Auth Initialise
 */
export const authInitialise = createAction('[Authentication] Initialise');
export const authInitialiseComplete = createAction('[Authentication] Initialise Complete', props<AuthResponse>());
export const authInitialiseFailed = createAction('[Authentication] Initialise Failed', props<{ error: any }>());

/**
 * Auth Token Refresh
 */
export const authRefresh = createAction('[Authentication] Refresh');
export const authRefreshComplete = createAction('[Authentication] Refresh Complete', props<AuthResponse>());
export const authRefreshFailed = createAction('[Authentication] Refresh Failed', props<{ error: any }>());

/**
 * Get User Info to verify user is logged in (get-user info)
 */
export const getUserAuthInfo = createAction('[Authentication] Get User Info', props<{ sourceAction: string }>());

export const getUserAuthInfoComplete = createAction('[Authentication] Get User Info Complete', props<AuthResponse>());

export const getUserAuthInfoFailed = createAction('[Authentication] Get User Info Failed', props<{ error: any }>());

/**
 * Get User Info Retry actions
 */
export const getUserAuthInfoRetry = createAction('[Authentication] Get User Info Retry');
export const getUserAuthInfoRetryComplete = createAction(
	'[Authentication] Get User Info Retry Complete',
	props<AuthResponse>()
);
