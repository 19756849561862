import { Component } from '@angular/core';
import { PaddingSize } from '../../../ui-models';
import { BaseModal } from '../base-modal/base-modal';
import { CardComponent } from '../card/card.component';

@Component({
	selector: 'cdx-autocomplete-modal',
	template: `
		<cdx-card [padding]="padding" [roundedCorners]="true" [class.autoComplete]="true">
			<ng-content></ng-content>
		</cdx-card>
	`,
	styleUrls: ['./autocomplete-modal.component.scss'],
	standalone: true,
	imports: [CardComponent]
})
export class AutocompleteModalComponent extends BaseModal {
	padding = PaddingSize.None;
}
