/* eslint-disable @typescript-eslint/naming-convention */
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	AddOrUpdateProductPreferencesRequest,
	AddOrUpdateTrolleyRequestV2,
	AddOrUpdateTrolleyResponseV2,
	AddShopperInstructionsRequest,
	OneCardResponse,
	PackagingPreferenceRequest,
	PastOrderItemsSearchRequest,
	PastOrderListItem,
	PastOrderPagedResponse,
	SaveNewListRequest,
	SaveNewListResponse,
	SearchResponse,
	ShopperContextResponse,
	ShopperFavouritesSearchRequest,
	UpdateContactNumbersRequest,
} from '@woolworthsnz/trader-api';
import { Observable, Subject } from 'rxjs';
import { OnecardOptions } from '../constants';
import { ApiService } from './api.service';
import { AppSettingsService } from './app-settings.service';
import { LocalStorageService } from './local-storage.service';
import { StatefulService } from './stateful.service';
import AddToBasketSourceEnum = AddOrUpdateTrolleyRequestV2.AddToBasketSourceEnum;

@Injectable({
	providedIn: 'root',
})
export class ShopperState implements ShopperContextResponse {
	firstName?: string;
	isShopper?: boolean;
	isLoggedIn?: boolean;
	hasOnecard? = false;
	shopperIdHash?: string;
	shopperScvId?: string;
	sessionGroups?: number[];
	orderCount?: string;
	oneCardBalance?: OneCardResponse;
	isPriorityShopper? = false;
	isChangingOrder? = false;
	changingOrderId?: number;
	hasActiveDeliverySubscription? = false;
}

@Injectable({
	providedIn: 'root',
})
export class ShopperService extends StatefulService<ShopperState> {
	addToTrolley$ = new Subject<void>();

	constructor(
		private apiService: ApiService,
		private appSettingsService: AppSettingsService,
		private localStorageService: LocalStorageService
	) {
		super(new ShopperState());
	}

	get isLoggedInUser(): boolean | undefined {
		return this.state && (this.state.isShopper || this.state.isLoggedIn);
	}

	get isShopper(): boolean | undefined {
		return this.state.isShopper;
	}

	get canAddToTrolley(): boolean | undefined {
		return this.state && this.state.isShopper;
	}

	get isPriorityShopper(): boolean | undefined {
		return this.state.isPriorityShopper;
	}

	get firstName(): string | undefined {
		return this.state?.firstName;
	}

	get orderCount(): string | undefined {
		return this.state?.orderCount;
	}

	get isChangingOrder(): boolean | undefined {
		return this.state?.isChangingOrder;
	}

	get changingOrderId(): number | undefined {
		return this.state?.changingOrderId;
	}

	updatePhoneNumbers(phoneNumbers: UpdateContactNumbersRequest): Observable<any> {
		return this.apiService.put(this.appSettingsService.getEndpoint('updatePhoneNumbers'), phoneNumbers);
	}

	getShopperFavourites(params: ShopperFavouritesSearchRequest): Observable<any> {
		return this.apiService.get(this.appSettingsService.getEndpoint('myFavourites', 2), { params });
	}

	getShopperNotes(params: { sku: string }): Observable<any> {
		return this.apiService.get(this.appSettingsService.getEndpoint('shopperNotes'), { params });
	}

	addOrUpdateShopperNotes(
		addOrUpdateShopperPreferencesRequest: AddOrUpdateProductPreferencesRequest
	): Observable<any> {
		return this.apiService.post(
			this.appSettingsService.getEndpoint('shopperNotes'),
			addOrUpdateShopperPreferencesRequest
		);
	}

	addOrUpdateFulfilmentNotes(addShopperInstructionsRequest: AddShopperInstructionsRequest): Observable<any> {
		return this.apiService.post(
			this.appSettingsService.getEndpoint('fulfilmentNotes'),
			addShopperInstructionsRequest
		);
	}

	addOrUpdateOrderShopperNotes(addShopperInstructionsRequest: AddShopperInstructionsRequest): Observable<any> {
		return this.apiService.post(this.appSettingsService.getEndpoint('orderNotes'), addShopperInstructionsRequest);
	}

	getExperiments(): Observable<any> {
		return this.apiService.get(this.appSettingsService.getEndpoint('experiments'));
	}

	optInToExperiment(betaTestStatus: boolean): Observable<any> {
		return this.apiService.post(this.appSettingsService.getEndpoint('experiments'), { betaTestStatus });
	}

	optOutOfExperiments(): Observable<any> {
		return this.apiService.post(this.appSettingsService.getEndpoint('experiments'), { disableExperiments: true });
	}

	addCoupon(couponCode: string): Observable<any> {
		return this.apiService.post(this.appSettingsService.getEndpoint('postCoupon'), { couponCode });
	}

	getPackagingOptions(): Observable<any> {
		return this.apiService.get(this.appSettingsService.getEndpoint('packagingOptions'));
	}

	updatePackagingOptions(opt: number): Observable<any> {
		const req: PackagingPreferenceRequest = {
			packagingPreferenceId: opt,
		};

		return this.apiService.post(this.appSettingsService.getEndpoint('packagingOptions'), req);
	}

	getShopperPastOrderItems(
		params: PastOrderItemsSearchRequest,
		orderId?: number | 'all'
	): Observable<SearchResponse> {
		let url = this.appSettingsService.getEndpoint('pastOrders');
		url += orderId === 'all' ? '/items' : `/${orderId}/items`;
		return this.apiService.get(url, { params });
	}

	getShopperPastOrders(requestParams?: { page?: number; dateFilter?: string }): Observable<PastOrderPagedResponse> {
		return this.apiService.get(this.appSettingsService.getEndpoint('pastOrders'), { params: requestParams });
	}

	getShopperPastOrder(orderId: number): Observable<PastOrderListItem> {
		return this.apiService.get(`${this.appSettingsService.getEndpoint('pastOrders')}/${orderId}`);
	}

	getShopperPastOrderInvoice(orderId: number): Observable<HttpResponse<Blob>> {
		return this.apiService.getBlob(`${this.appSettingsService.getEndpoint('pastOrders')}/${orderId}/invoice`);
	}

	addAllToTrolley(request: AddOrUpdateTrolleyRequestV2): Observable<any> {
		return this.apiService.post(this.appSettingsService.getEndpoint('addOrUpdateTrolley', 2), request);
	}

	addPastOrderItemsToTrolley(orderId: number | 'all'): Observable<AddOrUpdateTrolleyResponseV2> {
		let addToBasketSource: AddToBasketSourceEnum = AddToBasketSourceEnum.PastOrdersAllItems;
		if (orderId === 'all') {
			addToBasketSource = AddToBasketSourceEnum.PastOrdersMasterAllItems;
		}
		const body: AddOrUpdateTrolleyRequestV2 = {
			addToBasketSource,
		};
		if (orderId !== 'all') {
			body.orderId = orderId;
		}
		return this.apiService.post(this.appSettingsService.getEndpoint('addOrUpdateTrolley', 2), body);
	}

	addSavedList(body: SaveNewListRequest): Observable<SaveNewListResponse> {
		return this.apiService.post(this.appSettingsService.getEndpoint('savedList'), body);
	}

	getAnalyticsData(): {
		customer_ols_scvid: string;
		customer_shopper_id: string;
		customer_logged_in_status: string;
		customer_total_orders: string;
		customer_onecard_value: string;
		customer_onecard_status: string;
		customer_onecard_option: OnecardOptions;
		customer_delivery_saver: string;
	} {
		return {
			customer_ols_scvid: this.state?.shopperScvId || '',
			customer_shopper_id: this.state?.shopperIdHash || '',
			customer_logged_in_status: this.state?.isLoggedIn?.toString() || '',
			customer_total_orders: this.state?.orderCount || '',
			customer_onecard_value: this.state?.oneCardBalance?.onecardPointsBalance?.toString() || '',
			customer_onecard_status: this.state?.hasOnecard?.toString() || '',
			customer_onecard_option: OnecardOptions.points,
			customer_delivery_saver: this.state?.hasActiveDeliverySubscription?.toString() || '',
		};
	}

	storeFulfilmentInfo(): void {
		const method = this.localStorageService.getItem('fulfilmentMethod');
		if (method) {
			this.apiService
				.put(`${this.appSettingsService.getEndpoint('postMethod')}/${method.toLowerCase()}`, {})
				.subscribe(() => {
					if (method === 'Courier') {
						const suburbId = this.localStorageService.getItem('fulfilmentSuburbId');
						if (suburbId) {
							this.apiService
								.put(`${this.appSettingsService.getEndpoint('postSuburb')}/${suburbId}`, {})
								.subscribe();
						}
					} else {
						const storeId = this.localStorageService.getItem('fulfilmentPickupAddressId');
						if (storeId) {
							this.apiService
								.put(this.appSettingsService.getEndpoint('postPickupAddress'), { addressId: storeId })
								.subscribe();
						}
					}
				});
		}
	}
}
